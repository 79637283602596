import { Fragment, useEffect, useState } from "react";

import { Transition } from "@headlessui/react";
import Button from "../elements/Button";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Drawer(props) {
  const [show, setShow] = useState(props.show);

  useEffect(() => {
    console.log("show changed", props.show);
    setShow(props.show);
  }, [props.show]);

  return (
    <div>
      <Transition
        className="fixed inset-0 bg-gray-900 bg-opacity-30 z-40 transition-opacity"
        show={show && show == true ? true : false}
        onClick={() => {
          setShow(false);
          props.setShow(false);
        }}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      <aside
        //translate x from 0 to 100% to show and hide
        className={`${
          props.width
            ? "w-" + props.width
            : "w-[100vw] md:w-[90vw] lg:w-[70vw] xl:w-[40vw] "
        }
                pt-0 pb-2 z-50
transition-all duration-500 ease-in-out 
transform ${show && show == true ? "translate-x-0" : "translate-x-full"}
bg-white fixed bottom-0 right-0 top-0 

 overflow-x-hidden
overflow-y-hidden border-l border-gray-200 xl:block scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full`}
      >
        {/*  Header */}
        <div className="sticky px-5 py-3 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <div className="text-lg leading-6 font-medium text-slate-900">
              {props.title}
            </div>

            <button
              className="text-slate-400 hover:text-slate-500"
              onClick={(e) => {
                e.stopPropagation();
                props.setShow(false);
              }}
            >
              <div className="sr-only">Close</div>
              <svg className="w-4 h-4 fill-current">
                <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
              </svg>
            </button>
          </div>
        </div>
        {/* Content - Children */}
        <div
          className={`${
            props.scrollable
              ? props.showFooter == true
                ? "h-[calc(100vh-150px)] pb-12 overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full"
                : "h-[calc(100vh-120px)] overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full"
              : "h-full"
          }`}
        >
          {props.children}
        </div>
        {/* Footer */}
        {props.showFooter && (
          <div className="absolute w-full bottom-0 px-5 py-4 bg-white border-t border-gray-200">
            <div className="flex flex-wrap justify-end space-x-2">
              <Button
                color="primary"
                // className="btn-sm bg-brand-500 hover:bg-brand-600 text-white"
                handleClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.setShow(false);
                }}
              >
                {props.closeButtonText || "Close"}
              </Button>
              {props.saveButton && (
                <Button
                  color="primary"
                  // className="btn-sm bg-brand-500 hover:bg-brand-600 text-white"
                  handleClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.saveButtonClick(); // call the save button function
                  }}
                >
                  {props.saveButtonText || "Save"}
                </Button>
              )}
            </div>
          </div>
        )}
      </aside>
    </div>
  );
}

import { Fragment, useEffect, useMemo, useState } from "react";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import RadioGroupCards from "../../../components/RadioGroupCards";
import { Paragraph } from "../../../components/Typography/Paragraph";
import { Label } from "../../../components/Typography/FieldLabel";
import ReadOnlyAnswer from "./ReadOnlyAnswer";
import {
  Checkboxes,
  Dropdown,
  RadioGroupSingle,
  Section,
  SectionDivider,
  TextArea,
  TextInput,
} from "./DataEntry";
import JurisdictionInput from "./JurisdictionInput";
import { Jurisdictions } from "../../../modules/_jurisdiction_list";
import Divider from "../../../components/Divider";
import SingleOptions from "../../../components/SingleOptions";
import { object } from "prop-types";
import Tip from "../../../components/Tip";
import Assumptions from "./Assumptions";

export default function DataMiningInfo({
  create,
  newProject,
  languageOptions,
  callCenterLocationOptions,
  project,
  onFieldChange,
  handleSelectedLanguage,
  handleSingleSelect,
  handleMultiSelect,
  handleMultiDeSelect,
  handleDeselectedLanguage,
  handleClearAllSelections,
  handleSelect,
  onSelectionChange,
  selectedJurisdictions,
  setSelectedJurisdictions,
  field,
  service,
  scope,
  isChecked,
}) {
  const [options, setOptions] = useState([]);
  const [data, setData] = useState({});
  const [opts2, setOpts2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jurisidctions, setJurisdictionOptions] = useState([]);

  useEffect(() => {
    console.log(
      "DataMiningInfo useEffect",
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ]
    );
    setData(newProject);
  }, [newProject]);

  useMemo(() => {
    const jurisdictions = Jurisdictions.getJurisdictions();
    const priority = [
      { id: 124, alpha2: "ca", alpha3: "can", name: "Canada" },
      {
        id: 826,
        alpha2: "gb",
        alpha3: "gbr",
        name: "United Kingdom",
      },
      { id: 840, alpha2: "us", alpha3: "usa", name: "United States" },
    ];
    //combine the two arrays
    const combined = [...priority, ...jurisdictions];

    setJurisdictionOptions(combined);
  }, []);

  useEffect(() => {
    //setOpts2
    console.log("UseEffect DataMiningInfo opts2");
    if (
      newProject &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      setOpts2(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    }
  }, [newProject]);

  useEffect(() => {
    if (
      newProject &&
      newProject.services &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      setOptions(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    } else {
      console.log("UNDEFINED");
    }
  }, [newProject]);

  useEffect(() => {
    if (opts2.weekday_hours !== "custom" && create) {
      console.log("Remove custom hours");
      onFieldChange(
        "options",
        {
          target: {
            value: "",
          },
        },

        "custom_weekday_hours",
        service
      );
    }
  }, [opts2.weekday_hours]);

  useEffect(() => {
    if (opts2.weekend_hours !== "custom" && create) {
      console.log("Remove custom hours");
      onFieldChange(
        "options",

        {
          target: {
            value: "",
          },
        },

        "custom_weekend_hours",
        service
      );
    }
  }, [opts2.weekend_hours]);

  return (
    <div className="mt-8">
      {/* <Assumptions service={service} solicitation={newProject} /> */}
      <div className=" mt-6 grid grid-cols-6 gap-y-6 gap-x-4 ">
        {create && create == true && (
          <div className="col-span-full bg-[#f6f8ff] border border-brand-200 rounded p-2 pb-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <Section
              label="Assumptions"
              // description={`${
              //   create
              //     ? "These fields are required, and will help ensure a more consistent bidding process. "
              //     : "These are the assumptions that were made when the project was created. Please use these assumptions when creating your quotes."
              // }`}
              create={create}
            />{" "}
            <Tip colSpan={"full"} noMargin>
              <div className="inline">
                The fields in the assumptions section are required. It is
                extremely important to fill out the Assumptions section to get
                simple, accurate quotes.
                <span className="ml-1 font-medium">
                  We've provided you with some default answers.
                </span>
              </div>
            </Tip>
            <div className="col-span-full space-y-4">
              {/* <RadioGroupSingle
                cols={4}
                rows={1}
                tooltip={{
                  title: "Combined or Separate Quotes",
                  text: (
                    <div className="inline">
                      <div className="mb-4">
                        Many vendors will provide discounts in their quotes
                        provided you are willing to give them the Data Mining
                        and Review work. This can skew the results of the quote,
                        making it harder to compare. We highly recommend that
                        you ask the vendors to keep the quotes for Review and
                        Data Mining separate.
                        <span className="ml-1 font-medium">
                          When keeping the separate, we will inform the vendors
                          to include any discount assumptions in the Review
                          quote only.
                        </span>
                      </div>
                    </div>
                  ),
                }}
                type="single"
                label={`Should we include a note ensuring the vendor only quotes  ${
                  service && service == "document_review"
                    ? "Review"
                    : "Data Mining"
                } work in this section?`}
                description=""
                service={service}
                field="multiple_quotes"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                open={true}
                newProject={newProject}
                handleSelect={handleSingleSelect}
                defaultValue="yes"
                options={[
                  {
                    id: 1,
                    title: "Yes",
                    description: "",
                    value: "yes",
                  },
                  {
                    id: 2,
                    title: "No",
                    description: "",
                    value: "no",
                  },
                ]}
              /> */}
              <Checkboxes
                label="Require separate quotes for Data Mining and Review?"
                description="Check this box if you require the vendor to keep the quotes separate."
                tooltip={{
                  title: "Combined or Separate Quotes",
                  text: (
                    <div className="inline">
                      <div className="mb-4">
                        Many vendors will provide discounts in their quotes
                        provided you are willing to give them the Data Mining
                        and Review work. This can skew the results of the quote,
                        making it harder to compare. We highly recommend that
                        you ask the vendors to keep the quotes for Review and
                        Data Mining separate.
                        <span className="ml-1 font-medium">
                          When keeping the separate, we will inform the vendors
                          to include any discount assumptions in the Review
                          quote only.
                        </span>
                      </div>
                    </div>
                  ),
                }}
                service={service}
                field="multiple_quotes"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                newProject={newProject}
                onFieldChange={onFieldChange}
                options={[]}
                defaultChecked={true}
              />
              <Checkboxes
                label="Require total price?"
                description="Check this box if you require the vendor to provide a total price."
                tooltip={{
                  title: "Combined or Separate Quotes",
                  text: (
                    <div className="inline">
                      <div className="mb-4">
                        Many vendors will provide discounts in their quotes
                        provided you are willing to give them the Data Mining
                        and Review work. This can skew the results of the quote,
                        making it harder to compare. We highly recommend that
                        you ask the vendors to keep the quotes for Review and
                        Data Mining separate.
                        <span className="ml-1 font-medium">
                          When keeping the separate, we will inform the vendors
                          to include any discount assumptions in the Review
                          quote only.
                        </span>
                      </div>
                    </div>
                  ),
                }}
                service={service}
                field="require_total"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                newProject={newProject}
                onFieldChange={onFieldChange}
                options={[]}
                defaultChecked={true}
              />
              <Checkboxes
                label="Require pricing model?"
                description="Check this box if you require the vendor to provide their pricing model."
                tooltip={{
                  title: "Combined or Separate Quotes",
                  text: (
                    <div className="inline">
                      <div className="mb-4">
                        Many vendors will provide discounts in their quotes
                        provided you are willing to give them the Data Mining
                        and Review work. This can skew the results of the quote,
                        making it harder to compare. We highly recommend that
                        you ask the vendors to keep the quotes for Review and
                        Data Mining separate.
                        <span className="ml-1 font-medium">
                          When keeping the separate, we will inform the vendors
                          to include any discount assumptions in the Review
                          quote only.
                        </span>
                      </div>
                    </div>
                  ),
                }}
                service={service}
                field="require_pricing_model"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                newProject={newProject}
                onFieldChange={onFieldChange}
                options={[]}
                defaultChecked={true}
              />
              <Checkboxes
                label="Allow contingent discounts?"
                description="Check this box if the vendor is allowed to provide contingent discounts for this service."
                tooltip={{
                  title: "Combined or Separate Quotes",
                  text: (
                    <div className="inline">
                      <div className="mb-4">
                        Many vendors will provide discounts in their quotes
                        provided you are willing to give them the Data Mining
                        and Review work. This can skew the results of the quote,
                        making it harder to compare. We highly recommend that
                        you ask the vendors to keep the quotes for Review and
                        Data Mining separate.
                        <span className="ml-1 font-medium">
                          When keeping the separate, we will inform the vendors
                          to include any discount assumptions in the Review
                          quote only.
                        </span>
                      </div>
                    </div>
                  ),
                }}
                service={service}
                field="contingent_discounts"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                newProject={newProject}
                onFieldChange={onFieldChange}
                options={[]}
                defaultChecked={true}
              />
            </div>
            <TextInput
              label="Approximately how many documents are in the data set?"
              subLabel="If you are unsure, use our default of 5,000 per GB of data."
              tooltip={{
                title: "Document total",
                text: "Most vendors will add line items based on the number of documents they have to look at.  If you are unsure, add an assumption of 5,000 per GB of data.",
              }}
              service={service}
              field="document_count"
              create={create}
              opts2={opts2}
              placeholder=""
              onFieldChange={onFieldChange}
              // defaultValue={
              //   opts2 && opts2.data_size ? opts2.data_size * 5000 : ""
              // }
              required={false}
            />
            <TextInput
              label="How much data requires processing?"
              tooltip={{
                title: "Data Volume",
                text: "Letting the vendor know the data volume will help them tailor their quote to your needs.",
              }}
              service={service}
              field="data_size"
              type="size"
              create={create}
              opts2={opts2}
              placeholder=""
              onFieldChange={onFieldChange}
              indicator="GB"
            />
            <RadioGroupSingle
              cols={4}
              rows={1}
              type="single"
              label="Is this data compressed or uncompressed?"
              tooltip={{
                title: "Data compression",
                text: "If it's not loose files such as spreadsheets and documents, it's likely compressed.  The cost to process and host data will be impacted by whether or not the data is compressed.  If you are unsure, select 'Compressed' to be safe.",
              }}
              description=""
              service={service}
              field="data_compression"
              create={create}
              opts2={opts2}
              placeholder=""
              fieldType="single"
              open={true}
              newProject={newProject}
              handleSelect={handleSingleSelect}
              defaultValue="compressed"
              options={[
                {
                  id: 1,
                  title: "Compressed",
                  description: "",
                  value: "compressed",
                },
                {
                  id: 2,
                  title: "Uncompressed",
                  description: "",
                  value: "uncompressed",
                },
              ]}
            />
            <TextInput
              label="What expansion rate should the vendor use when quoting?"
              subLabel="If you are unsure, use our default expansion rate of 2x for consistency."
              tooltip={{
                title: "Expansion rate",
                text: "Some vendors charge for the base data that is uploaded; however, others charge for the expanded volume.  The expanded volume is the total disk space used after extracting. If you are unsure, you can use our default expansion rate of 2x for consistency.  Just keep in mind that the expansion rate can be unpredictable as it is influenced by the characteristics of the data and the way the data is processed.",
              }}
              service={service}
              field="expansion_rate"
              create={create}
              opts2={opts2}
              placeholder=""
              onFieldChange={onFieldChange}
              defaultValue={2}
            />
            {service && service == "document_review" && (
              <TextInput
                label="What percent of the documents should the vendor assume will require manual review?"
                subLabel="Some vendors call this the 'survival' rate."
                tooltip={{
                  title: "Survivial Rate",
                  text: "To give you the an accurate quote, vendors need to assume that a certain percentage of the documents will require manual review.  This is often referred to as the 'survival' rate.  If you are unsure, use 15% as a default.  Note: The actual survival rate will depend on the data, and could impact the final cost. This is a good reason to require a pricing model, so you can calculate the new price based on new assumptions.",
                }}
                service={service}
                field="survival_rate"
                create={create}
                opts2={opts2}
                placeholder=""
                onFieldChange={onFieldChange}
                indicator="%"
                defaultValue={15}
              />
            )}
            <TextInput
              label="How many hours per week should the vendor include for project management?"
              tooltip={{
                title: "Project Management Hours",
                text: "Vendors will typically assume you'll need project management hours.  It's best to give them a number to shoot for so that all quotes are consistent. If you aren't sure, use 20 hours per week just to keep all quotes consistent.",
              }}
              service={service}
              field="hours_per_week_for_status_calls"
              type="hours_per_week"
              create={create}
              opts2={opts2}
              placeholder=""
              onFieldChange={onFieldChange}
              defaultValue={20}
            />
            <RadioGroupSingle
              cols={4}
              rows={1}
              stacked={false}
              type="single"
              label="Which staffing option do you prefer?"
              subLabel="If you don't have a preference, select 'Most cost effective' to get the best price."
              description=""
              tooltip={{
                title: "Staffing Preference",
                text: "Some vendors will quote offshore resources by default; however, sometimes they will offer both in the fine print, making it difficult to compare quotes.  Letting the vendor know your preference will help ensure that all quotes are consistent. Keep in mind, however, that some vendors do not offer offshore options.  This question isn't intended to disqualify vendors.  It is here to ensure they submit only one option.  ",
              }}
              service={service}
              field="offshore_resources"
              create={create}
              opts2={opts2}
              placeholder=""
              fieldType="single"
              open={true}
              newProject={newProject}
              handleSelect={handleSingleSelect}
              defaultValue="most_cost_effective"
              options={[
                {
                  id: 1,
                  title: "Onshore",
                  description: "",
                  value: "onshore",
                },
                {
                  id: 2,
                  title: "Offshore",
                  description: "",
                  value: "offshore",
                },
                {
                  id: 2,
                  title: "Most cost effective",
                  description: "",
                  value: "most_cost_effective",
                },
              ]}
            />
            {service && service == "document_review" && (
              <RadioGroupSingle
                cols={4}
                rows={1}
                stacked={false}
                type="single"
                label="Which hosting option do you want the vendor to quote?"
                //  subLabel="If you don't have a preference, select 'Most cost effective' to get the best price."
                description=""
                tooltip={{
                  title: "Hosting Options",
                  text: "Many vendors will only host the data that is promoted to Review; however, some vendors default to hosting all data that was processed regardless of whether it was promoted to Review. This can make it difficult to compare quotes.  Letting the vendor know your preference will help ensure that all quotes are consistent.  ",
                }}
                service={service}
                field="hosting_option"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                open={true}
                newProject={newProject}
                handleSelect={handleSingleSelect}
                defaultValue="all_data"
                options={[
                  {
                    id: 1,
                    title: "All Data",
                    description: "",
                    value: "all_data",
                  },
                  {
                    id: 2,
                    title: "Review Data Only",
                    description: "",
                    value: "review_data_only",
                  },
                ]}
              />
            )}
          </div>
        )}
        <div className="col-span-6 text-xl font-medium text-slate-900">
          Bid Request Details
        </div>
        <SectionDivider />
        {create && (
          <Tip colSpan={"full"} noMargin>
            The rest of this form is optional but we highly recommend completing
            it to the best of your ability. These are the requirements that you
            will expect vendors to meet. Giving them all the information up
            front will ensure that you get the most accurate quotes.
          </Tip>
        )}

        <Section
          label="  General Information"
          description={`${
            create
              ? "Let's start by gathering information related to the project. "
              : ""
          }`}
          create={create}
        />
        {/* Client Industry */}
        <Dropdown
          label="What is the client's industry?"
          tooltip={{
            title: "Client Industry",
            text: "Letting the vendor know the client's industry can help them better understand the types of data they may enounter.",
          }}
          service={service}
          field="client_industry"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleSingleSelect}
          handleDeselect={handleDeselectedLanguage}
          handleClearAllSelections={(value) =>
            handleClearAllSelections(value, service, "client_industry")
          }
          options={[
            { name: "Healthcare" },
            {
              name: "Manufacturing",
            },
            { name: "Transportation" },
            { name: "Other" },
          ]}
        />
        {/* Data Owners Role */}
        <TextInput
          label="What is the data owners' roles in the company?"
          tooltip={{
            title: "Data Owners",
            text: "Letting the vendor know the role of the data owners can help them better understand the types of data they may enounter.",
          }}
          service={service}
          field="data_owner_roles"
          create={create}
          opts2={opts2}
          placeholder=""
          onFieldChange={onFieldChange}
        />
        {/* Third party notifications */}
        <Dropdown
          label="Will 3rd party notification be required?"
          tooltip={{
            title: "Third Party Notifications",
            text: "Letting the vendor know whether or not third party notifications will be required can help them better understand the scope of the project.",
          }}
          service={service}
          field="third_party_notification"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleSingleSelect}
          handleDeselect={handleDeselectedLanguage}
          handleClearAllSelections={(value) =>
            handleClearAllSelections(value, service, "client_industry")
          }
          options={[
            { name: "Yes" },
            {
              name: "No",
            },
            { name: "Unsure" },
          ]}
        />
        {/* Regulatory concerns */}
        <Dropdown
          label="Is this data subject to any regulations?"
          tooltip={{
            title: "Regulatory Concerns",
            text: "Regulatory matters may dictate how the vendor handles the data.",
          }}
          service={service}
          field="subject_to_regulations"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="multiSelect"
          open={true}
          newProject={newProject}
          handleSelect={handleMultiSelect}
          handleDeselect={(value) =>
            handleMultiDeSelect(value, service, "subject_to_regulations")
          }
          handleDeselectedLanguage={(value) =>
            handleMultiDeSelect(value, service, "subject_to_regulations")
          }
          handleClearAllSelections={(value) =>
            handleClearAllSelections(value, service, "subject_to_regulations")
          }
          options={[
            { name: "GDPR" },
            {
              name: "CPRA",
            },
            { name: "None" },
          ]}
        />
        {/* Data origin */}
        <Dropdown
          label="Where did this data originate?"
          tooltip={{
            title: "Data Origin",
            text: "If the data needs to remain in the jurisdiction where it originated, the vendor may have to provide special handling.",
          }}
          service={service}
          field="data_origin_location"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleSingleSelect}
          handleDeselect={handleDeselectedLanguage}
          handleClearAllSelections={(value) =>
            handleClearAllSelections(value, service, "data_origin_location")
          }
          options={jurisidctions ? jurisidctions : []}
        />
        <SectionDivider />
        {/* New Section */}
        <Section
          label="Data Information"
          description="Use the form fields below to let the provider know about the compromised data."
          create={create}
        />
        {/* Processed before? */}
        <Dropdown
          label="Has this data been processed and culled already?"
          tooltip={{
            title: "Processing",
            text: "If the data has been processed in the past, the data provided will likely be more accurate.",
          }}
          service={service}
          field="processed_before"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleSingleSelect}
          handleDeselect={handleDeselectedLanguage}
          handleClearAllSelections={(value) =>
            handleClearAllSelections(value, service, "processed_before")
          }
          options={[
            { name: "Yes" },
            {
              name: "No",
            },
            { name: "Unsure" },
          ]}
        />
        {/* Compressed/Uncompressed */}
        {/* <RadioGroupSingle
          // maybe use dropdown instead?
          cols={3}
          rows={2}
          type="single"
          label="Is this data compressed or uncompressed?"
          description=""
          tooltip="What is the project scope?"
          service={service}
          field="data_compression"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleSingleSelect}
          options={[
            {
              id: 1,
              title: "Compressed",
              description: "",
              value: "compressed",
            },
            {
              id: 2,
              title: "Uncompressed",
              description: "",
              value: "uncompressed",
            },
            {
              id: 3,
              title: "Not Sure",
              description: "",
              value: "not_sure",
            },
          ]}
        /> */}
        <RadioGroupSingle
          // maybe use dropdown instead?
          cols={4}
          rows={2}
          type="multiSelect"
          label="Select any known file types."
          tooltip="What is the project scope?"
          service={service}
          field="known_file_types"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleMultiSelect}
          options={[
            {
              id: 1,
              title: "Word Processing",
              description: "",
              value: "word_processing",
            },
            {
              id: 2,
              title: "Spreadsheets",
              description: "",
              value: "spreadsheets",
            },
            {
              id: 3,
              title: "Presentations",
              description: "",
              value: "presentations",
            },
            {
              id: 4,
              title: "Emails",
              description: "",
              value: "emails",
            },
            {
              id: 5,
              title: "Images",
              description: "",
              value: "images",
            },
            {
              id: 6,
              title: "PDFs",
              description: "",
              value: "pdfs",
            },
            {
              id: 7,
              title: "Audio",
              description: "",
              value: "audio",
            },
            {
              id: 8,
              title: "Video",
              description: "",
              value: "video",
            },
            {
              id: 9,
              title: "Databases",
              description: "",
              value: "databases",
            },
            {
              id: 10,
              title: "Programming Artifacts",
              description: "",
              value: "programming_artifacts",
            },
            {
              id: 11,
              title: "Compressed Files",
              description: "",
              value: "compressed_files",
            },
            {
              id: 12,
              title: "Computer Aided Design (CAD)",
              description: "",
              value: "computer_aided_design",
            },

            {
              id: 13,
              title: "Other",
              description: "",
              value: "other",
            },
          ]}
        />
        {/* Special Document Types */}
        <RadioGroupSingle
          // maybe use dropdown instead?
          cols={3}
          rows={2}
          type="multiSelect"
          label="Data characteristics."
          description="Select any known special data characteristics. "
          tooltip="What is the project scope?"
          service={service}
          field="special_document_characteristics"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleMultiSelect}
          options={[
            {
              id: 1,
              title: "Long PDF's",
              description: "",
              value: "long_pdfs",
            },
            {
              id: 2,
              title: "Scanned PDF's",
              description: "",
              value: "scanned_pdfs",
            },
            {
              id: 3,
              title: "PDF Binders",
              description: "",
              value: "pdf_binders",
            },
            {
              id: 4,
              title: "Foreign Languages",
              description: "",
              value: "foreign_languages",
            },
            {
              id: 5,
              title: "High PII density PDF's",
              description: "",
              value: "high_density_pdf",
            },
            {
              id: 6,
              title: "High PII density Spreadsheets",
              description: "",
              value: "high_density_spreadsheets",
            },
            {
              id: 7,
              title: "Proprietary Formats",
              description: "",
              value: "proprietary_formats",
            },
            {
              id: 8,
              title: "Encrypted Files",
              description: "",
              value: "encrypted_files",
            },

            {
              id: 9,
              title: "Password protected",
              description: "",
              value: "password_protected",
            },
          ]}
        />
        {/* Types of documents */}
        <TextArea
          label="Other data types"
          description="Is there anything else we need to know about the documents?"
          tooltip="Use the Additional Info field if you need more room."
          service={service}
          field="additional_info_about_documents"
          create={create}
          newProject={newProject}
          opts2={opts2}
          placeholder="Marketing, HR, Finance, etc."
          onFieldChange={onFieldChange}
        />
        <SectionDivider />
        {/* Section - Sensitive Data */}
        <Section
          label="Sensitive Data"
          description="Use the form fields below to let the provider know about the sensitive data."
          create={create}
        />
        {/* Types of compromised data */}
        <RadioGroupSingle
          // maybe use dropdown instead?
          cols={3}
          rows={2}
          type="multiSelect"
          label="Types of compromised personal information"
          description="Select any projected types of compromised personal information. "
          tooltip="What is the project scope?"
          service={service}
          field="compromised_personal_information"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleMultiSelect}
          options={[
            {
              id: 1,
              title: "Contact Information",
              description: "Examples: Name, Address, Phone Number, Email",
              value: "contact_information",
            },
            {
              id: 2,
              title: "Identity Information",
              description: "Examples: SSN, Driver's License, Passport",
              value: "identity_information",
            },
            {
              id: 3,
              title: "Financial Information",
              description: "Examples: Credit Card, Bank Account, Tax ID",
              value: "financial_information",
            },
            {
              id: 4,
              title: "Health Information",
              description: "Examples: Medical Records, Health Insurance",
              value: "health_information",
            },
            {
              id: 5,
              title: "Education Information",
              description: "Examples: School Records, Transcripts, Grades",
              value: "education_information",
            },
            {
              id: 6,
              title: "Other",
              description: "Other types of compromised personal information",
              value: "other",
            },
          ]}
        />
        {/* Additional sensitive data */}
        <TextArea
          label="Additional sensitive data"
          description="Is there anything else we need to know about the sensitive data?"
          tooltip="Use the Additional Info field if you need more room."
          service={service}
          field="additional_info_about_sensitive_data"
          create={create}
          newProject={newProject}
          opts2={opts2}
          placeholder="Tell us about any special data types including retirement accounts, payroll information, special account numbers, etc. "
          onFieldChange={onFieldChange}
        />
        <Checkboxes
          label="Treat business contact information as personal data"
          description="Check this box if business related contact information should be treated as personal data."
          tooltip=""
          service={service}
          field="business_data_treated_as_personal"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          newProject={newProject}
          onFieldChange={onFieldChange}
          options={[]}
        />
        <SectionDivider />
        {/* Automation Section*/}
        <Section
          label="Automation"
          description="Check all the automation options that you want your vendor to use."
          create={create}
        />
        <Checkboxes
          label="Keyword Searches"
          description="Check this box if the provider is permitted to use keyword searches for automated tagging."
          tooltip=""
          service={service}
          field="automation_keywords"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          newProject={newProject}
          onFieldChange={onFieldChange}
          options={[]}
        />
        <Checkboxes
          label="Regular Expressions"
          description="Check this box if the provider is permitted to use regular expressions for automated tagging."
          tooltip=""
          service={service}
          field="automation_regular_expressions"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          newProject={newProject}
          onFieldChange={onFieldChange}
          options={[]}
        />
        <Checkboxes
          label="Machine Learning"
          description="Check this box is the provider is permitted to use AI techniques for automated tagging."
          tooltip=""
          service={service}
          field="automation_machine_learning"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          newProject={newProject}
          onFieldChange={onFieldChange}
          options={[]}
        />
        <Checkboxes
          label="Document and Image Classification"
          description="Check this box is the provider is permitted to use AI to classify documents."
          tooltip=""
          service={service}
          field="automation_classification"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          newProject={newProject}
          onFieldChange={onFieldChange}
          options={[]}
        />
        <Checkboxes
          label="Random Sampling"
          description="Check this box is the provider is permitted to use random sampling to determine scope."
          tooltip=""
          service={service}
          field="automation_random_sampling"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          newProject={newProject}
          onFieldChange={onFieldChange}
          options={[]}
        />
        {service && service == "document_review" && (
          <Checkboxes
            label="Other Review Automation"
            description="Check this box is the provider is permitted to use other types of review automation."
            tooltip=""
            service={service}
            field="other_review_automation"
            create={create}
            opts2={opts2}
            placeholder=""
            fieldType="single"
            newProject={newProject}
            onFieldChange={onFieldChange}
            options={[]}
          />
        )}
        <SectionDivider />
        {/* Operations Section */}
        <Section
          label="Operations"
          description="Use the fields below to let the supplier know the best way to operate."
          create={create}
        />
        <Dropdown
          label="Select all allowed review center locations"
          tooltip=""
          service={service}
          field="review_center_locations"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="multiSelect"
          open={true}
          newProject={newProject}
          handleSelect={handleMultiSelect}
          handleDeselect={(value) =>
            handleMultiDeSelect(value, service, "review_center_locations")
          }
          handleClearAllSelections={(value) =>
            handleClearAllSelections(value, service, "review_center_locations")
          }
          options={callCenterLocationOptions ? callCenterLocationOptions : []}
        />
        <Dropdown
          name="required_langages"
          label="What languages are staff required to speak?"
          tooltip="What is the client's industry?"
          service={service}
          field="required_langages"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="multiSelect"
          open={true}
          newProject={newProject}
          handleSelect={handleMultiSelect}
          handleDeselect={(value) =>
            handleMultiDeSelect(value, service, "required_langages")
          }
          handleClearAllSelections={(value) =>
            handleClearAllSelections(value, service, "required_langages")
          }
          options={languageOptions ? languageOptions : []}
        />
        <Checkboxes
          //PROBLEM -->
          label="Project Reporting"
          description="Check this box to give your project reporting requirements."
          tooltip=""
          service={service}
          field="project_reporting"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          newProject={newProject}
          onFieldChange={onFieldChange}
          options={[
            {
              label:
                (service == "data_mining" ? "Data Mining" : "Docment Review") +
                " progress report",
              value: "daily_progress_report",
            },
            {
              label: "Sensitive data report",
              value: "daily_sensitive_data_report",
            },
            {
              label: "Incurred cost report",
              value: "incurred_cost_report",
            },
          ]}
        />
        <Dropdown
          label="If you have a preferred tool for the vendor to use, please select it."
          tooltip=""
          service={service}
          field="preferred_tools"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="multiSelect"
          open={true}
          newProject={newProject}
          handleSelect={handleMultiSelect}
          handleDeselect={(value) =>
            handleMultiDeSelect(value, service, "preferred_tools")
          }
          handleClearAllSelections={(value) =>
            handleClearAllSelections(value, service, "preferred_tools")
          }
          options={[
            { name: "Relativity Server" },
            {
              name: "RelativityOne",
            },
            { name: "RelativityOne for Data Breach" },
            { name: "Canopy" },
            { name: "No Preference" },
            { name: "Other" },
          ]}
        />
        <Checkboxes
          label="Third party access"
          description="Check this box if you will require access to the vendor's tools for collaboration."
          tooltip=""
          service={service}
          field="third_party_access"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          newProject={newProject}
          onFieldChange={onFieldChange}
          options={[]}
        />
        {service && service == "document_review" && (
          <Dropdown
            label="What review levels are required?"
            tooltip=""
            service={service}
            field="review_levels"
            create={create}
            opts2={opts2}
            placeholder=""
            fieldType="multiSelect"
            open={true}
            newProject={newProject}
            handleSelect={handleMultiSelect}
            handleDeselect={(value) =>
              handleMultiDeSelect(value, service, "review_levels")
            }
            handleClearAllSelections={(value) =>
              handleClearAllSelections(value, service, "review_levels")
            }
            options={[
              { name: "First pass review" },
              {
                name: "Data extraction review",
              },
              { name: "First pass QC" },
              { name: "Second Pass QC" },
              { name: "More than two QC Passess" },
            ]}
          />
        )}
        {service && service == "document_review" && (
          <RadioGroupSingle
            // maybe use dropdown instead?
            cols={3}
            rows={2}
            type="multiSelect"
            label="Reviewer Qualifications"
            description="Who do you want to review the documents? "
            tooltip="What is the project scope?"
            service={service}
            field="reviewer_qualifications"
            create={create}
            opts2={opts2}
            placeholder=""
            fieldType="single"
            open={true}
            newProject={newProject}
            handleSelect={handleMultiSelect}
            options={[
              {
                id: 1,
                title: "Licensed Attorneys",
                description: "",
                value: "licensed_attorneys",
              },
              {
                id: 2,
                title: "Paralegals",
                description: "",
                value: "paralegals",
              },
              {
                id: 3,
                title: "Contract Attorneys",
                description: "",
                value: " contract_attorneys",
              },
              {
                id: 4,
                title: "Anyone with a JD",
                description: "",
                value: "anyone_with_jd",
              },
              {
                id: 5,
                title: "Anyone with attorney supervision",
                description: "",
                value: "anyone_with_attorney_supervision",
              },
              {
                id: 6,
                title: "Other",
                description: "",
                value: "other",
              },
            ]}
          />
        )}
        {service && service == "document_review" && (
          <Checkboxes
            label="Jurisdictional Restrictions"
            description="Check this box if reviewers are required to be barred in a specific jurisdiction."
            tooltip=""
            service={service}
            field="jurisdictional_restrictions"
            create={create}
            opts2={opts2}
            placeholder=""
            fieldType="single"
            newProject={newProject}
            onFieldChange={onFieldChange}
            options={[]}
          />
        )}
        {service &&
          service == "document_review" &&
          opts2.jurisdictional_restrictions &&
          opts2.jurisdictional_restrictions == true && (
            //if yes, which jurisdictions?
            <TextArea
              label="Which jurisdictions?"
              description="If you selected yes, please list the jurisdictions."
              tooltip="Use the Additional Info field if you need more room."
              service={service}
              field="jurisdictional_restrictions_jurisdictions"
              create={create}
              newProject={newProject}
              opts2={opts2}
              placeholder="New York, California, etc."
              onFieldChange={onFieldChange}
            />
          )}
        {/* {service && service == "document_review" && (
          <>
            <SectionDivider />
            <Section
              label="Quality Control"
              description="Use the fields below to let the supplier know your quality control requirements."
              create={create}
            />
          </>
        )} */}
        {service && service == "document_review" && (
          <>
            <SectionDivider />
            <Section
              label="Entity Consolidation"
              description="Use the fields below to let the supplier know your entity consolidation requirements."
              create={create}
            />
            <Checkboxes
              label="Rolling notifications"
              description="Check this box if rolling notifications will be required."
              tooltip=""
              service={service}
              field="rolling_notifications"
              create={create}
              opts2={opts2}
              placeholder=""
              fieldType="single"
              newProject={newProject}
              onFieldChange={onFieldChange}
              options={[]}
            />
            <Checkboxes
              label="Daily consolidation"
              description="Check this box if you require entity consolidation to happen daily."
              tooltip=""
              service={service}
              field="daily_consolidation"
              create={create}
              opts2={opts2}
              placeholder=""
              fieldType="single"
              newProject={newProject}
              onFieldChange={onFieldChange}
              options={[]}
            />
            <Checkboxes
              label="AI based entity consolidation"
              description="Check this box if you will allow AI based entity consolidation."
              tooltip={{
                title: "AI Entity Consolidation",
                text: "AI entity consolidation is the process of using AI to determine if pieces of PII are related to the same physical person.",
              }}
              service={service}
              field="ai_based_entity_consolidation"
              create={create}
              opts2={opts2}
              placeholder=""
              fieldType="single"
              newProject={newProject}
              onFieldChange={onFieldChange}
              options={[]}
            />
            <Checkboxes
              label="Manual entity consolidation"
              description="Check this box if you require manual entity consolidation."
              tooltip={{
                title: "Manual Entity Consolidation",
                text: "Manual entity consolidation is the process of manually reviewing documents to determine if they are related to the same entity.",
              }}
              service={service}
              field="manual_entity_consolidation"
              create={create}
              opts2={opts2}
              placeholder=""
              fieldType="single"
              newProject={newProject}
              onFieldChange={onFieldChange}
              options={[]}
            />
            {/* 
    Manual or Automated? 
    AI based or human based?
    Basic or advanced?
    
    
    */}
          </>
        )}
        <SectionDivider />
        {/* Project Reporting Section */}
        <Section
          label="Project Management"
          description="Use the fields below to let the supplier know your project management requirements."
          create={create}
        />
        {service && service == "document_review" && (
          <Checkboxes
            //PROBLEM -->
            label="Review progress reporting"
            description="Check this box to give review progress reporting requirements."
            tooltip=""
            service={service}
            field="review_progress_reporting"
            create={create}
            opts2={opts2}
            placeholder=""
            fieldType="single"
            newProject={newProject}
            onFieldChange={onFieldChange}
            options={[
              {
                label: "Documents reviewed per day",

                value: "documents_reviewed_per_day",
              },
              {
                label: "Documents remaining",
                value: "documents_remaining",
              },
              {
                label: "Average review speed",
                value: "average_review_speed",
              },
            ]}
          />
        )}
        {service && service == "document_review" && (
          <Checkboxes
            //PROBLEM -->
            label="Sensitive Data Reporting"
            description="Check this box to give PII status reporting requirements."
            tooltip=""
            service={service}
            field="sensitive_data_reporting"
            create={create}
            opts2={opts2}
            placeholder=""
            fieldType="single"
            newProject={newProject}
            onFieldChange={onFieldChange}
            options={[
              {
                label: "Reportable documents count",

                value: "reportable_documents_count",
              },
              {
                label: "Sensitive documents report",
                value: "senstive_documents_report",
              },
              {
                label: "Affected individuals report",
                value: "affected_individuals_report",
              },
            ]}
          />
        )}
        {/* Specify reporting frequency */}
        <Dropdown
          label="How frequently should the vendor provide status reporting?"
          tooltip=""
          service={service}
          field="reporting_frequency"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleSingleSelect}
          handleDeselect={handleDeselectedLanguage}
          handleClearAllSelections={(value) =>
            handleClearAllSelections(value, service, "reporting_requency")
          }
          options={[
            { name: "Multiple times per day" },
            {
              name: "Daily",
            },

            { name: "Two or more times per week" },
            { name: "Weekly" },
            { name: "Monthly" },
            { name: "Other" },
          ]}
        />
        {/* Third party platform access */}
        {/* Status call frequency */}
        {/* <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"> */}
        <div className="sm:col-span-6">
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Final Details
          </h3>
          {!create && (
            <ReadOnlyAnswer type="text" answer={opts2.additional_details} />
          )}
          {create && (
            <Paragraph>
              Use the below field to provide any additional details about your
              project or bid request that you think might be helpful to the
              vendor.
            </Paragraph>
          )}
          {create && (
            <div className="mt-1">
              <textarea
                id="additional_details"
                name="additional_details"
                rows={3}
                value={
                  opts2 && opts2.additional_details
                    ? opts2.additional_details
                    : ""
                }
                onChange={(e) => {
                  onFieldChange(
                    "options",

                    e,

                    "additional_details",
                    service,

                    null
                  );
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

// import PROFILE from "../data_sources/fake.js";

import { utils } from "./_utils";
import { postRequest, getRequest, signupPostRequest } from "./ApiRequest";
import setAuthToken from "../config/setAuthToken";
import jwt_decode from "jwt-decode";
import { features } from "./_features";

let setProfileFunction = null;
let getProfileFunction = null;
let storageStrategy = "sessionStorage";

export const _auth = {
  async verifyAuth(userId, name, token) {
    try {
      let data = {
        userId,
        name,
        guestToken: token,
      };
      let response = await postRequest("verify-guest");
      return response;
    } catch (err) {
      return [err];
    }
  },
  async verifyToken(token, userId) {
    try {
      let data = {
        id: userId,
        token: token,
      };
      let response = await postRequest("users/verify-token", data);
      if (response) {
        console.log("/users/verify-token", response.data.data.data);
      }
      return response.data.data.data;
    } catch (err) {
      return [err];
    }
  },

  async signup(
    email,
    password,
    fullName,
    companyName,
    accessCode,
    successCallback,
    errorCallback
  ) {
    try {
      let userData = {
        email: email,
        password: password,
        name: fullName,
        company: companyName,
        role: "Admin",
      };
      this.loader = true;
      console.info(userData);
      await signupPostRequest(
        "new-customer-registration",
        userData,
        successCallback,
        errorCallback
      )
        .then((response) => {
          console.log("RESPTEST DATA", response.data);
          successCallback(response.data);
        })
        .catch((error) => {
          errorCallback(error.response.data);
          // return error.response.data;
        });

      // let response = await postRequest("create-admin-tenant", userData);

      // if (response && response.data.status === 200) {
      //   console.log("Sending Back Response", response);
      //   this.loader = false;
      //   return true;
      // } else {
      //   console.log("There was an error creating the user");
      //   return false;
      // }
    } catch (e) {
      return false;
    }
  },
  async getAuth(history) {
    try {
      let token = _auth.getUserToken("jwtToken");
      if (!token) {
        return false;
      } else {
        setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

        let response = await getRequest(`get-auth`);
        console.log(response, ">resppp");
        if (response && response.data) {
          return true;
        } else {
          return false;
        }
      }
    } catch (e) {
      console.log(e, ">resppp");
    }
  },
  async login(userData) {
    try {
      // features.getFeatureFlags();
      // features.socketListener();
      this.loader = true;
      // console.info(userData);
      let response = await postRequest("login", userData);

      return response;
    } catch (e) {
      console.log("Returning error", e);
      return [false, e];
    }
  },

  async forgotPassword(obj) {
    try {
      let response = await postRequest("forgot-password", obj);
      return response;
    } catch (e) {
      return {
        error: e,
        success: false,
      };
    }
  },

  async resetPassword(data) {
    try {
      let response = await postRequest("reset-password", data);
      return response;
    } catch (e) {
      return {
        e: e,
        error: true,
      };
    }
  },
  async resetPasswordEmail(data, history) {
    try {
      let response = await postRequest("reset-new-user-password", data);
      console.log(response, "response..");
      if (response) {
        return response;
      }
    } catch (e) {
      return false;
    }
  },

  async logout(history) {
    //clear session storage
    sessionStorage.clear();
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("profile");
  },

  setProfileFunction: function (func) {
    setProfileFunction = func;
  },

  getProfileFunction: function (func) {
    getProfileFunction = func;
  },
  profile() {
    // entry point to get profile. If user is not logged in, return guest profile
    if (
      _auth.getUserProfile() === null ||
      _auth.getUserProfile() === undefined
    ) {
      return JSON.parse(_auth.getGuestProfile());
    } else {
      return _auth.getUserProfile();
    }
  },
  setUserProfile: function (profile, from) {
    if (profile === undefined) {
      console.log("setProfile STOPPED - profile is undefined", from);
      return false;
    }
    console.log("setProfile in _auth", profile, from);
    if (storageStrategy === "sessionStorage") {
      sessionStorage.setItem("profile", JSON.stringify(profile));
      return true;
    } else if (setProfileFunction) {
      console.log("This is the profile in setUserProfile", profile);
      setProfileFunction(profile, from);
      return true;
    }
    return false;
  },

  getUserProfile: function () {
    if (storageStrategy === "sessionStorage") {
      const profile = sessionStorage.getItem("profile");
      return profile ? JSON.parse(profile) : null;
    } else if (getProfileFunction) {
      console.log("getProfile in _auth", getProfileFunction());
      return getProfileFunction();
    } else {
      console.error("getProfile is not a function", null);
    }

    return null;
  },

  setGuestProfile(profile) {
    sessionStorage.removeItem("guestProfile");
    const {
      userId,
      name,
      email,
      tenantId,
      type,
      inviteId,
      assessment_name,
      token,
    } = profile;
    sessionStorage.setItem(
      "guestProfile",
      JSON.stringify({
        userId,
        name,
        email,
        tenantId,
        type,
        inviteId,
        assessment_name,
        token,
      })
    );
    return true;
  },
  removeGuestProfile() {
    sessionStorage.removeItem("guestProfile");
  },

  getGuestProfile() {
    let profile = sessionStorage.getItem("guestProfile");
    return profile;
  },
  getToken() {
    let token = _auth.getUserToken("jwtToken");
    if (token == null) {
      return null;
    }
    token = token.replace(/"([^"]+(?="))"/g, "$1");
    //remove quotes from token
    // token.replace(/['"]+/g, null).trimEnd
    return token;
  },
  setUserToken(token) {
    sessionStorage.removeItem("jwtToken");
    sessionStorage.setItem("jwtToken", token);
    return true;
  },

  getUserToken() {
    let token = sessionStorage.getItem("jwtToken");
    return token;
  },
  removeUserToken() {
    sessionStorage.removeItem("jwtToken");
  },
  removeUserProfile() {
    sessionStorage.removeItem("profile");
  },
  getTenantProfile(tenant) {
    return true;
  },
  getAccessCode() {
    return "457";
  },

  async MFA(otp, history) {
    try {
      this.loader = true;
      let data = {
        otp,
      };
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("mfa-otp", data);
      if (response) {
        console.log("mfa response _auth", response.data.status);
      }
      if (response && response.data.status === 200) {
        // Set token to ls
        // history.push({
        //   path: "/",
        // });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("mfa error", e);
      return false;
    }
  },

  async signup2(data) {
    // try {
    let userData = data;
    this.loader = true;
    // console.info(userData);
    let response = await postRequest("new-customer-registration", userData);

    if (response && response.response && response.response.status !== 200) {
      return response.response.data;
    } else {
      return response.data;
    }
    // } catch (error) {
    //   console.log(error, "error");
    //   return error.data;
    // }
  },
  async addUser(email, password, fullName, role) {
    try {
      let userData = {
        email: email,
        password: password,
        name: fullName,
        role: role,
      };
      this.loader = true;
      // console.info(userData);
      let response = await postRequest("new-customer-registration", userData);

      if (response && response.response && response.response.status !== 200) {
        return response.response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error, "error");
      return error.data;
    }
  },
  async getAllUsers(tenantId) {
    try {
      let response = await getRequest("get-all-users", {
        tenantId: tenantId,
      });
      return response;
    } catch (e) {
      return false;
    }
  },
  async acceptInvite(id, token, tenantId) {
    console.log("Accepting invite", id, token, tenantId);
    let data = {
      id: id,
      token: token,
      tenantId: tenantId,
    };
    // try {
    let response = await postRequest("accept-invite", data);
    if (response) {
      console.info("Response", response);
    }
    return response;
    // } catch (e) {
    //   return false;
  },

  checkNaughtyList(email) {
    console.info("Checking naughty list for", email);
    let domain = email.split("@")[1];
    console.info("Checking naughty list for", domain);
    let domains = utils.list();
    if (domains.indexOf(domain) != -1) {
      // console.info("Found domain in list", domain);
      return true;
    } else {
      // console.info("Did not find domain in list", domain);
      return false;
    }
  },

  async verifyGuest(userId, token, name, email, inviteId) {
    try {
      let data = {
        userId,
        name,
        guestToken: token,
        email,
        inviteId,
      };
      //debugger; //
      let response = await postRequest("verify-guest", data);
      return response;
    } catch (e) {
      return e;
    }
  },

  async getTentants(path, data) {
    path = "get-tenants";
    console.log("Getting Tenants", data);
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response;

    response = await postRequest(path, data);

    if (response.data.status === 200) {
      console.info("Response Returned - Got Tenants", data);
      return response.data.data.data;
    } else {
      console.info("Bad Response Returned", response);
      return response;
    }
  },

  async getTentants2() {
    const profile = this.getUserProfile();
    let path;
    let role = profile.role;
    let data = {};

    if (role == "Super Admin") {
      path = "get-tenants";
      data = {
        companyId: "all",
      };
    } else if (role == "Administrator") {
      path = "get-tenants";
      data = {
        companyId: profile.ocmpanyId,
      };
    } else {
      path = "get-user-tenants";
    }

    if (path == "get-user-tenants") {
      return false;
    }

    console.log("Getting Tenants", data);
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response;

    response = await postRequest(path, data);

    if (response.data.status === 200) {
      console.info("Response Returned - Got Tenants", data);
      return response.data.data.data;
    } else {
      console.info("Bad Response Returned", response);
      return response;
    }
  },
  async verifyOtpToken(data) {
    let path = "verifyOtp";

    let response = await postRequest(path, data);
    if (response) {
      console.log("verify", response.data.status);
    }
    if (response && response.data.status === 200) {
      // Set token to ls
      // history.push({
      //   path: "/",
      // });
      return true;
    } else {
      return false;
    }
  },
  getProfileRole(profile) {
    let role;
    role =
      profile.sysRole && profile.sysRole == "Super Admin"
        ? (role = "Super Admin")
        : profile.companyRole &&
          (profile.companyRole == "Administrator" ||
            profile.companyRole == "Observer")
        ? profile.companyRole
        : profile.tenants[0].role;
    if (role) {
      return role;
    } else {
      return null;
    }
  },
  setProfileRole(userProfile) {
    userProfile.tenantId = userProfile.tenants[0].tenantId._id;
    userProfile.tenantName = userProfile.tenants[0].tenantId.tenantTitle;
    userProfile.tenantType = userProfile.tenants[0].tenantId.type;

    userProfile.tenantRole = userProfile.tenants[0].role;

    userProfile.role = this.getProfileRole(userProfile);

    return userProfile;
  },
  buildProfile(userProfile) {
    console.log("Building Profile", userProfile);
    userProfile.tenantId = userProfile.tenants[0].tenantId._id;
    userProfile.tenantName = userProfile.tenants[0].tenantId.tenantTitle;
    userProfile.tenantType = userProfile.tenants[0].tenantId.type;

    userProfile.company = userProfile.tenants[0].tenantId.company;
    userProfile.companyId = userProfile.tenants[0].tenantId.companyId;
    userProfile.tenantCompanyDomain = userProfile.tenants[0].tenantId.domain;
    userProfile.tenantCompanyId = userProfile.tenants[0].tenantId.companyId
      ? userProfile.tenants[0].tenantId.companyId
      : userProfile.tenants[0].tenantId.tenantCompanyID;
    userProfile.tenantCompany = userProfile.tenants[0].tenantId.tenantCompany;

    // userProfile.role = this.getProfileRole(userProfile);
    userProfile.role =
      userProfile.sysRole && userProfile.sysRole == "Super Admin"
        ? (userProfile.role = "Super Admin")
        : userProfile.companyRole &&
          (userProfile.companyRole == "Administrator" ||
            userProfile.companyRole == "Observer")
        ? userProfile.companyRole
        : userProfile.tenants[0].role;
    console.log("BUILDING PROFILE - SET PROFILE & ROLE", userProfile);
    return userProfile;

    //   {
    //     "user_id": "63cc9957371c4c0c9443df26",
    //     "email": "oran.sears@breachlink.com",
    //     "name": "Oran Sears",
    //     "phone": "",
    //     "role": "Super Admin",
    //     "tenantRole": "Manager",
    //     "companyRole": "Administrator",
    //     "sysRole": "Super Admin",
    //     "status": "Enabled",
    //     "lastInviteSent": "2023-01-22T02:03:05.384Z",
    //     "dnd": false,
    //     "onBoardCompleted": false,
    //     "company": "Breachlink",
    //     "MFA": false,
    //     "tenants": [
    //         {
    //             "status": "Enabled",
    //             "role": "Manager",
    //             "tenantId": {
    //                 "_id": "63cc9959371c4c0c9443df2a",
    //                 "tenantTitle": "Admin Tenant",
    //                 "tenantCompany": "Breachlink",
    //                 "createdBy": "Oran Sears",
    //                 "createdById": "63cc9957371c4c0c9443df26",
    //                 "isRenamed": true,
    //                 "tenantCompanyID": "63cc9959371c4c0c9443df30",
    //                 "__v": 0,
    //                 "stripeCustomerId": "cus_NEhIAAd8ZvfiU9",
    //                 "type": "admin",
    //                 "projectIncrement": 1034
    //             },
    //             "_id": "63cc9959371c4c0c9443df2c",
    //             "lastInviteSent": "2023-01-22T02:03:05.384Z",
    //             "companyId": "63cc9959371c4c0c9443df30"
    //         }
    //     ],
    //     "tenantCount": 1,
    //     "tenantId": "64c5b5956dc9352b7675ff5a",
    //     "tenantInfo": {
    //         "_id": "64c5b5956dc9352b7675ff5a",
    //         "tenantTitle": "Bluesmoke-1",
    //         "type": "vendor"
    //     },
    //     "tenantName": "Bluesmoke-1",
    //     "showHome": true,
    //     "companyId": "63cc9959371c4c0c9443df30",
    //     "iat": 1690937265,
    //     "exp": 1690951665,
    //     "tenantType": "vendor"
    // }
  },

  // if (profile.role == "Administrator" || profile.role == "Observer") {
  //   let tenants = await _auth.getTentants(path, data);
  //   console.info("GET TENANTS", profile.role, tenants);
  //   if (tenants) {
  //     tenants.sort((a, b) =>
  //       a.tenantId.tenantTitle > b.tenantId.tenantTitle ? 1 : -1
  //     );
  //     setCompanyTenants(tenants);
  //     console.log("GET TENANTS setCompanyTenants", tenants);
  //     let showTenants = tenants;

  //     setTenants(showTenants);
  //     setLoader(false);
  //   } else {
  //     console.info("GET TENANTS Profile 67", profile.role, profile.tenants);
  //     let showTenants = profile.tenants;
  //     setTenants(showTenants);
  //     setLoader(false);
  //   }
  // } else {
  //   console.info("GET TENANTS Profile 73", profile.role, profile.tenants);
  //   let showTenants = profile.tenants;
  //   setTenants(showTenants);
  //   setLoader(false);
  // }
};

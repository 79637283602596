import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";
import { utils } from "./_utils";

import { sockets } from "./_notifications";
const { postRequest, getRequest } = require("./ApiRequest");

export const features = {
  features_old() {
    //status of features
    // released means it is available to all subscribed users
    // early access means it is available within tier to all subscribed users
    // beta means it is available to beta users
    // preview means it is available outside of tier to all subscribed users
    // coming soon means it is not available to anyone

    let features = [
      {
        FeatureName: "Upgrade",
        isOn: true,
        isOnInDev: true,
        isOnInBeta: true,
        isOnInProd: false,
        isOnForBreachlinkOnly: false,
        isOnForBetaUser: false,
        status: "released",
        tiers: ["Free", "Grow", "Scale"],
        permissions: ["admin", "superadmin"],
      },
      {
        FeatureName: "Settings",
        isOn: true,
        isOnInDev: true,
        isOnInBeta: true,
        isOnInProd: true,
        isOnForBreachlinkOnly: false,
        isOnForBetaUser: false,
        status: "released",
        tiers: ["Free", "Grow", "Scale"],
        permissions: ["admin", "superadmin"],
      },
      {
        FeatureName: "Send Assessment",
        isOn: true,
        isOnInDev: true,
        isOnInBeta: true,
        isOnInProd: true,
        isOnForBreachlinkOnly: true,
        isOnForBetaUser: false,
        status: "beta",
        tiers: ["Free", "Grow", "Scale"],
        permissions: ["admin", "superadmin"],
      },
      {
        FeatureName: "Marketplace",
        isOn: false,
        isOnInDev: true,
        isOnInBeta: true,
        isOnInProd: true,
        isOnForBreachlinkOnly: false,
        isOnForBetaUser: false,
        status: "beta",
        tiers: ["Free", "Grow", "Scale"],
        permissions: ["admin", "superadmin"],
      },
      {
        FeatureName: "Employees",
        isOn: true,
        isOnInDev: true,
        isOnInBeta: true,
        isOnInProd: true,
        isOnForBreachlinkOnly: true,
        isOnForBetaUser: false,
        status: "beta",
        tiers: ["Grow", "Scale"],
        permissions: ["admin", "superadmin"],
      },
      {
        FeatureName: "Knowledge Center",
        isOn: true,
        isOnInDev: true,
        isOnInBeta: true,
        isOnInProd: true,
        isOnForBreachlinkOnly: true,
        isOnForBetaUser: false,
        status: "beta",
        tiers: ["Free", "Grow", "Scale"],
        permissions: ["admin", "superadmin"],
      },
    ];

    return features;
  },
  isOn(featureName, tier) {
    let features = this.features_old();
    let feature = features.find((f) => f.FeatureName === featureName);
    return feature.isOn;
  },

  isOnInBeta(featureName, tier) {
    let features = this.features_old();
    let feature = features.find((f) => f.FeatureName === featureName);
    return feature.isOnInBeta;
  },

  isOnInProd(featureName, tier) {
    let features = this.features_old();
    let feature = features.find((f) => f.FeatureName === featureName);
    return feature.isOnInProd;
  },

  isOnForBreachlinkOnly(featureName, tier) {
    let features = this.features_old();
    let feature = features.find((f) => f.FeatureName === featureName);
    return feature.isOnForBreachlinkOnly;
  },

  isOnForBetaUser(featureName, tier) {
    let features = this.features_old();
    let feature = features.find((f) => f.FeatureName === featureName);
    return feature.isOnForBetaUser;
  },

  isOnForTier(featureName, tier) {
    let features = this.features_old();
    let feature = features.find((f) => f.FeatureName === featureName);
    return feature.tiers.includes(tier);
  },

  isOnForTierAndStatus(featureName, tier, status) {
    let features = this.features_old();
    let feature = features.find((f) => f.FeatureName === featureName);
    let isOn = false;
    if (status === "beta") {
      isOn = feature.isOnInBeta;
    } else if (status === "prod") {
      isOn = feature.isOnInProd;
    }
    return isOn && feature.tiers.includes(tier);
  },

  isOnForTierAndStatusAndUser(featureName, tier, status, user) {
    let features = this.features_old();
    let feature = features.find((f) => f.FeatureName === featureName);
    let isOn = false;
    if (status === "beta") {
      isOn = feature.isOnInBeta;
    } else if (status === "prod") {
      isOn = feature.isOnInProd;
    }
    return (
      isOn &&
      feature.tiers.includes(tier) &&
      (feature.isOnForBreachlinkOnly ? user.isBreachlink : true) &&
      (feature.isOnForBetaUser ? user.isBetaUser : true)
    );
  },

  forceIsOn() {
    return false;
  },

  async isEntitled(featureName, flags) {
    console.log("Entering _features.isEntitled with", featureName, flags);
    if (this.forceIsOn() === true) {
      console.log("_features.isEntitled forceIsOn is true");
      return true;
    } else {
      console.log("_features.isEntitled forceIsOn is false");
    }

    console.log("_features.isEntitled flags", flags);

    // [X] first check if the feature is on at all
    // [X] if it is not on, then return false
    // [X] if it is on, then check to see what environment we are running in
    // [X] if we are in beta, then check to see if the feature is on in beta
    // [X] if we are in prod, then check to see if the feature is on in prod
    // [X] if it is on for the environment, and the environment is prod, check to see if it is on for Breachlink only
    // [X] if it is on for Breachlink only and the user is not Breachlink, return false
    // [X] if it is not on for Breachlink only, check to see if it is on for beta only
    // [X] if it is on for beta only and the user is not beta, return false
    // [X] if it is on for beta only and the user is beta, check to see if the user is entitled for the tier
    // [X] if the user is entitled, then return true, otherwise return false

    let isCached = false;
    let features = null;
    let featureFlags = null;
    if (flags && flags !== undefined && flags?.length > 0) {
      console.log("Flags passed in", flags);
      featureFlags = flags;
    } else {
      isCached = await utils.checkCache("flags");

      if (await utils.checkCache("flags")) {
        console.log("flags are cached");
        isCached = true;
      }

      //to do
      // check flags at login and cache
      // check and re-cache flags every 1 hour
      // if flags change, push change to all users and cache
      // clear cache on logout

      if (isCached) {
        console.log("_features getting flags from cache");
        features = await utils.getCacheData("flags", "flags");

        if (features) {
          console.log("featureflags from cache");
          featureFlags = features.data;
        }
      } else {
        console.log("_features getting featureflags flags from server");
        // debugger;
        // features = await this.featureFlags();
        // featureFlags = features.data;
        // utils.cacheData("flags", features);
      }
    }

    let feature = featureFlags?.find((f) => f.FeatureName === featureName);

    if (feature) {
      console.log(featureName, "isEntitled feature found", feature);
      if (!feature.isOn || feature.isOn === false) {
        console.log(
          featureName,
          "isEntitled Feature.isOn is false, return false"
        );
        return false;
      }
      console.log("isEntitled feature is on");
      let isOn = false;

      if (this.isBeta()) {
        isOn = feature.isOnInBeta;
        console.log(
          featureName,
          "isEntitled isBeta is true, setting isOn to",
          isOn
        );
      } else if (this.isProd()) {
        isOn = feature.isOnInProd;
        console.log(
          featureName,
          "isEntitled isProd is true, setting is to ",
          isOn
        );
      } else if (this.isDev()) {
        isOn = feature.isOnInDev;
        console.log(
          featureName,
          "isEntitled isDev is true, setting isOn to",
          isOn
        );

        // return (isOn = feature.isOnInDev || true);
      }
      //Enabled because upgrade was showing.  Needs tested
      if (!isOn || isOn === false) {
        console.log(featureName, "isEntitled isOn is false, returning false");
        return false;
      }
      const user = _auth.getUserProfile();
      if (this.isProd() && feature.isOnForBreachlinkOnly && user.isBreachlink) {
        console.log(
          featureName,
          "isEntitled isProd is true, isOnForBreachlinkOnly is true, user.isBreachlink is true, returning true"
        );
        return true;
      }

      if (
        this.isProd() &&
        feature.isOnForBreachlinkOnly &&
        !user.isBreachlink
      ) {
        console.log(
          featureName,
          "isEntititled isProd is true, isOnForBreachlinkOnly is true, user.isBreachlink is false, returning false"
        );
        return false;
      }

      if (this.isProd() && feature.isOnForBetaUser && !user.isBetaUser) {
        console.log(
          featureName,
          "isEntitled isProd is true, isOnForBetaUser is true, user.isBetaUser is false, returning false"
        );
        return false;
      }

      if (!feature.tiers) {
        console.log(
          featureName,
          "isEntitled feature.tiers is undefined, available in all tiers, returning true"
        );
        return true;
      }
      const tier = await utils.getCacheData("tier", "Stripe");
      console.log("Checking isEntitled", featureName, tier);

      if (tier === null || tier === undefined) {
        tier = "Free";
      }

      if (this.isSubscribed(feature, tier, user)) {
        console.log(
          featureName,
          "isEntitled isSubscribed  returned true, returning true"
        );
        return true;
      } else {
        console.log(
          featureName,
          "isEntitled isSubscribed returned false, returning false"
        );
        return false;
      }
    } else {
      console.log(featureName, "isEntitled feature not found, returning true");
      return true;
    }
  },

  isEntitled1(featureName, flags) {
    console.log("Entering isEntitled1 with", featureName, flags);
    if (this.forceIsOn() === true) {
      console.log("forceIsOn is true");
      return true;
    } else {
      console.log("forceIsOn is false");
    }

    let feature = flags.data.find((f) => f.FeatureName === featureName);

    if (feature) {
      console.log(featureName, "isEntitled feature found", feature);
      if (!feature.isOn) {
        console.log(
          featureName,
          "isEntitled Feature.isOn is false, return false"
        );
        return false;
      }
      console.log("isEntitled feature is on");
      let isOn = false;

      if (this.isBeta()) {
        isOn = feature.isOnInBeta;
        console.log(
          featureName,
          "isEntitled isBeta is true, setting isOn to",
          isOn
        );
      } else if (this.isProd()) {
        isOn = feature.isOnInProd;
        console.log(
          featureName,
          "isEntitled isProd is true, setting is to ",
          isOn
        );
      } else if (this.isDev()) {
        isOn = feature.isOnInDev;
        console.log(
          featureName,
          "isEntitled isDev is true, setting isOn to",
          isOn
        );

        // return (isOn = feature.isOnInDev || true);
      }

      if (!isOn || isOn === false) {
        console.log(featureName, "isEntitled isOn is false, returning false");
        return false;
      }
      const user = _auth.getUserProfile();
      if (this.isProd() && feature.isOnForBreachlinkOnly && user.isBreachlink) {
        console.log(
          featureName,
          "isEntitled isProd is true, isOnForBreachlinkOnly is true, user.isBreachlink is true, returning true"
        );
        return true;
      }

      if (
        this.isProd() &&
        feature.isOnForBreachlinkOnly &&
        !user.isBreachlink
      ) {
        console.log(
          featureName,
          "isEntititled isProd is true, isOnForBreachlinkOnly is true, user.isBreachlink is false, returning false"
        );
        return false;
      }

      if (this.isProd() && feature.isOnForBetaUser && !user.isBetaUser) {
        console.log(
          featureName,
          "isEntitled isProd is true, isOnForBetaUser is true, user.isBetaUser is false, returning false"
        );
        return false;
      }

      if (!feature.tiers) {
        console.log(
          featureName,
          "isEntitled feature.tiers is undefined, available in all tiers, returning true"
        );
        return true;
      }
      const tier = utils.getCacheData("tier", "Stripe");
      console.log("Checking isEntitled", featureName, tier);

      if (tier === null || tier === undefined) {
        tier = "Free";
      }

      if (this.isSubscribed(feature, tier, user)) {
        console.log(
          featureName,
          "isEntitled isSubscribed  returned true, returning true"
        );
        return true;
      } else {
        console.log(
          featureName,
          "isEntitled isSubscribed returned false, returning false"
        );
        return false;
      }
    } else {
      console.log(featureName, "isEntitled feature not found, returning true");
      return true;
    }
  },

  isSubscribed(feature, tier, user) {
    let featureName = feature.FeatureName;
    console.log(
      featureName,
      "isEntitled isSubscribed company",
      user?.tenantCompany
    );
    if (
      user?.tenantCompany !== "BreachConnect" &&
      user?.tenantCompany !== "Breachlink"
    ) {
      if (feature.tiers.includes(tier)) {
        console.log(
          featureName,
          "isEntitled isSubscribed user is entitled, returning true"
        );
        return true;
      } else {
        console.log(
          featureName,
          "isEntitled isSubscribed user is not entitled, returning false"
        );
        return false;
      }
    } else {
      console.log(
        featureName,
        "isEntitled isSubscribed tier does not need to be checked, returning true",
        tier,
        user.tenantCompany
      );
      return true;
    }
  },

  env() {
    return process.env.REACT_APP_NODE_ENV;
  },

  isProd() {
    return process.env.REACT_APP_NODE_ENV === "production";
  },

  isBeta() {
    return process.env.REACT_APP_NODE_ENV === "beta";
  },

  isDev() {
    let env = process.env.REACT_APP_NODE_ENV;
    return (
      env === "development" ||
      env === "test" ||
      env === "local" ||
      env === "dev"
    );
  },
  getStatus(featureName) {
    let features = this.features_old();
    let feature = features.find((f) => f.FeatureName === featureName);
    if (feature) {
      return feature.status || "active";
    }
  },

  //old code - do not use below this line
  //--------------------------------------- old code ---------------------------------------//
  featureList() {
    let featureList = [
      {
        ProductID: 123,
        ProductName: "Free",
        Features: ["Settings", "Feature1", "Feature2", "Feature3"],
      },
      {
        ProductID: 456,
        ProductName: "Grow",
        Features: ["Feature1", "Feature2", "Feature3", "Feature4", "Feature5"],
      },
      {
        ProductID: 789,
        ProductName: "Scale",
        Features: [
          "Feature1",
          "Feature2",
          "Feature3",
          "Feature4",
          "Feature5",
          "Feature6",
          "Feature7",
        ],
      },
    ];

    return featureList;
  },

  async featureFlags() {
    let token = _auth.getUserToken("jwtToken");
    if (token) {
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    }
    console.log("Getting flags from server");
    let featureFlags = await features.getFeatureFlags();
    console.log("Got flags from server", featureFlags);
    console.log("Updating cache");
    utils.cacheData("flags", featureFlags);
    console.log("Updated cache");
    return featureFlags;
  },

  async getFeatureFlags(data) {
    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("get-feature-flags", data);
      if (response) {
        console.log("DBFLAGS Got Response", response);
        if (response.data.status === 200) {
          console.log("DBFLAGS success, ", response.data.data.data);
          return response.data.data;
        } else {
          console.info("DBFLAGS Response Returned", response);
          return response.data.data;
        }
      }
    } catch (e) {
      console.log("DBFLAGS ERROR", e);
    }
  },

  async isEnabled(featureName) {
    const featureFlags = await features.getFeatureFlags();

    if (featureFlags) {
      // console.log("Feature Flag", featureFlags);
      const feature = featureFlags.find(
        (feature) => feature.name === featureName
      );
      // console.log("Feature Flag Found", feature.enabled);
      return feature ? feature.isOn : false;
    } else {
      return false;
    }
  },

  isEnabledV1(featureName) {
    const feature = features
      .features()
      .find((feature) => feature.FeatureName === featureName);
    return feature ? feature.isOn : false;
  },

  isAdmin(featureName) {
    const feature = features
      .featureFlags()
      .find((feature) => feature.FeatureName === featureName);
    return feature ? feature.AdminFlag : false;
  },

  isEligible(featureName, productID, tier) {
    let featureList = this.featureList();
    let product = featureList.find(
      (product) => product.ProductID === productID
    );
    if (product) {
      let feature = product.Features.find((feature) => feature === featureName);
      if (feature) {
        return true;
      }
    }
    return false;
  },
  getEntitledFeatures(tier) {
    //filter out features that are not entitled
    let tmp = [];
    let features = this.features_old();
    let entitledFeatures = features.filter((feature) => {
      if (feature.tiers.includes(tier)) {
        tmp.push(feature.FeatureName);
        return true;
      } else {
        return false;
      }
    });
    return tmp;
  },

  //socket.io listener
  socketListener() {
    //connect to local

    const socket = sockets.connect();

    socket.on("connect", function () {
      socket.emit("greet", { message: "Hello Mr.Server!" });
    });

    socket.on("respond", function (data) {
      console.log("I'm very well, thanks");
      socket.emit("greet", { message: "I'm well and you?" });
    });
    socket.on("update", function () {
      console.log("You need to update feature flags");
      features.featureFlags();
      console.log("Feature Flags Updated");
    });

    socket.on("error", (err) => console.log("error", err));
    // socket.on("connect", () => console.log(socket.id)); // ojIckSD2jqNzOqIrAGzL);
  },

  // end old code
};

const OrderSummary = ({ products, selectedProductId, selectedPriceId }) => {
  // Find the selected product and price
  const selectedProduct = products.find(
    (product) => product.id === selectedProductId
  );
  const selectedPrice = selectedProduct
    ? selectedProduct.prices.find((price) => price.id === selectedPriceId)
    : null;

  // If no product or price was found, return null
  if (!selectedProduct || !selectedPrice) return null;

  return (
    <div className="flex flex-col space-y-4 mr-40 ml-20">
      <div>Product: {selectedProduct.name}</div>
      <div>Price: {selectedPrice.cost}</div>
    </div>
  );
};

// Usage:
{
  /* <OrderSummary
  products={products}
  selectedProductId={selectedProductId}
  selectedPriceId={selectedPriceId}
/>; */
}

// Path: partials/settings/OrderSummary.js
export default OrderSummary;

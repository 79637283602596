export const TOOLTIP_TEXT = {
  "New!": "New Service!",
  "Free Preview":
    "Free Previews are provided for free for a limited time so you can explore our ever-growing portfolio of features. Free Previews are not available for all features.",
  "Coming Soon!": "Coming Soon",
  "Try it!": "Try it!",
  Beta: "Beta",
};
export const SOLICITATION_TAB_ORDER = [
  "mailings",
  "emailings",
  "call_center",
  "credit_monitoring",
  "data_mining",
  "document_review",
  "ransomware",
  "forensics",
  "managed_detection_and_response",
];
export const SERVICE_OPTIONS = [
  {
    id: 1,
    title: "Mailings",
    description: "",
    value: "mailings",
  },
  {
    id: 2,
    title: "Emailings",
    description: "",
    value: "emailings",
  },
  {
    id: 3,
    title: "Call Center",
    description: "",
    value: "call_center",
  },
  // {
  //   id: 4,
  //   title: "Landing Page",
  //   description: "",
  //   value: "landing_page",
  // },
  {
    id: 5,
    title: "Credit Monitoring",
    description: "",
    value: "credit_monitoring",
  },

  {
    id: 6,
    title: "Data Mining",
    description:
      "Services related to mining affected data to determine scope of breach.",
    value: "data_mining",
  },
  {
    id: 7,
    title: "Document Review",
    description:
      "Services related to reviewing data to identify affected individuals.",
    value: "document_review",
  },
  {
    id: 8,
    title: "Ransomware",
    description: "Services related to ransomware.",
    value: "ransomware",
    status: "New Service!",
    // visible: true, // (profile) => profile && profile.tenantType === "admin",
    // enabled: true, // (profile) => profile && profile.tenantType === "admin",
    // enabled: false,
  },
  {
    id: 9,
    title: "Forensics",
    description: "Services related to forensic investigation.",
    value: "forensics",
    status: "New Service!",
    // visible: () => true, // (profile) => profile && profile.tenantType === "admin",
    // enabled: () => true, // (profile) => profile && profile.tenantType === "admin",
    // enabled: false,
    // status: "Coming Soon",
    // enabled:
    //   profile && profile.tenantType && profile.tenantType === "admin"
    //     ? true
    //     : false,
  },
  {
    id: 10,
    title: "Managed Detection and Response",
    description: "Services related to MDR.",
    value: "managed_detection_and_response",
    // enabled: false,
    status: "New Service!",
    // visible: () => true, // (profile) => profile && profile.tenantType === "admin",
    // enabled: () => true, // (profile) => profile && profile.tenantType === "admin",
    // status: "Coming Soon",
    // enabled:
    //   profile && profile.tenantType && profile.tenantType === "admin"
    //     ? true
    //     : false,
  },
];

export const PRICING_TYPES = [
  // {
  //   name: "Flat",
  //   value: "flat",
  //   description:
  //     "Choose this option if the user will pay one price for the entire order.",
  // },
  {
    name: "Standard",
    value: "standard",
    description:
      "Choose this option if the user will pay the same price for every unit.",
  },
  // {
  //   name: "Package",
  //   value: "package",
  //   description:
  //     "Select package pricing if you charge by the package, or group of units. For example, say you charge $25.00 for every 5 units. Purchases are rounded up by default, so a customer buying 8 units would pay $50.00.",
  // },
  {
    name: "Graduated",
    value: "graduated",
    description:
      "Select graduated pricing if you use pricing tiers that may result in a different price for some units in an order. For example, you might charge $10.00 per unit for the first 100 units and then $5.00 per unit for the next 50.",
  },
  {
    name: "Volume",
    value: "volume",
    description:
      "Select volume pricing if you charge the same price for each unit based on the total number of units sold. For example, you might charge $10.00 per unit for 50 units, and $7.00 per unit for 100 units.",
  },
];

export const BILLING_RECURRENCE = [
  {
    name: "One Time",
    title: "One Time",
    // description: "Charge the customer once for the entire order.",
  },
  {
    name: "Recurring",
    title: "Recurring",
    // description:
    // "Charge the customer on a recurring basis, such as every month or year.",
  },
];

export const BILLING_INTERVAL = [
  {
    name: "Day",
    description: "Charge the customer once for the entire order.",
  },
  {
    name: "Week",
    description:
      "Charge the customer on a recurring basis, such as every month or year.",
  },
  {
    name: "Month",
    description:
      "Charge the customer on a recurring basis, such as every month or year.",
  },
  {
    name: "Year",
    description:
      "Charge the customer on a recurring basis, such as every month or year.",
  },
];

export const SOLICITATION_STATUS = ["Active", "Closed", "Archived", "Deleted"];

export const WORKFLOW_STATES = [
  "Accepting Bids",
  "Bidding Closed",
  "Winner Selected",
  "Winner Notified",
  "Closed",
  "Archived",
];
export const RESPONSE_STATUS = [
  "Draft",
  "Submitted",
  "Accepted",
  "Declined",
  "Withdrawn",
  "Archived",
];

export const RESPONSE_STATEES = ["Won", "Lost", "Declined", "Withdrawn"];

export const WORKFLOW_STATUS = [
  //response status
  "Draft",
  "Active",
  "Closed",
  "Archived",
  "Deleted",
];

export const SELECT_WINNER_OPTIONS = [
  {
    id: 1,
    title: "Lowest price",
    description: "The winner had the lowest price.",
    value: "lowest_price",
  },
  {
    id: 2,
    title: "Preferred price",
    description: "The winner offered preferred pricing.",
    value: "preferred_price",
  },
  {
    id: 3,
    title: "Prior relationship",
    description:
      "The winner had the prior relationship with the requestor or insurer.",
    value: "prior_relationship",
  },
  {
    id: 4,
    title: "Carrier preference",
    description: "The carrier had a preference for the winner.",
    value: "carrier_preference",
  },
  {
    id: 5,
    title: "Name recognition",
    description: "The winner was chosen based on name recognition.",
    value: "name_recognition ",
  },
  {
    id: 6,
    title: "Quote quality",
    description: "The winner's quote was easy to read and understand.",
    value: "quote_quality",
  },
  {
    id: 7,
    title: "Other",
    description:
      "The requestor is chose this winner for another reason. Please provide details.",
    value: "Other",
  },
];

export const CLOSE_REASON_OPTIONS = [
  {
    id: 1,
    title: "Prices too high",
    description: "All submitted prices were too high.",
    value: "prices_to_high",
  },
  {
    id: 2,
    title: "No longer needed",
    description: "The services are no longer needed.",
    value: "no_longer_needed",
  },
  {
    id: 3,
    title: "Used outside vendor",
    description:
      "The requestor or carrier chose to use a vendor that was not part of this bidding process.",
    value: "used_outside_vendor",
  },
  {
    id: 4,
    title: "Other",
    description:
      "The requestor is closing this bid for another reason. Please provide details.",
    value: "other",
  },
];

import Input from "../../../components/Input";
import React, { useEffect } from "react";
import { useState } from "react";
import Button from "../../../elements/Button";
import { set } from "date-fns";
import { _company } from "../../../modules/_company";
import { Responses } from "../../../modules/_responses";
import Tip from "../../../components/Tip";

export default function SupplierInfo(props) {
  const [company, setCompany] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [vendorDetails, setVendorDetails] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [fullQuote, setFullQuote] = useState({});

  const [loader, setLoader] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disableCompanyField, setDisableCompanyField] = useState(false);

  //   const { newProject, onFieldChange } = props;
  const {
    updateResponse,
    manageSaved,
    isSaved,
    setSaved,
    responderName,
    responderCompany,
    responderEmail,
    vendor,
  } = props;

  useEffect(() => {
    setFullQuote(props.fullQuote);
    console.log(
      "DEBUG --> props.fullquote changed SupplierInfo",
      props.fullQuote
    );
    let fullQuote = props.fullQuote;
    if (fullQuote && fullQuote.vendor) {
      console.log(
        "DEBUG --> Pre-filling SupplierInfo form from fullQuote.vendorCompanyId",
        fullQuote.vendor
      );
      setCompany(fullQuote.vendor.companyName);
      setContact(fullQuote.vendor.companyContact);
      setEmail(fullQuote.vendor.companyEmail);
      setDomain(fullQuote.vendor.domain);
      setVendorDetails(fullQuote.vendor.companyDetails);
      setPhone(fullQuote.vendor.companyPhone);
      setAddress(fullQuote.vendor.companyAddress);
      setCity(fullQuote.vendor.companyCity);
      setState(fullQuote.vendor.companyState);
      setZip(fullQuote.vendor.companyZip);
      setDisableCompanyField(true);
    } else {
      console.log("DEBUG --> No vendor information found");
      setCompany("");
      setContact("");
      setEmail("");
      setDomain("");
      setVendorDetails("");
      setPhone("");
      setAddress("");
      setCity("");
      setState("");
      setZip("");
      setDisableCompanyField(false);
    }
  }, [props.fullQuote]);

  useEffect(() => {
    console.log("Email address changed", email);
    if (email) {
      let domain = email.split("@")[1];
      console.log("DOMAIN FROM EMAIL", domain);
      setDomain(domain);
    }
  }, [email]);

  useEffect(() => {
    console.log("DEBUG --> SupplierInfo vendorDetails", vendorDetails);
  }, [vendorDetails]);

  const onSave = async () => {
    //get domain from email address
    setLoader(true);
    console.log("onSave email", email);
    // let domain = email.split("@")[1];
    // console.log(domain);
    // setDomain(domain);
    setTimeout(async () => {
      //if any of the fields are empty, then return
      if (!company || !contact || !email || !domain) {
        setLoader(false);
        setError(true);
        setErrorMessage("Please fill out all required fields.");
        return;
      }

      let data = {
        companyName: company,
        companyContact: contact,
        companyEmail: email,
        domain: domain,
        companyDetails: vendorDetails,
        companyPhone: phone,
        companyAddress: address,
        companyCity: city,
        companyState: state,
        companyZip: zip,
      };
      console.log("DEBUG --> onSave Supplier Data", data);

      const newCompany = await Responses.createCompanyFromResponse(data);
      if (newCompany) {
        console.log("newCompany reponse data", newCompany);
        if (
          (fullQuote &&
            fullQuote.status &&
            fullQuote.status.toLowerCase() !== "submitted") ||
          !fullQuote.status
        ) {
          updateResponse(newCompany);
        }
      } else {
        console.log("DEBUG --> No company created");
      }
      setLoader(false);
    }, 1000);

    //check if domain exists in company table
    //if domain exists, get company id and save it in state for use in responses table
    //if domain does not exist, save company information to company table and get company id
    //save company id in state for use in responses table
  };

  //if vendor information exists in vendors table, then populate the fields
  //if vendor information does not exist in vendors table, then start with blank fields

  return (
    <div className="col-span-8">
      <div>
        <h3 className="text-base font-semibold leading-6 text-slate-900">
          Supplier Information
        </h3>
        <p className="mt-1 text-sm text-slate-900">
          Please enter your company's information below for the requestor.{" "}
        </p>
        <Tip>
          {vendor ? (
            `We found the below information based on the information you gave us.  Feel free to make changes, but keep in mind that this information should be general contact information for your company.  We'll keep the details you've already given us and submit them with this bid.`
          ) : (
            <span>
              Note: This should be the general contact information for your
              company. We'll keep the details you've already given us and submit
              them with this bid.
            </span>
          )}
        </Tip>
      </div>

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 lg:grid-cols-6">
        <div className="col-span-8 grid grid-cols-1 md:grid-cols-6 gap-x-4 gap-y-6">
          <div className="col-span-2 sm:col-span-2">
            <label
              htmlFor="company_name"
              className="block text-sm font-medium text-slate-700"
            >
              Company Name
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <Input
                type="text"
                name="company"
                id="company"
                autoComplete="none"
                disabled={
                  (fullQuote && fullQuote.status == "submitted") ||
                  disableCompanyField
                }
                value={company}
                onChange={(e) => {
                  e.preventDefault();
                  setError(false);
                  setErrorMessage("");
                  manageSaved(
                    "Supplier Information",
                    false,
                    "SupplierInfo Input Company"
                  );
                  setCompany(e.target.value);
                }}
                // className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="project_name"
              className="block text-sm font-medium text-slate-700"
            >
              Company Contact
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <Input
                type="text"
                name="vendor_contact"
                id="vendor_contact"
                autoComplete="none"
                value={contact}
                disabled={fullQuote && fullQuote.status == "submitted"}
                onChange={(e) => {
                  setError(false);
                  setErrorMessage("");

                  manageSaved(
                    "Supplier Information",
                    false,
                    "SupplierInfo Input Contact"
                  );
                  setContact(e.target.value);
                }}
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="deadline"
              className="block text-sm font-medium text-slate-700"
            >
              Contact Email
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <Input
                type="text"
                name="vendor_email"
                id="vendor_email"
                autoComplete="none"
                disabled={fullQuote && fullQuote.status == "submitted"}
                value={email}
                onChange={(e) => {
                  setError(false);
                  setErrorMessage("");
                  // console.log("EMAIL CHANGE", e.target.value);
                  manageSaved(
                    "Supplier Information",
                    false,
                    "SupplierInfo Email"
                  );
                  setEmail(e.target.value);
                }}
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
        <div className="sm:col-span-8">
          <label
            htmlFor="about"
            className="block text-sm font-medium text-slate-700"
          >
            Details
          </label>
          <p className="mt-2 text-sm text-slate-500">
            Please provide any additional details about your company that you
            would like to share with the requestor.
          </p>
          <div className="mt-1">
            <textarea
              id="vendor_details"
              name="vendor_details"
              placeholder="(Optional)"
              rows={3}
              disabled={fullQuote && fullQuote.status == "submitted"}
              value={vendorDetails}
              onChange={(e) => {
                console.log("VENDOR DETAILS CHANGE", e.target.value);
                manageSaved(
                  "Supplier Information",
                  false,
                  "SupplierInfo Details"
                );
                setVendorDetails(e.target.value);
              }}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
            />
          </div>
        </div>
        {/* 
        <div id="upload_container" className="sm:col-span-6">
          <label
            htmlFor="cover-photo"
            className="block text-sm font-medium text-slate-700"
          >
            Attachments
          </label>
          <p className=" text-sm text-slate-500">
            You can upload attachments later if needed.
          </p>
          <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-slate-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-slate-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-medium text-brand-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-brand-500 focus-within:ring-offset-2 hover:text-brand-500"
                >
                  <span>Upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-slate-500">PNG, JPG, GIF up to 10MB</p>
            </div>
          </div>
        </div> */}
        <div className="pt-5 col-span-8">
          <div className="flex items-center justify-between ">
            <div className="flex items-center justify-start ">
              {error && (
                <div className="text-red-500 text-xs p-1">{errorMessage}</div>
              )}
            </div>
            <div className="flex justify-end ">
              <Button
                color="secondary"
                loader={loader}
                disabled={
                  (fullQuote && fullQuote.status == "submitted") ||
                  error ||
                  isSaved("Supplier Information")
                }
                // className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-brand-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                handleClick={(e) => {
                  e.preventDefault();
                  onSave();
                  manageSaved(
                    "Supplier Information",
                    true,
                    "SupplierInfo Save Button"
                  );
                  // setStep(step + 1);
                }}
              >
                Save Supplier Information
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

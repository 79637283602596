import React, { FC, useRef } from "react";

// import { IconType, IconPosition } from "./Types";
// import { Typography, TypographyVariant } from "./Typography";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

// interface Props {
//   extends React.InputHTMLAttributes<HTMLInputElement> {
//   placeholder: string;
//   Icon?: IconType;
//   iconPosition?: IconPosition;
//   label?: string;
//   id: string;
//   helpText?: string;
//   errorText?: string;
// }

export default function Input(props, ...rest) {
  const {
    placeholder,
    Icon,
    iconPosition,
    label,
    id,
    indicator,
    type,
    helpText,
    errorText,
    disabled,
    autofill,
  } = props;
  const showLabel = props.showLabel ? props.showLabel : false;
  const clearButton = props.clearButton ? props.clearButton : false;
  const className = props.className ? props.className : "";
  let inputRef = useRef(null);
  let inputClassName = `appearance-none block w-full px-4 py-2 border border-gray-300 
  rounded shadow-sm placeholder-primary-400 
  focus:outline-none focus:border-1 focus:border-brand-500  
  focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-brand-500
  focus:ring-0
  sm:text-sm 
  ${disabled ? "bg-gray-100" : ""}
  ${
    !disabled
      ? "hover:border-1 hover:border-brand-500  sm:text-sm hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-brand-500"
      : ""
  }
   ${className}`;

  if (errorText) {
    inputClassName =
      "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-brand-500  sm:text-sm";
  }

  return (
    <div className="w-full">
      {showLabel && label ? <p>{label}</p> : ""}
      <div className="mt-1 relative rounded- w-full">
        {indicator && (
          <div className="pb-2.5 items-end text-md pointer-events-none absolute inset-y-0 left-0 flex pl-1">
            {indicator}
          </div>
        )}

        <input
          ref={inputRef}
          name={id}
          id={id}
          type={type}
          autoComplete={autofill}
          disabled={disabled}
          className={inputClassName}
          // defaultValue={props.defaultValue}
          value={props.value}
          placeholder={placeholder}
          {...rest}
          onChange={(e) => props.onChange(e)}
        />
        {clearButton && (
          <div className="absolute inset-y-0 right-2 flex items-center">
            <XMarkIcon
              className="cursor-pointer inline h-5 w-5 text-gray-400"
              onClick={() => {
                props.handleClearAllSelections();
              }}
            />
          </div>
        )}

        {helpText && !errorText ? <p className="mt-2">{helpText}</p> : ""}
        {errorText ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {errorText ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errorText}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

Input.defaultProps = {
  label: "Input",
  disabled: false,
  //   iconPosition: IconPosition.LEADING,
};

import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { _auth } from "../modules/_auth";
import { useHistory } from "react-router-dom";
// import { NavLink, useNavigate } from "react-router-dom";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Button from "../elements/Button";
import Input from "../components/Input";
import { _user } from "../modules/_user";
import StackedListButtons from "../components/StackedListButtons";

function Signup() {
  const location = useLocation();
  // const navigate = useNavigate();
  // const { register } = useAuthStore();
  const history = useHistory();
  const [isValidForSignup, setIsValidForSignup] = useState(true);
  const [email, setEmail] = useState(
    location && location.state && location.state.info
      ? location.state.email
      : ""
  );
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState(
    location && location.state && location.state.info
      ? location.state.info.companyContact
      : ""
  );
  const [companyName, setCompanyName] = useState(
    location && location.state && location.state.info
      ? location.state.info.companyName
      : ""
  );
  const [companyId, setCompanyId] = useState(
    location && location.state && location.state.info
      ? location.state.info._id
      : ""
  );
  const [tenantType, setTenantType] = useState(
    location && location.state && location.state.info ? "vendor" : ""
  );
  const [errorMessageTenantType, setErrorMessageTenantType] = useState("");
  const [accessCode, setAccessCode] = useState(
    location && location.state && location.state.info ? "457" : ""
  );
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [accessCodeInvalid, SetAccessCodeInvalid] = useState(false);
  const [accessCodeErrorMessage, setAccessCodeErrorMessage] = useState("");
  const [signUpErrorMessage, setSignUpErrorMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageName, setErrorMessageName] = useState("");
  const [errorMessageCompanyName, setErrorMessageCompanyName] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log("TenantType changed", tenantType);
  }, [tenantType]);

  const onChangeText = (value, variant) => {
    console.info("onChangeText", value, variant);

    if (variant === "email") {
      setEmail(value);
      setErrorMessageEmail("");
    } else if (variant === "username") {
      setFullName(value);
      setErrorMessageName("");
    } else if (variant === "password") {
      setPassword(value);
      setErrorMessagePassword("");
    } else if (variant === "confirmpassword") {
      setConfirmPassword(value);
      setErrorMessagePassword("");
    } else if (variant === "companyName") {
      setCompanyName(value);
      setErrorMessageCompanyName("");
    } else if (variant === "accessCode") {
      setAccessCode(value);
      setAccessCodeErrorMessage(false);
    }
  };

  const sgn = async () => {
    let resp;
    if (!location.state || (location.state && !location.state.info)) {
      console.log("Calling register");
      let data = {
        email: email,
        password: password,
        name: fullName,
        company: companyName,
        role: "Administrator",
        accessCode: accessCode,
        companyId: companyId,
        type: "new",
        tenantType: tenantType,
        createTenant: true,
        createCompany: true,
        from: "register",
        acceptTerms: acceptTerms,
      };
      resp = await _auth.signup2(data);
    } else {
      console.log("Calling create vendor");
      let data = {
        name: fullName,
        email: email,
        role: "Administrator",
        sysRole: null,
        companyRole: "Administrator",
        tenantId: null,
        admin: null,
        tenantName: null,
        company: companyName,
        companyName: companyName,
        companyId: companyId,
        type: "add",
        tenantType: "vendor",
        createTenant: true,
        createCompany: true,
        from: "registerVendor",
        acceptTerms: acceptTerms,
      };
      console.info("COLLAB Single User", data);
      resp = await _auth.signup2(data);
    }

    return resp;
  };

  const onRegister = async (event) => {
    let amIvalid = true;
    setLoading(true);
    event.preventDefault();
    setIsValidForSignup(true);
    const isValid = new Promise((resolve, reject) => {
      console.info("onRegister Clicked");
      let emailRegex = new RegExp(/\S+@\S+\.\S+/);
      // let accessCode = _auth.getAccessCode().toString();
      if (!tenantType) {
        amIvalid = false;
        setIsValidForSignup(false);
        setErrorMessageTenantType("Please select an account type");
      }
      if (!accessCode) {
        amIvalid = false;
        setIsValidForSignup(false);
        SetAccessCodeInvalid(true);
        setAccessCodeErrorMessage("Please enter an Invitation Code");
      } else {
        // accessCode.toString();
        if (accessCode.toString() !== _auth.getAccessCode().toString()) {
          amIvalid = false;
          setIsValidForSignup(false);
          SetAccessCodeInvalid(true);
          setAccessCodeErrorMessage("Please enter a valid Inviation Code");
        }
      }
      if (!fullName) {
        console.log("please enter full name");
        setErrorMessageName("Please enter your full name");
        amIvalid = false;
        setIsValidForSignup(false);
      }
      if (!email || !emailRegex.test(email)) {
        console.log("please enter email");
        setErrorMessageEmail("Please enter a valid email address");
        amIvalid = false;
        setIsValidForSignup(false);
      }
      if (!email || !emailRegex.test(email)) {
        console.log("Please use a company email address");
        setErrorMessageEmail("Please use a company email address");
        amIvalid = false;
        setIsValidForSignup(false);
      }
      if (!companyName) {
        console.log("please enter company name");
        setErrorMessageCompanyName("Please enter your company name");
        amIvalid = false;
        setIsValidForSignup(false);
      }

      console.info("Is it valid for signup?", isValidForSignup);
      resolve("Success!");
    })
      .then(() => {
        const isNaughtyList = new Promise((resolve, reject) => {
          let isNaughty = _auth.checkNaughtyList(email);
          resolve(isNaughty);
        })
          .then((naughty) => {
            console.log("isNaughtyListResp", naughty);
            if (naughty === true) {
              amIvalid = false;
              setErrorMessageEmail("Personal emails are not allowed");
              console.info(
                "Found in naughty list, setting IsValidForSignup to false"
              );
              setIsValidForSignup(false);
            } else {
              console.info(
                "Not found in naughty list, setting IsValidForSignup to true"
              );
              setIsValidForSignup(true);
            }
          })
          .then(() => {
            console.info("AM I VALID?", amIvalid);
            console.info("NOW CHECKING IF WE CAN CREATE THE ACCOUNT");
            if (amIvalid === false) {
              console.info(
                "Cannot start creation, not valid",
                isValidForSignup
              );
              setLoading(false);
            } else {
              console.info("Are we valid for signup?", isValidForSignup);
              setLoading(true);
              console.info(
                "Everything passed, signing up",
                email,
                password,
                fullName,
                companyName,
                accessCode,
                tenantType
              );
              sgn().then((resp) => {
                console.log("My response", resp);
                if (resp && resp.status && resp.status == 200) {
                  console.log("Signup success", resp);
                  setLoading(false);
                  history.push("/signupconfirmation");
                } else {
                  console.log("You failed", resp);
                  setLoading(false);
                  setSignUpErrorMessage(resp.errorMessage);
                }
              });
              // .catch((error) => {
              //   setLoading(false);
              //   console.info("error", error);
              //   setSignUpErrorMessage(error.errorMessage);
              // });
            }
          })
          .catch((error) => {
            console.info("error", error);
          });
      })
      .catch((error) => {
        console.info("error", error);
      });
  };

  //   let resp = new Promise (resolve, reject) => {
  //     const res = sgn();
  //   })
  //   resp
  //     .then((resp) => {
  //   console.info("resp", resp);
  //   if (resp.success) {
  //     console.log("success", resp);
  //     setLoading(false);
  //     history.push("/signupconfirmation");
  //   } else {
  //     setLoading(false);
  //     setSignUpErrorMessage(resp.errorMessage);
  //   }
  // }
  //   )}
  //       });
  //   });
  // };

  return (
    <main className="bg-white">
      <div className="h-screen flex">
        <div className="max-w-md mx-auto my-auto px-4 py-8">
          <h1 className="text-2xl text-slate-800 font-semibold mb-6">
            {location.state && location.state.info
              ? "Create an account to track your bids"
              : "Sign up to get started"}
          </h1>
          {location.state && location.state.info && (
            <div className="flex items-center justify-between">
              Now that you've submitted a bid, register for a free account so
              you can manage your bids and track your progress.
            </div>
          )}
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            {/* <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"> */}
            <form className="space-y-6" method="post" autoComplete="off">
              <Input
                type="text"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                label="Full Name"
                placeholder="Full name"
                id="input"
                value={fullName}
                onChange={(e) => onChangeText(e.target.value, "username")}
                errorText={errorMessageName ? errorMessageName : undefined}
              />
              <Input
                type="text"
                autofill="off"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                label="Work Email address"
                placeholder="Work email address"
                id="input"
                value={email}
                onChange={(e) => onChangeText(e.target.value, "email")}
                errorText={errorMessageEmail ? errorMessageEmail : undefined}
              />

              <Input
                type="text"
                autofill="off"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                label="Company Name"
                placeholder="Your company name"
                id="input"
                value={companyName}
                onChange={(e) => onChangeText(e.target.value, "companyName")}
                errorText={
                  errorMessageCompanyName ? errorMessageCompanyName : undefined
                }
              />
              <Input
                type={
                  location && location.state && location.state.info
                    ? "hidden"
                    : "text"
                }
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                label="Invitation Code"
                placeholder="Invitation code"
                id="input"
                value={accessCode}
                onChange={(e) => onChangeText(e.target.value, "accessCode")}
                errorText={
                  accessCodeErrorMessage ? accessCodeErrorMessage : undefined
                }
              />

              <ul class="space-y-3">
                <li className="min-h-full cursor-pointer">
                  <input
                    type="radio"
                    id="account-type-consumer"
                    name="account-type"
                    value="consumer"
                    disabled={location && location.state && location.state.info}
                    checked={tenantType === "consumer"}
                    onClick={() => {
                      setTenantType("consumer");
                      // console.log("onclick called", "tenantType", "consumer");
                    }}
                    class="hidden peer"
                    required
                  />
                  <label
                    for="account-type-consumer"
                    class={`${
                      location.state && location.state.info && "opacity-50"
                    }
                    rounded inline-flex min-h-full items-center justify-between w-full 
                    py-3 px-4 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer  
                    peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-50`}
                  >
                    <div className="flex items-center">
                      {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                      <div className="grow">
                        <div className="flex flex-wrap items-center justify-between mb-0.5">
                          <span className="flex font-semibold text-slate-800">
                            Consumer
                          </span>
                          <span>
                            {/* <span className="font-medium text-accent-600">
                                  Right Text
                                </span> */}
                          </span>
                        </div>
                        <div className="text-xs text-slate-500">
                          You're here to send bid requests, compare quotes, and
                          connect with vendors.
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </label>
                </li>

                <li className="min-h-full cursor-pointer">
                  <input
                    type="radio"
                    id="account-type-vendor"
                    name="account-type-vendor"
                    value="vendor"
                    checked={tenantType === "vendor"}
                    onClick={(e) => {
                      // e.preventDefault();
                      setTenantType("vendor");
                      // console.log("onclick called", "tenantType", "consumer");
                    }}
                    class="hidden peer"
                    required
                  />
                  <label
                    for="account-type-vendor"
                    class="inline-flex min-h-full items-center justify-between w-full py-3 px-4  text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)]  peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-50 "
                  >
                    <div
                    // className={`w-full h-full text-left py-3 px-4 rounded bg-white
                    //       border border-gray-300
                    //       shadow-sm duration-150 ease-in-out

                    //       focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500

                    //      hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-brand-500 hover:border-gray-300 hover:border-brand-500
                    //     cursor-pointer  peer-checked:border-brand-600 peer-checked:text-brand-600

                    //       `}
                    >
                      <div className="flex items-center">
                        {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                        <div className="grow">
                          <div className="flex flex-wrap items-center justify-between mb-0.5">
                            <span className="flex font-semibold text-slate-800">
                              Vendor
                            </span>
                            <span>
                              {/* <span className="font-medium text-accent-600">
                                  Right Text
                                </span> */}
                            </span>
                          </div>
                          <div className="text-xs text-slate-500">
                            You're here to send quotes, connect with customers,
                            and win more business.
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </li>
              </ul>
              <span className="text-red-500 text-sm">
                {errorMessageTenantType ? errorMessageTenantType : undefined}
              </span>
            </form>
            {/* </div> */}
            <div className="flex items-center justify-between mt-5">
              {signUpErrorMessage !== "" && (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  {signUpErrorMessage}
                </div>
              )}
              <div className="text-sm">
                <input
                  type="checkbox"
                  className="h-4 w-4 mr-2 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                  onChange={(e) => {
                    console.log("e.target.checked", e.target.checked);
                    if (e.target.checked) {
                      setAcceptTerms(true);
                    } else {
                      setAcceptTerms(false);
                    }
                  }}
                />
                I have read and understand the
                <a
                  href="https://www.breachlink.com/terms"
                  //open in new window
                  target="_blank"
                  className="font-extra-small text-brand-600 hover:text-primary-500"
                >
                  {" "}
                  Terms and Conditions
                </a>
              </div>
            </div>
            <div className="mt-5">
              {/* <button
                    // size={ButtonSize.LARGE}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    title="sign in"
                    // variant={ButtonVariant.PRIMARY}
                    onClick={onRegister}
                  /> */}
              <Button
                // enabled={!loader}
                loader={loading}
                type="submit"
                onClick={onRegister}
                handleClick={onRegister}
                fullWidth
                size="md"
                color="secondary"
                disabled={!acceptTerms}
              >
                Sign up
              </Button>
              {/* <Button
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    // variant={ButtonVariant.PRIMARY}
                  >
                    Sign up
                  </Button> */}
            </div>
          </div>
          {/* Footer */}
          <div className="pt-5 mt-6 border-t border-gray-200">
            <div className="text-sm">
              Have an account?{" "}
              <Link
                className="font-medium text-primary-500 hover:text-primary-600"
                to="/signin"
              >
                Sign In
              </Link>
            </div>
            <div className="text-sm">
              <a
                className="font-medium text-primary-500 hover:text-primary-600"
                href="#"
              >
                Need an invitation code?
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Signup;

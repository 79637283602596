import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";

import { _auth } from "./_auth";

export const openAI = {
  async summarize(data) {
    let profile = _auth.getUserProfile();

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;

    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/openai/test", data);
      //   console.log("/impediments/get", response);
      if (response) {
        console.log("/openai/test", response.data.data);
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return "";
    }
  },
};

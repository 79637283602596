import { Fragment, useEffect, useState } from "react";
import { utils } from "../../../modules/_utils";
import Button from "../../../elements/Button";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import QuestionToolTip from "../../../components/QuestionToolTip";
import { pricing_explanations } from "../../../modules/pricing_explanations";
import Badge from "../../../components/Badge";
import WYSIWYGViewer from "../../../components/WYSIWYGViewer";
import PDFViewerModal from "../../../components/PDFViewerModal";
import { PaperClipIcon } from "@heroicons/react/20/solid";

export default function BidCompareTable({
  responses,

  solicitation,
  handleSelectWinnerClick,
  handleNotifyVendorClick,
  handleViewPDF,
}) {
  const [allResponses, setResponses] = useState(responses);
  // const [solicitation, setSolicitation] = useState(props.solicitation);
  const [allLineItems, setAllLineItems] = useState([]);
  const [allPricingItems, setAllPricingItems] = useState([]);
  const [colWidth, setColWidth] = useState("w-1/3");
  const [displayedPricingItems, setDisplayedPricingItems] = useState([]);

  useEffect(() => {
    console.log(
      "DEBUG BID COMPARE TABLE USEEFFECT  props.responses",
      responses
    );
    setResponses(responses);
    if (responses && responses.length > 0) {
      setColWidth(`w-1/4`);
      // setColWidth(`w-${Math.floor(100 / responses.length)}`);
    }
  }, [responses]);

  useEffect(() => {
    let allLineItems = [];
    let allPrices = [];
    let allServicePrices = [];
    // if (responses && responses.length > 0) {
    responses &&
      responses.length > 0 &&
      responses.map((response) => {
        console.log("BidCompareTable response", response);
        response?.services &&
          Object.entries(response.services)?.map(([k, v]) => {
            if (allLineItems && !allLineItems.includes(k)) {
              allLineItems.push(k);
            }
            console.log("BidCompareTable Services", k, v);
            v.pricing &&
              Object.entries(v.pricing) &&
              Object.entries(v.pricing).map(([key, v]) => {
                if (allPrices && !allPrices.includes(key)) {
                  allPrices.push(key);
                }
                //NOT WORKING YET
                if (allServicePrices && !allServicePrices[k]) {
                  //if allServicePrices is empty, add allServicePrices[v] first
                  allServicePrices[k] = [];
                }
                allServicePrices[k].push(key);
              });
          });
      });

    // }
    console.log("AllServicePrices", allServicePrices);
    setAllPricingItems(allServicePrices);
    setAllLineItems(allLineItems);
    console.log("allLineItems", allLineItems);
  }, [allResponses]);

  //   responses

  //   [
  //     {
  //         "_id": "65175b5a5473fd17d2d83024",
  //         "solicitationId": "65175ace5473fd17d2d82ff6",
  //         "vendorId": "65175afd5473fd17d2d83005",
  //         "vendorCompanyId": {
  //             "_id": "64cc58c8e4ea4781b6adff67",
  //             "companyName": "Oran Gmail",
  //             "companyContact": "Oran Sears",
  //             "companyEmail": "oransears@gmail.com"
  //         },
  //         "inviteId": "d1ddc0c3ea462ca810b7d2dc9acdc4da71200627a8d86dbc80ac9e4cc594ecce",
  //         "projectId": "64f78a1403f87a5134b5f1b9",
  //         "answers": [],
  //         "services": {
  //             "data_mining": {
  //                 "pricing": {
  //                     "Base": {
  //                         "type": "Standard",
  //                         "price": "123",
  //                         "unit": "document"
  //                     },
  //                     "PDF Review": {
  //                         "type": "Volume",
  //                         "tiers": [
  //                             {
  //                                 "min": 1,
  //                                 "max": 2,
  //                                 "price": "1",
  //                                 "currency": "USD",
  //                                 "price_type": "unit_pricing"
  //                             },
  //                             {
  //                                 "min": 3,
  //                                 "max": 4,
  //                                 "price": "2",
  //                                 "currency": "USD",
  //                                 "price_type": "unit_pricing"
  //                             },
  //                             {
  //                                 "min": 5,
  //                                 "max": "∞",
  //                                 "price": "3",
  //                                 "currency": "USD",
  //                                 "price_type": "unit_pricing"
  //                             }
  //                         ],
  //                         "unit": "document"
  //                     },
  //                     "Processing": {
  //                         "type": "Volume",
  //                         "unit": "GB",
  //                         "tiers": [
  //                             {
  //                                 "min": 1,
  //                                 "max": 2,
  //                                 "price": "3",
  //                                 "currency": "USD",
  //                                 "price_type": "unit_pricing"
  //                             },
  //                             {
  //                                 "min": 3,
  //                                 "max": 4,
  //                                 "price": "4",
  //                                 "currency": "USD",
  //                                 "price_type": "unit_pricing"
  //                             },
  //                             {
  //                                 "min": 5,
  //                                 "max": "∞",
  //                                 "price": "5",
  //                                 "currency": "USD",
  //                                 "price_type": "unit_pricing"
  //                             }
  //                         ]
  //                     },
  //                     "Project Management": {
  //                         "type": "Standard",
  //                         "price": "23",
  //                         "unit": "hour"
  //                     }
  //                 }
  //             }
  //         },
  //         "attachments": [],
  //         "vendorNotified": false,
  //         "submittedByEmail": "oransears@gmail.com",
  //         "submittedByName": "Oran Sears",
  //         "submittedByCompany": "Oran Gmail",
  //         "accept_nda": false,
  //         "comments": [],
  //         "createdAt": "2023-09-29T23:18:50.003Z",
  //         "updatedAt": "2023-09-29T23:18:53.116Z",
  //         "__v": 0,
  //         "status": "submitted",
  //         "submittedOn": "2023-09-29T23:18:53.115Z"
  //     },
  //     {
  //         "_id": "65175bf35473fd17d2d8308e",
  //         "solicitationId": "65175ace5473fd17d2d82ff6",
  //         "vendorId": "65175afd5473fd17d2d83004",
  //         "vendorCompanyId": {
  //             "_id": "63cc9959371c4c0c9443df30",
  //             "companyName": "Breachlink",
  //             "companyContact": "Oran Sears Vendor 1",
  //             "companyEmail": "support@breachlink.com"
  //         },
  //         "inviteId": "ec7494207c7a4b02dbba28078aefac61592f7ed0ad778a1b642848aeb26a45e2",
  //         "projectId": "64f78a1403f87a5134b5f1b9",
  //         "answers": [],
  //         "services": {
  //             "data_mining": {
  //                 "pricing": {
  //                     "Processing": {
  //                         "type": "Standard",
  //                         "price": "23",
  //                         "unit": "GB"
  //                     },
  //                     "Base": {
  //                         "type": "Standard",
  //                         "price": "12",
  //                         "unit": "document"
  //                     },
  //                     "PDF Review": {
  //                         "type": "Volume",
  //                         "tiers": [
  //                             {
  //                                 "min": 1,
  //                                 "max": 2,
  //                                 "price": "6",
  //                                 "currency": "USD",
  //                                 "price_type": "unit_pricing"
  //                             },
  //                             {
  //                                 "min": 3,
  //                                 "max": 4,
  //                                 "price": "5",
  //                                 "currency": "USD",
  //                                 "price_type": "unit_pricing"
  //                             },
  //                             {
  //                                 "min": 5,
  //                                 "max": "∞",
  //                                 "price": "4",
  //                                 "currency": "USD",
  //                                 "price_type": "unit_pricing"
  //                             }
  //                         ],
  //                         "unit": "document"
  //                     }
  //                 }
  //             }
  //         },
  //         "attachments": [],
  //         "vendorNotified": false,
  //         "submittedByEmail": "oran.sears@breachlink.com",
  //         "submittedByName": "Oran",
  //         "submittedByCompany": "Breachlink",
  //         "accept_nda": false,
  //         "comments": [],
  //         "createdAt": "2023-09-29T23:21:23.911Z",
  //         "updatedAt": "2023-09-29T23:23:21.946Z",
  //         "__v": 0,
  //         "status": "submitted",
  //         "submittedOn": "2023-09-29T23:23:21.946Z"
  //     },
  //     {
  //         "_id": "65175cb85473fd17d2d83161",
  //         "solicitationId": "65175ace5473fd17d2d82ff6",
  //         "vendorId": "65175afd5473fd17d2d83003",
  //         "vendorCompanyId": {
  //             "_id": "65175bb55473fd17d2d83062",
  //             "companyName": "Breachconnect",
  //             "companyContact": "Oran Sears",
  //             "companyEmail": "oran.sears@breachconnect.com"
  //         },
  //         "inviteId": "6688269e4413b5a314b8005dfd26a1787db5d6cacc2f7ec933f973d9e8c83dd5",
  //         "projectId": "64f78a1403f87a5134b5f1b9",
  //         "answers": [],
  //         "services": {
  //             "data_mining": {
  //                 "pricing": {
  //                     "Base": {
  //                         "type": "Standard",
  //                         "price": "100",
  //                         "unit": "GB"
  //                     },
  //                     "PDF Review": {
  //                         "type": "Standard",
  //                         "price": "12",
  //                         "unit": "document"
  //                     }
  //                 }
  //             }
  //         },
  //         "attachments": [],
  //         "vendorNotified": false,
  //         "submittedByEmail": "oran.sears@breachconnect.com",
  //         "submittedByName": "Oran",
  //         "submittedByCompany": "Breachconnect",
  //         "accept_nda": false,
  //         "comments": [],
  //         "createdAt": "2023-09-29T23:24:40.246Z",
  //         "updatedAt": "2023-09-29T23:24:43.817Z",
  //         "__v": 0,
  //         "status": "submitted",
  //         "submittedOn": "2023-09-29T23:24:43.816Z"
  //     }
  // ]
  const generateRows = () => {
    // each row is a service
    // each column is a response
    // each cell is a pricing item (e.g. Base, Processing, Excel Review, PDF Review, Project Management)
    // each cell has a price, a unit, and a tier (if applicable)
    // each cell has a type (Standard or Volume)
    // each cell has a price_type (unit_pricing or flat)
    // each cell has a currency (USD)
    const rows = [];
    return <th scope="col">Service Name</th>;
  };

  return (
    // <table class="table-auto overflow-scroll col-span-full  	 text-sm text-left text-gray-500 ">
    <div
      id="bid-compare-table-scrollable-div"
      className="h-full col-span-full  overflow-x-auto overflow-y-auto scrollbar-default 
      border border-gray-200 "
    >
      <table
        id="response_table"
        className="table-auto h-full overflow-x-scroll w-full text-sm text-left text-gray-500 "
      >
        {/* <div className="w-full overflow-x-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full"> */}
        <thead class="sticky  z-10 top-0 w-full text-xs text-gray-700  bg-gray-50  ">
          <tr>
            <th
              scope="col"
              class={`sticky left-0 h-fit  px-6 py-3  bg-gray-50  border-r border-gray-200`}
              style={{
                width: "300px",
                maxWidth: "300px",
                minWidth: "300px",
              }}
            ></th>
            {allResponses &&
              allResponses.length > 0 &&
              allResponses.map((response, idx) => {
                return (
                  <th
                    scope="col"
                    style={{
                      width: "300px",
                      maxWidth: "300px",
                      minWidth: "300px",

                      // Don't forget this, required for the stickiness
                    }}
                    class={`w-96 px-2  py-3 whitespace-nowrap text-xl px-6 py-3 `}
                  >
                    <div className="space-y-2">
                      {response.vendorCompanyId
                        ? response.vendorCompanyId.companyName
                        : response.submittedByCompany}
                      <p className="mt-4 text-sm leading-6 text-gray-600">
                        Submitted on{" "}
                        {utils.formatDate(response.submittedOn, "short")}
                      </p>
                      <p className=" text-sm leading-6 text-gray-600">
                        by{" "}
                        <span className="font-semibold">
                          {response.submittedByEmail}
                        </span>
                      </p>
                      <div>
                        <div data-html2canvas-ignore>
                          <Button
                            data-html2canvas-ignore="true"
                            // fullWidth
                            aria-describedby={response._id}
                            handleClick={(e) =>
                              handleSelectWinnerClick(e, response)
                            }
                            color={
                              response &&
                              response.requestorDecision === "winner"
                                ? "text"
                                : "secondary"
                            }
                            className="w-48 px-0 mx-0"
                          >
                            {response.requestorDecision &&
                            response.requestorDecision == "winner" ? (
                              <div className=" mx-auto items-center">
                                <CheckIcon className="inline-flex mr-1.5 h-6 w-6 text-green-500" />{" "}
                                <div className="inline-flex">
                                  Winner selected
                                </div>
                              </div>
                            ) : solicitation?.workflow == "Winner Selected" ||
                              solicitation?.workflow == "Winner Notified" ||
                              solicitation?.workflow == "Closed" ? (
                              <div className="px-auto mx-auto items-center cursor-not-allowed">
                                <XMarkIcon className="inline-flex mr-1.5 h-6 w-6 text-red-500" />{" "}
                                <div className="inline-flex">Not selected</div>
                              </div>
                            ) : (
                              "Select winner"
                            )}
                          </Button>
                        </div>
                        <div>
                          {response.requestorDecision &&
                            response.requestorDecision == "winner" && (
                              <Button
                                data-html2canvas-ignore="true"
                                className="w-48 px-0 mx-0"
                                aria-describedby={response._id}
                                handleClick={(e) => {
                                  response.vendorNotified !== true &&
                                    handleNotifyVendorClick(e, response);
                                }}
                                disabled={
                                  solicitation &&
                                  (solicitation.workflow == "Winner Notified" ||
                                    solicitation.workflow == "Closed" ||
                                    solicitation.workflow == "Archived")
                                }
                                color={
                                  response && response.vendorNotified === true
                                    ? "text"
                                    : "green"
                                }
                              >
                                {response.vendorNotified &&
                                response.vendorNotified == true ? (
                                  <div className="items-center">
                                    <CheckIcon className="inline-flex mr-1.5 h-6 w-6 text-green-500" />{" "}
                                    <div className="inline-flex">
                                      Vendor notified
                                    </div>
                                  </div>
                                ) : (
                                  "Notify vendor"
                                )}
                              </Button>
                            )}
                        </div>
                      </div>
                    </div>
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody className="w-full">
          <tr
            colSpan={allResponses?.length + 1}
            className="  bg-brand-100 relative  py-2 uppercase text-sm leading-6 text-gray-900"
          >
            <th
              // scope="col"
              colSpan={allResponses?.length}
              // class={`sticky left-0 h-fit  px-6 py-3`}
              style={{
                width: "300px",
                maxWidth: "300px",
                minWidth: "300px",
              }}
              class={`w-full col-span-full sticky left-0 h-fit px-6 py-3 border-r border-gray-200`}
            >
              Attachments
            </th>
            {/* for 0 to allResponses.length, return <th */}

            <th
              colSpan={1}
              style={{
                width: "300px",
                maxWidth: "300px",
                minWidth: "300px",
              }}
              class={` w-full col-span-full sticky left-0 h-fit px-6 py-3 `}
            ></th>
          </tr>
          <tr className="h-4">
            <th
              scope="col"
              class={` sticky bg-white left-0 px-6 py-3 border-r border-gray-200 align-top`}
              style={{
                width: "300px",
                maxWidth: "300px",
                minWidth: "300px",
              }}
            >
              Attachments
            </th>
            {responses &&
              responses.map((response) => {
                return (
                  <td className="text-gray-600 text-sm py-2 px-2 align-top">
                    <div>
                      <ul
                        role="list"
                        className="divide-y divide-gray-200 rounded-md border border-gray-200"
                      >
                        {response.attachments &&
                          response.attachments.map((attachment) => (
                            <li
                              key={attachment.name}
                              onClick={(e) => {
                                handleViewPDF(e, attachment);
                                // console.log("Downloading attachment", attachment);
                                // handleSetPdfModalOpen(attachment, "response");
                              }}
                              className="flex cursor-pointer items-center justify-between py-3 pl-3 pr-4 text-sm"
                            >
                              <div className="flex w-0 flex-1 items-center">
                                <PaperClipIcon
                                  className="h-5 w-5 flex-shrink-0 text-slate-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-2 w-0 flex-1 truncate">
                                  {attachment.name}
                                </span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <a
                                  // href={attachment.href}
                                  onClick={(e) => {
                                    handleViewPDF(e, attachment);
                                    // handleSetPdfModalOpen(attachment, "response");
                                  }}
                                  className="font-medium text-brand-600 hover:text-brand-500"
                                >
                                  View
                                </a>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </td>
                );
              })}
          </tr>
          {allLineItems &&
            allLineItems.length > 0 &&
            allLineItems.map((lineItem) => {
              let tmp = [];
              return (
                <Fragment>
                  <tr
                    colSpan={allResponses?.length + 1}
                    className="  bg-brand-100 relative  py-2 uppercase text-sm leading-6 text-gray-900"
                  >
                    <th
                      // scope="col"
                      colSpan={1}
                      // class={`sticky left-0 h-fit  px-6 py-3`}
                      style={{
                        width: "300px",
                        maxWidth: "300px",
                        minWidth: "300px",
                      }}
                      class={`w-full col-span-full sticky left-0 h-fit px-6 py-3 border-r border-gray-200`}
                    >
                      {utils.cleanUpKeys(lineItem)}
                    </th>
                    {/* for 0 to allResponses.length, return <th */}
                    {allResponses &&
                      allResponses.length > 0 &&
                      allResponses.map((response) => {
                        return (
                          <th
                            colSpan={1}
                            style={{
                              width: "300px",
                              maxWidth: "300px",
                              minWidth: "300px",
                            }}
                            class={` w-full col-span-full sticky left-0 h-fit px-6 py-3 `}
                          >
                            <div className="flex items-center">
                              {response.services &&
                              response.services[lineItem] &&
                              response.services[lineItem]?.price
                                ? "Estimated:" +
                                  parseFloat(
                                    response.services[lineItem]?.price || 0
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                : "No estimated price provided"}{" "}
                              {response.services &&
                                response.services[lineItem] &&
                                response.services[lineItem]?.price && (
                                  <QuestionToolTip
                                    // position="bottom"
                                    field="Estimated price"
                                    text="This is the estimated price based on the information you provided in your bid request and is subject to change based on the final scope of work."
                                  />
                                )}
                            </div>
                          </th>
                        );
                      })}
                  </tr>

                  {allPricingItems &&
                    allPricingItems[lineItem] &&
                    allPricingItems[lineItem].length > 0 &&
                    allPricingItems[lineItem].map((key, idx) => {
                      if (tmp.includes(key)) {
                        return;
                      } else {
                        tmp.push(key);
                      }
                      return (
                        <>
                          <tr class="py-6 bg-white border-b  dark:border-gray-700">
                            <th
                              scope="col"
                              class={` sticky bg-white left-0 px-6 py-3 border-r border-gray-200`}
                              style={{
                                width: "300px",
                                maxWidth: "300px",
                                minWidth: "300px",
                              }}
                            >
                              {key}
                            </th>

                            {responses &&
                              responses.length > 0 &&
                              responses.map((response) => {
                                if (!response.services) {
                                  return (
                                    <td className="text-gray-600 font-normal align-top "></td>
                                  );
                                }
                                let line_Item = response.services[lineItem];
                                let pricingItem =
                                  response?.services[lineItem]?.key;
                                let recurrence = pricingItem?.recurrence;
                                let unit = pricingItem?.unit;
                                let billing_interval =
                                  pricingItem?.billing_interval;
                                let type = pricingItem?.type;
                                return (
                                  <td className="text-gray-600 font-normal align-top ">
                                    <li
                                      key={key}
                                      className=" font-medium flex gap-x-3 py-2 px-2"
                                    >
                                      <div className="space-y-2">
                                        {response.services[lineItem] &&
                                        response.services[lineItem].pricing &&
                                        response.services[lineItem].pricing[
                                          key
                                        ] &&
                                        response.services[lineItem].pricing &&
                                        response.services[lineItem].pricing[key]
                                          .type === "Standard" ? (
                                          <>
                                            <div className="flex items-center white-space-nowrap space-x-2">
                                              Standard Pricing{" "}
                                              <QuestionToolTip
                                                field="Standard pricing"
                                                text={pricing_explanations.StandardPricing(
                                                  response.services[lineItem]
                                                    .pricing?.[key]
                                                )}
                                              />
                                              {response.services[lineItem]
                                                .pricing?.[key]?.recurrence ==
                                              "Recurring" ? (
                                                <Badge
                                                  color="secondary"
                                                  className=" "
                                                >
                                                  {" "}
                                                  RECURRING{" "}
                                                </Badge>
                                              ) : (
                                                <Badge
                                                  color="secondary"
                                                  className=" "
                                                >
                                                  {" "}
                                                  ONE-TIME FEE{" "}
                                                </Badge>
                                              )}
                                            </div>
                                            <div className="text-gray-600 font-normal ">
                                              <span className="lowercase">
                                                {" $"}
                                                {response.services[lineItem]
                                                  .pricing &&
                                                  response.services[lineItem]
                                                    .pricing[key] &&
                                                  response.services[lineItem]
                                                    .pricing[key].price}{" "}
                                                {response.services[lineItem]
                                                  ?.pricing[key]?.unit !==
                                                  "Fixed Price" && " per "}
                                                {response.services[lineItem]
                                                  .pricing &&
                                                  response.services[lineItem]
                                                    .pricing[key] &&
                                                  response.services[lineItem]
                                                    .pricing[key].unit !==
                                                    undefined &&
                                                  response.services[lineItem]
                                                    .pricing[key].unit}
                                                {response.services[lineItem]
                                                  .pricing?.[key]?.recurrence ==
                                                  "Recurring" &&
                                                  " per " +
                                                    response.services[lineItem]
                                                      .pricing?.[key]
                                                      ?.billing_interval}
                                              </span>
                                            </div>{" "}
                                          </>
                                        ) : (
                                          <div className="text-gray-600 space-y-2">
                                            {" "}
                                            <div className="flex items-center white-space-nowrap space-x-2">
                                              {response.services[lineItem] &&
                                                response.services[lineItem]
                                                  .pricing &&
                                                response.services[lineItem]
                                                  .pricing[key] &&
                                                response.services[lineItem]
                                                  .pricing[key].type}{" "}
                                              {/* <QuestionToolTip
                                                field={
                                                  response?.services[lineItem]
                                                    ?.pricing[key]?.type +
                                                  " pricing"
                                                }
                                                text={pricing_explanations.GetVolumeAndGraduatedPricing(
                                                  response.services[lineItem]
                                                    .pricing?.[key]
                                                )}
                                              />{" "} */}
                                              {response &&
                                              response.services &&
                                              response.services[lineItem] &&
                                              response?.services[lineItem]
                                                .pricing?.[key]?.recurrence ==
                                                "Recurring" ? (
                                                <Badge
                                                  color="secondary"
                                                  className=" "
                                                >
                                                  {" "}
                                                  RECURRING{" "}
                                                </Badge>
                                              ) : (
                                                response &&
                                                response.services &&
                                                response.services[lineItem] &&
                                                response.services[lineItem]
                                                  .pricing?.[key]
                                                  ?.recurrence && (
                                                  <Badge
                                                    color="secondary"
                                                    className=" "
                                                  >
                                                    {" "}
                                                    ONE-TIME FEE{" "}
                                                  </Badge>
                                                )
                                              )}
                                            </div>
                                            <div className="text-gray-600 font-normal space-y-2">
                                              {
                                                // if type is volume, loop through the volume tiers
                                                // and display the volume tier, price, and unit

                                                response.services[lineItem] &&
                                                  response.services[lineItem]
                                                    .pricing &&
                                                  response.services[lineItem]
                                                    .pricing[key] &&
                                                  (response.services[lineItem]
                                                    .pricing[key].type ==
                                                    "Volume" ||
                                                    response.services[lineItem]
                                                      .pricing[key].type ==
                                                      "Graduated") &&
                                                  response.services[lineItem]
                                                    .pricing[key].tiers &&
                                                  response.services[
                                                    lineItem
                                                  ].pricing[key].tiers.map(
                                                    (tier) => {
                                                      return (
                                                        <div
                                                          key={tier._id}
                                                          className="flex mt-2 gap-x-3 lowercase"
                                                        >
                                                          {tier.min} -{" "}
                                                          {tier.max}: {" $"}
                                                          {tier.price &&
                                                            tier.price}
                                                          {tier.price_type ==
                                                          "Recurring"
                                                            ? " per " +
                                                              response
                                                                ?.services[
                                                                lineItem
                                                              ]?.pricing[key]
                                                                ?.unit
                                                            : " fixed"}
                                                          {response.services[
                                                            lineItem
                                                          ].pricing?.[key]
                                                            ?.recurrence ==
                                                            "Recurring" &&
                                                            " per " +
                                                              response.services[
                                                                lineItem
                                                              ].pricing?.[key]
                                                                ?.billing_interval}
                                                        </div>
                                                      );
                                                    }
                                                  )
                                              }
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                  </td>
                                );
                              })}
                          </tr>
                        </>
                      );
                    })}
                  <tr className="h-4">
                    <th
                      scope="col"
                      class={` sticky bg-white left-0 px-6 py-3 border-r border-gray-200 align-top`}
                      style={{
                        width: "300px",
                        maxWidth: "300px",
                        minWidth: "300px",
                      }}
                    >
                      Additional Comments
                    </th>
                    {responses &&
                      responses.length > 0 &&
                      responses.map((response) => {
                        if (!response.services) {
                          return (
                            <td className="text-gray-600 font-normal align-top"></td>
                          );
                        }
                        return (
                          <td className="text-gray-600 text-sm py-2 px-2 align-top">
                            {response.services[lineItem] &&
                            response.services[lineItem]
                              .additional_comments_object ? (
                              <WYSIWYGViewer
                                editorState={
                                  response.services[lineItem]
                                    .additional_comments_object
                                }
                              />
                            ) : (
                              response.services[lineItem] &&
                              response.services[lineItem].additional_comments
                            )}
                          </td>
                        );
                      })}
                  </tr>
                </Fragment>
              );
            })}

          {/* {response.services &&
          Object.entries(response.services).map(([k, v]) => {
            return (
              <tr class="bg-white border-b  dark:border-gray-700">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  {idx} Service Name
                </th>
                <td class="px-6 py-4">{tier.min}</td>
                <td class="px-6 py-4"></td>
                <td class="px-6 py-4"></td>
                <td class="px-6 py-4 relative">
                  <span className="bottom-5 absolute -right-4"></span>
                </td>
              </tr>
            );
          })} */}
        </tbody>
        {/* </div> */}
      </table>
    </div>
  );
}

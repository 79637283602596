import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const _company = {
  async getCompanyByDomain(domain) {
    let profile = _auth.profile();

    let data;
    data = {
      domain: domain,
    };
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    try {
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    } catch (e) {
      console.log(e);
    }

    let response = await postRequest("companies/get-company-by-domain", data);

    if (response) {
      console.log("companies/get-company-by-domain", response.data.data);
      return response.data.data;
    } else {
      return [];
    }
  },
  async getCompanyById(id) {
    let profile = _auth.profile();

    let data;
    data = {
      companyId: id,
    };
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    try {
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    } catch (e) {
      console.log(e);
    }

    let response = await postRequest("companies/get-company-by-id", data);

    if (response) {
      console.log("companies/get-company-by-id", response.data);
      return response.data.data;
    } else {
      return [];
    }
  },
  async createMarketplace(updates) {
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let data = {
      companyId: profile.tenantCompanyId,
      updates,
    };
    console.log("marketplace/create-marketplace", data);

    let response = await postRequest("marketplace/create-marketplace", data);

    if (response) {
      console.log("marketplace/create-marketplace", response.data);
      return response.data;
    } else {
      return [];
    }
  },
};

import React from "react";
import { useTable } from "react-table";

// const data = [
//   // your data here
// ];

// const columns = Object.keys(data[0]).map((key) => ({
//   Header: key,
//   accessor: key,
// }));

function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div
      className="scrollbar-default"
      style={{ overflow: "auto", height: "500px" }}
    >
      <table {...getTableProps()} style={{ width: "100%", textAlign: "left" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="text-sm">
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="p-4 border-r border-l border-gray-200"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;

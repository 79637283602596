import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Projects } from "../../modules/_projects";

import RightActionArea from "../../components/ActionAreaRight";
import Button from "../../elements/Button";
import ModalBasic from "../../components/ModalBasic";
import NewProject from "./NewProject";
import ProjectDetails from "./ProjectDetails";
import { utils } from "../../modules/_utils";

import { TrashIcon } from "@heroicons/react/20/solid";
import DialogBox from "../../components/Dialog";
import ListTable from "./components/ListTable";
import PageHeader from "../../components/PageHeader";
import Tabs from "../component/Tabs";
import NewSolicitation from "./NewSolicitation";
import { Solicitations } from "../../modules/_solicitations";
import Board from "../MyPlan/parts/board";
import { _auth } from "../../modules/_auth";
import { Switch } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Input from "../../components/Input";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SolicitationsPage(props) {
  const getLocalStorage = utils.getLocalStorage;
  const setLocalStorage = utils.setLocalStorage;
  const fromDashboard = props.fromDashboard ? true : false;
  const history = useHistory();
  const [data, setData] = useState([]);
  const [boardData, setBoardData] = useState([]);
  const [boardType, setBoardType] = useState(
    getLocalStorage("boardType") ? getLocalStorage("boardType") : "status"
  ); //status, workflow, type, priority, burden, effort, total
  const [showModal, setShowModal] = useState(false);
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const [newProjectStep, setNewProjectStep] = useState(1);
  const [newSolicitationModalOpen, setNewSolicitationModalOpen] =
    useState(false);
  const [newSolicitationData, setProjectForNewSolicitation] = useState(null);

  const [selectedProject, setSelectedProject] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [deletionEvent, setDeletionEvent] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selectedProjectModalOpen, setSelectedProjectModalOpen] =
    useState(false);
  const [limit, setLimit] = useState(0);
  const [initialData, setInitialData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selectedTab, setSelectedTab] = React.useState("All");
  const [columnOrder, setColumnOrder] = useState([]);
  const [scope, setScope] = useState(
    getLocalStorage("scope") ? getLocalStorage("scope") : "All"
  ); //all, tenant, company
  const [showTestData, setShowTestData] = useState(
    getLocalStorage("showTestData") && getLocalStorage("showTestData") == true
      ? true
      : false
  );
  const [hideLiveData, setHideLiveData] = useState(
    getLocalStorage("hideLiveData") && getLocalStorage("hideLiveData") == true
      ? true
      : false
  );
  const fromModal = props.fromModal ? props.fromModal : false;
  const profile = _auth.getUserProfile();
  const defaultStatusColumns = [
    "Active",
    "Expired",
    "Inactive",
    // "Open",
    "Closed",
    "Archived",
    "Deleted",
  ];
  const defaultWorkflowColumns = [
    // "Open",
    "Accepting Bids",
    "Bidding Closed",
    "Reviewing Bids",
    "Winner Selected",
    "Winner Notified",
    "Closed",
    "Archived",
  ];

  useEffect(() => {
    console.log("showTestData", showTestData);
    console.log(
      "showTestData getLocalStorage",
      getLocalStorage("showTestData")
    );
  }, [showTestData]);

  useEffect(() => {
    (async () => {
      let list;
      let filtered;
      setFilterText("");
      let scp = profile.tenantType === "admin" ? scope.toLowerCase() : "tenant";
      console.log("Get Solicitations", scp);
      if (scp == "tenant") {
        list = await Solicitations.getBoardData();
      } else if (scp == "company") {
        list = await Solicitations.getBoardData("company");
      } else {
        list = await Solicitations.getBoardData("all");
      }
      //delete all fields from each item in list except for:
      //_id, tenantId, companyId, name, responses
      // let smallList = list.map((item) => {
      //   if (item.tenantId._id === "641e7e748836b5489b224231") {
      //     return {
      //       _id: item._id,
      //       tenantId: item.tenantId._id,
      //       projectId: item.projectId._id,
      //       name: item.name,
      //       //responses.map and return response._id
      //       responses: item.responses.map((resp) => {
      //         return resp._id;
      //       }),
      //     };
      //   }
      // });
      // console.log("Small list", smallList);
      if (list) {
        filtered = list;
        console.log("Got Project List", list);
        if (showTestData === false) {
          console.log("showTestData false", data);
          filtered =
            filtered && filtered.filter((item) => item?.isForTesting !== true);
        }

        if (hideLiveData === true) {
          //show only items that has isForTesting === true
          console.log("hideLiveData true", data);
          filtered =
            filtered && filtered.filter((item) => item?.isForTesting === true);
        }

        // if (hideLiveData === true) {
        //   //show items that don't have isForTesting or isForTesting is false
        //   console.log("hideLiveData true", data);
        //   filtered =
        //     filtered &&
        //     filtered.filter(
        //       (item) => item?.isForTesting !== true && item?.isLiveData !== true
        //     );
        // }

        setData(list);
        setFilteredData(filtered);
        // setLimit(list.length > 30 ? 30 : list.length);
      } else {
        console.log("Got Project list but it is empty");
        setData([]);
      }
    })();
  }, [scope]);

  useEffect(() => {
    if (boardType == "status") {
      const columnOrder = defaultStatusColumns;
      setColumnOrder(columnOrder);
    } else if (boardType == "workflow") {
      const columnOrder = defaultWorkflowColumns;
      setColumnOrder(columnOrder);
    }
  }, [boardType]);

  useEffect(() => {
    // let boardData = [];
    let columns = [];
    let cards = [];
    //build columns

    columns = createColumns(filteredData, boardType);

    console.log("Building board data", columns, cards);
    //put cards in columns
    if (filteredData && filteredData.length > 0) {
      cards = filteredData;
    }
    console.log(
      "Calling createBoardData - Building board data",
      columns,
      cards
    );
    //build board data
    let bData = createBoardData(columns, cards);

    console.log("Returned Board Data createBoardData", bData);
    setBoardData([...bData]);
  }, [data, columnOrder, filteredData]);

  useEffect(() => {
    console.log("Selected Tab", selectedTab);
    if (selectedTab == "All") {
      setFilteredData(data);
    } else {
      let filtered = data.filter((item) => item.status == selectedTab);
      setFilteredData(filtered);
    }
  }, [selectedTab]);

  useEffect(() => {
    let newData = data && data.length > 0 ? data.slice(0, limit) : [];
    setInitialData(newData);
  }, [data]);

  useEffect(() => {
    setLocalStorage("showTestData", showTestData);
    setLocalStorage("hideLiveData", hideLiveData);
    if (!data || data.length == 0) {
      console.log("showTestData data is empty", data);
      setFilteredData(data);
      return;
    }
    // console.log("showTestData data", data);
    let filtered = [];
    // let hideLiveData = true;
    // if (showTestData === null) return;
    // if (showTestData === undefined) return;
    // if (showTestData === "") return;
    if (showTestData === false) {
      console.log("showTestData false", data);
      filtered = data && data.filter((item) => item?.isForTesting !== true);
    } else {
      console.log("showTestData true", data);
      filtered = data;
    }
    if (hideLiveData === true) {
      //show only items that has isForTesting === true
      console.log("hideLiveData true", data);
      filtered =
        filtered && filtered.filter((item) => item?.isForTesting === true);
    }
    console.log("showTestData filtered", filtered);
    if (filterText && filterText.length >= 3) {
      filtered = filtered.filter(
        (item) =>
          // Replace 'property' with the property you want to filter by
          item?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
          item?.tenantId?.tenantCompany
            ?.toLowerCase()
            .includes(filterText.toLowerCase()) ||
          item?.tenantId?.tenantTitle
            ?.toLowerCase()
            .includes(filterText.toLowerCase())
      );
    }
    // console.log("Selected Test Data showTestData", showTestData, filtered);
    setFilteredData(filtered);
  }, [showTestData, hideLiveData, filterText]);

  //create columns from solicitation status
  const createBoardData = (columns, cards) => {
    console.log("createBoardData using columns:", columns);
    console.log("createBoardData using cards:", cards);
    // console.log("createBoardData columnOrder:", columnOrder);

    // let boardData = new Array(columnOrder.length);
    let boardData = [];
    // if (columns && columns.length > 0) {
    columns.forEach((column) => {
      console.log("createBoardData forEach start column:", column);

      let columnData = { title: column.title, cards: [] };
      cards.forEach((card) => {
        console.log("createBoardData card:", card);

        if (boardType.toLowerCase() === "workflow") {
          let currentWorkflow = card.workflow_status.find(
            (item) => item.status === "current"
          );
          console.log("createBoardData currentWorkflow:", currentWorkflow);
          if (currentWorkflow && currentWorkflow.name === column.title) {
            console.log(
              "createBoardData Adding card to columnData.cards (workflow)"
            );
            columnData.cards.push(card);
          }
        } else {
          if (card.status === column.title) {
            console.log(
              "createBoardData Adding card to columnData.cards (status)"
            );
            columnData.cards.push(card);
          }
        }
      });

      console.log("createBoardData forEach end columnData:", columnData);

      let index = columnOrder.indexOf(column.title);
      if (index > -1) {
        console.log(
          "createBoardData Adding columnData to boardData at index",
          index
        );
        boardData[index] = columnData;
      } else {
        console.log(
          "createBoardData Column title not found in columnOrder:",
          column.title
        );
      }
    });
    // }

    console.log("createBoardData returning boardData:", boardData);
    return boardData.filter((column) => column); // Remove any undefined items
  };
  const createColumns = (solicitations, type) => {
    let existing = [];
    let columns = [];
    // console.log("Creating default columns from ", columnOrder);
    columnOrder.forEach((col) => {
      columns.push({ title: col });
      existing.push(col);
    });
    // console.log("createBoardData created columns from columnOrder", columns);
    if (solicitations && solicitations.length > 0) {
      // console.log("Adding additional columns not in default columns");
      solicitations.forEach((solicitation) => {
        if (type === "workflow") {
          if (!existing.includes(solicitation.workflow)) {
            columns.push({ title: solicitation.workflow });
            existing.push(solicitation.workflow);
          }
        } else {
          //loop through default status columns and create columns for each
          //if status is not in default status, add it to the end
          if (!existing.includes(solicitation.status)) {
            columns.push({ title: solicitation.status });
            existing.push(solicitation.status);
          }
        }
      });
    }
    // console.log("Returning columns from createColumns", columns);
    return columns;
  };
  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    // console.log("handleOnDragEnd", boardData);
    if (
      source.droppableId !== destination.droppableId ||
      source.index !== destination.index
    ) {
      const startColumn = boardData.find(
        (column) => column?.title === source.droppableId
      );
      const endColumn = boardData.find(
        (column) => column?.title === destination.droppableId
      );
      // console.log("handleOnDragEnd", result, startColumn, "-->", endColumn);
      const [removed] = startColumn.cards.splice(source.index, 1);
      endColumn.cards.splice(destination.index, 0, removed);
      // console.log("handleOnDragEnd updated ->", boardData);

      // Update boardData in your state
      setBoardData([...boardData]);

      // Update the card in the database
      try {
        // updating status is tricky because we need to make other changes depending on new status
        if (boardType.toLowerCase() == "status") {
          await Solicitations.update({
            _id: removed._id,
            status: endColumn.title,
          });
        } else if (boardType.toLowerCase() == "workflow") {
          await Solicitations.update({
            _id: removed._id,
            workflow: endColumn.title,
          });
        }
      } catch (error) {
        // console.error("Failed to update solicitation in database", error);
        // If the update fails, undo the move in the local state
        endColumn.cards.splice(destination.index, 1);
        startColumn.cards.splice(source.index, 0, removed);
        setBoardData([...boardData]);
      }
    }
  };

  // useEffect(() => {
  //   setNewProjectStep(1);
  //   alert("New Project Modal Open", newProjectStep);
  // }, [newProjectModalOpen]);

  const handleOpenProjectClick = (project) => {
    setSelectedProject(project);
    setSelectedProjectModalOpen(true);
  };

  const handleCardClick = (e, index, solicitation) => {
    console.log("handleCardClick", e, index, solicitation);
    // alert("Navigate to project", JSON.stringify(project));

    history.push({
      // pathname: `projects/project-details/solicitation-details`,
      pathname: `projects/project-details/${solicitation.projectId._id}/solicitation-details/${solicitation._id}`,
      state: { project: solicitation.projectId, solicitation: solicitation },
    });
  };

  const handleToggleClick = (value, toggle) => {
    console.log("handleToggleClick", value, toggle);
    if (toggle == "boardType") {
      setBoardType(value);
      setLocalStorage("boardType", value);
    } else if (toggle == "scope") {
      setScope(value);
      setLocalStorage("scope", value);
    }
    console.log(
      "getLocalStorage",
      getLocalStorage("boardType"),
      getLocalStorage("scope")
    );
  };

  const handleOpenNewProjectModalClick = () => {
    setNewProjectStep(1);
    setNewProjectModalOpen(true);
  };

  const handleAfterCreateProject = (project) => {
    console.log("Project Created", project);
    //update data to include new project
    let oldData = data;
    let newData = [...oldData, project];
    setData(newData);
    setNewProjectStep(1);
    // setProjectForNewSolicitation(project);
  };
  const handleCreateNewSolicitation = (data) => {
    console.log("handleCreateNewSolicitation", data);
    setProjectForNewSolicitation(data);
    setNewSolicitationModalOpen(true);
  };

  // const handleAfterDeleteProject = (id) => {
  //   console.log("Project Created", project);
  //   //update data to include new project
  //   let oldData = data;
  //   let newData = [...oldData, project];
  //   setData(newData);
  // };

  const handleCloseProjectClick = () => {
    setSelectedProject({});
    setSelectedProjectModalOpen(false);
  };

  // const showDeleteConfirm = (project) => {
  //   setShowDeleteConfirm(true);
  //   setSelectedProject(project);
  // };

  const handleCancelDeleteProjectClick = () => {
    // e.preventDefault();
    setSuccess(true);
    setDeletionEvent(null);
    setIdToDelete(null);
    setShowDeleteConfirm(false);
  };

  const handleDeleteProjectClick = (e, id) => {
    console.log("handleDeleteProjectClick", id);
    setShowDeleteConfirm(true);
    setDeletionEvent(e);
    setIdToDelete(id);
  };

  const showSolicitationDetails = (project, item) => {
    let projectId = project._id;
    let solicitationId = item._id;
    console.log("Opening solicitation details", item);
    history.push({
      // pathname: "projects/project-details/solicitation-details",
      pathname:
        "/projects/project-details/" +
        projectId +
        "/solicitation-details/" +
        solicitationId,
      state: { project: project, solicitation: item },
    });
  };

  const deleteProject = async (e) => {
    console.log("deleteProject", idToDelete);
    // setDeleteConfirmed(true);
    deletionEvent.preventDefault();
    console.log("Delete Project", idToDelete);
    const deleted = await Projects.delete(idToDelete);

    if (deleted) {
      //remove from data
      let oldData = data;
      let newData = oldData.filter((item) => item._id !== idToDelete);
      setData(newData);
      setSuccess(true);
      setDeletionEvent(null);
      setIdToDelete(null);
      setShowDeleteConfirm(false);
    }
  };

  return (
    // Page Container
    // <div className="flex flex-col items-center justify-between h-full pb-10 ">
    <div
      id="card"
      className="max-h-full h-full w-full overflow-y-hidden flex flex-col justify-between"
    >
      <div id="card_header" className="flex-col md:flex-row ">
        <div
          id="card_header_row_one"
          className="flex flex-col md:flex-row items-center justify-between border-b border-gray-200 "
        >
          {" "}
          <div className="pb-2 flex items-center justify-between space-x-12">
            <h2 className="text-xl font-semibold text-gray-800">
              Bid Requests{" "}
              <span className="text-md text-gray-400 font-medium">
                {data && data.length}
              </span>
            </h2>
          </div>
          <div className="flex flex-col md:flex-row items-center space-x-12">
            <div className="flex items-center pb-2">
              <Input
                placeholder="Search"
                // Icon={
                //   <svg
                //     class="w-4 h-4 text-gray-500 mr-4"
                //     aria-hidden="true"
                //     xmlns="http://www.w3.org/2000/svg"
                //     fill="none"
                //     viewBox="0 0 20 20"
                //   >
                //     <path
                //       stroke="currentColor"
                //       stroke-linecap="round"
                //       stroke-linejoin="round"
                //       stroke-width="2"
                //       d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                //     />
                //   </svg>
                // }
                iconPosition="left"
                label="Search"
                id="search"
                indicator={
                  <svg
                    class="w-4 h-4 text-gray-500 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                }
                type="text"
                value={filterText}
                errorText={null}
                disabled={false}
                autofill={false}
                clearButton={filterText ? true : false}
                handleClearAllSelections={() => {
                  setFilterText("");
                }}
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
              />
            </div>
            {profile &&
              profile.role === "Super Admin" &&
              profile.tenantType === "admin" && (
                <div className="flex items-center space-x-12">
                  <div className="flex items-center space-x-2">
                    <Switch
                      id={"hideLiveData"}
                      checked={hideLiveData}
                      onChange={(e) => {
                        setHideLiveData(e);
                        setLocalStorage("hideLiveData", e);
                      }}
                      className={classNames(
                        hideLiveData && hideLiveData === true
                          ? "bg-brand-600"
                          : "bg-gray-200",
                        "flex items-center w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          hideLiveData && hideLiveData === true
                            ? "translate-x-3.5"
                            : "translate-x-0",
                          "flex h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <label
                      htmlFor="hideLiveData"
                      className="flex items-center cursor-pointer mr-2 "
                    >
                      Hide live data
                    </label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Switch
                      id={"showTestData"}
                      checked={showTestData}
                      onChange={(e) => {
                        setShowTestData(e);
                        setLocalStorage("showTestData", e);
                      }}
                      className={classNames(
                        showTestData && showTestData === true
                          ? "bg-brand-600"
                          : "bg-gray-200",
                        "flex items-center w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          showTestData && showTestData === true
                            ? "translate-x-3.5"
                            : "translate-x-0",
                          "flex h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <label
                      htmlFor="showTestData"
                      className="flex items-center cursor-pointer mr-2 "
                    >
                      Show test data
                    </label>
                  </div>
                </div>
              )}

            <div className="flex items-center">
              <div className="flex items-center font-medium mr-2">
                Board View
              </div>

              <Tabs
                spacing="sm"
                tabs={[
                  {
                    name: "status",
                    href: "#",
                    current: true,
                  },
                  {
                    name: "workflow",
                    href: "#",
                    current: true,
                  },
                ]}
                setSelectedTab={(e) => {
                  handleToggleClick(e, "boardType", "boardType");
                }}
                selectedTab={boardType}
              />
            </div>
            {profile && profile.tenantType === "admin" && (
              <div>
                <Tabs
                  spacing="sm"
                  tabs={[
                    {
                      name: "All",
                      href: "#",
                      current: true,
                    },
                    {
                      name: "Company",
                      href: "#",
                      current: true,
                    },
                    {
                      name: "Tenant",
                      href: "#",
                      current: true,
                    },
                  ]}
                  setSelectedTab={(e) => {
                    handleToggleClick(e, "scope", "scope");
                  }}
                  selectedTab={scope}
                />
              </div>
            )}
          </div>
        </div>
        {/* <div
            id="card_header_row_two"
            className="flex items-center justify-between"
          >
            {" "}
            <Tabs
              tabs={[
                {
                  name: "All",
                  href: "#",
                  current: true,
                },
                {
                  name: "Open",
                  href: "#",
                  current: true,
                },
                {
                  name: "Closed",
                  href: "#",
                  current: true,
                },
              ]}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          </div> */}
      </div>
      {/* <div
          id="card_content"
          className="h-full flex flex-col overflow-y-hidden overflow-x-auto shrink-1"
        > */}{" "}
      <Board
        columns={createColumns(data, boardType)}
        cards={data && data.length > 0 ? data : []}
        type={boardType}
        boardData={boardData}
        handleOnDragEnd={handleOnDragEnd}
        isDragDisabled={true} //{boardType == "status" && !fromDashboard ? false : true}
        handleCardClick={handleCardClick}
      />
      {/* </div> */}
      <div id="card_footer" className="px-6 flex-shrink-0 "></div>
      {/* </div> */}
      {showDeleteConfirm && (
        <DialogBox
          handleConfirmClick={deleteProject}
          handleCancelClick={handleCancelDeleteProjectClick}
          loading={false}
          success={success}
          title="Confirm Project Deletion"
          message={"Are you sure you want to delete this project?"}
          OKbuttonText={"Delete"}
          CancelButton={"Cancel"}
        />
      )}
      <ModalBasic
        id="assessment-modal"
        modalOpen={newProjectModalOpen}
        setModalOpen={setNewProjectModalOpen}
        title="Create a new project"
        showClose={false}
        showFooter={false}
        closeButtonText="Close"
        // size="lg"
        scrollable={true}
      >
        <div className="flex flex-col h-full items-center px-10 pt-3 pb-0">
          <NewProject
            setModalOpen={setNewProjectModalOpen}
            modalOpen={newProjectModalOpen}
            updateData={handleAfterCreateProject}
            step={newProjectStep}
            fromModal={"projects"}
            handleCreateNewSolicitation={handleCreateNewSolicitation}
          />
        </div>
      </ModalBasic>
      <ModalBasic
        id="new-solicitation-model"
        modalOpen={newSolicitationModalOpen}
        setModalOpen={setNewSolicitationModalOpen}
        title="Create New Bid Request"
        showClose={false}
        showFooter={false}
        closeButtonText="Close"
        // size="lg"
        scrollable={true}
      >
        <div className="flex flex-col h-full items-center px-10 pt-3 pb-0">
          <NewSolicitation
            projectId={newSolicitationData?._id}
            project={newSolicitationData}
            setModalOpen={setNewSolicitationModalOpen}
            modalOpen={newSolicitationModalOpen}
            handleCloseButtonClick={(project, solicitation) =>
              showSolicitationDetails(project, solicitation)
            }
            updateData={null}
            stepOptions={4}
            step={0}
          />
        </div>
      </ModalBasic>
      <ModalBasic
        id="project-details-modal"
        modalOpen={selectedProjectModalOpen}
        setModalOpen={setSelectedProjectModalOpen}
        title="Create a new project"
        showClose={true}
        showFooter={false}
        closeButtonText="Close"
        size="lg"
        scrollable={true}
      >
        {/* <div className="flex items-center p-10"> */}
        <ProjectDetails project={selectedProject} />
        {/* </div> */}
      </ModalBasic>
    </div>
  );
}

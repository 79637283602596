import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";
import { _auth } from "./_auth";

export const _onboarding_options = {
  getOnboardingSteps() {
    const steps = [
      {
        id: "01",
        name: "Overview",
      },
      {
        id: "02",
        name: "Team Name",
      },
      {
        id: "03",
        name: "Role",
      },
      // {
      //   id: "03",
      //   name: " Department",
      // },
      // { id: "04", name: "Industry" },
      // { id: "05", name: "Size" },
      // { id: "06", name: "Location" },
      // { id: "04", name: "Recommendations" },
      // { id: "04", name: "Resources" },
    ];
    return steps;
  },

  getCompanyIndustry() {
    const steps = [
      {
        id: 1,
        title: "Food & Beverage",
        description: "Last message sent an hour ago",
        users: "621 users",
      },
      {
        id: 2,
        title: "Hospitality",
        description: "Last message sent 2 weeks ago",
        users: "1200 users",
      },
      {
        id: 3,
        title: "Retail",
        description: "Last message sent 4 days ago",
        users: "2740 users",
      },
      {
        id: 4,
        title: "Education",
        description: "Last message sent an hour ago",
        users: "621 users",
      },
      {
        id: 5,
        title: "Healthcare",
        description: "Last message sent 2 weeks ago",
        users: "1200 users",
      },
      {
        id: 6,
        title: "Financial",
        description: "Last message sent 4 days ago",
        users: "2740 users",
      },
      {
        id: 7,
        title: "Consumer Services",
        description: "Last message sent an hour ago",
        users: "621 users",
      },
      {
        id: 8,
        title: "Manufacturing",
        description: "Last message sent 2 weeks ago",
        users: "1200 users",
      },
      {
        id: 9,
        title: "Government",
        description: "Last message sent 4 days ago",
        users: "2740 users",
      },
      {
        id: 10,
        title: "Transportation",
        description: "Last message sent an hour ago",
        users: "621 users",
      },
      {
        id: 11,
        title: "Media",
        description: "Last message sent 2 weeks ago",
        users: "1200 users",
      },
      {
        id: 12,
        title: "Communications",
        description: "Last message sent 4 days ago",
        users: "2740 users",
      },
    ];
    return steps;
  },
  getEmployeeRoles() {
    const role = [
      {
        id: "01",
        name: "Executive",
      },
      {
        id: "02",
        name: "Manager",
      },
      {
        id: "03",
        name: "Architect",
      },
      { id: "04", name: "Engineer" },
      { id: "05", name: "Product Owner" },
    ];
    return role;
  },
  getEmployeeDepartments() {
    const division = [
      {
        id: "01",
        name: "Security",
      },
      {
        id: "02",
        name: "Engineering",
      },
      {
        id: "03",
        name: "Human Resources",
      },
      { id: "04", name: "Finance" },
      {
        id: "05",
        name: "Legal",
      },
      {
        id: "06",
        name: "Information Technology",
      },
      {
        id: "07",
        name: "Other",
      },
    ];
    return division;
  },

  getCompanySize() {
    const size = [
      {
        id: "01",
        name: "1-50",
      },
      {
        id: "02",
        name: "51-200",
      },
      {
        id: "03",
        name: "201-1000",
      },
      { id: "04", name: "1001-5000" },
      {
        id: "05",
        name: "5001-10,000",
      },
      {
        id: "06",
        name: "Over 10,000",
      },
      {
        id: "07",
        name: "I don't know",
      },
    ];
    return size;
  },

  getCompanyLocation() {
    const location = [
      {
        id: "01",
        name: "United States",
      },
      {
        id: "02",
        name: "Canada",
      },
      {
        id: "03",
        name: "UK",
      },
      { id: "04", name: "EU" },
      {
        id: "05",
        name: "AU/NZ",
      },

      {
        id: "10",
        name: "I don't know",
      },
    ];
    return location;
  },
  getAllSettingsOptions() {
    const steps = [
      {
        id: "01",
        name: "Invite Collaborators",
        description: "Invite other administrators to work with you.",
      },
      {
        id: "02",
        name: "Set up MFA ",
        description: "Enable Multi-factor Authentication",
      },
      {
        id: "03",
        name: "Set up MFA",
        description: "",
      },
    ];
    return steps;
  },
  loader: false,
  async setDontShowAgain(c) {
    this.loader = true;
    console.info("set dont show again", c);
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await getRequest("dont-show-again");
    if (response) {
      this.loader = false;
      console.log(response);
      return true;
    } else {
      return false;
    }
  },
  async setUserFinishedOnboarding(data, history) {
    // write the request logic here
    ////debugger
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("on-board", data);
    ////debugger
    this.loader = true;
    console.log(response, "responsesss");
    if (response) {
      this.loader = false;
      history.push({
        pathname: "/home",
      });
      return true;
    } else {
      return false;
    }
  },
};

import { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Reports } from "../../modules/_reports";
import ComboboxSelector from "../../components/ComboBoxSelector";
import Table from "./Table";
import Button from "../../elements/Button";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function WinReport(props) {
  const [data, setData] = useState({});
  const [columns, setColumns] = useState([]);
  const [month, setMonth] = useState(
    monthNames[(new Date().getMonth() - 1 + 12) % 12]
  );

  const date = new Date();
  const currentMonth = date.getMonth();

  const lastFourMonths = Array.from({ length: 4 }, (_, i) => {
    const month = monthNames[(currentMonth - i + 12) % 12];
    return { name: month, value: month };
  });
  useEffect(() => {
    (async () => {
      let winReport = await Reports.getWinReport({ month: month });
      if (winReport) {
        console.log("Got winReport");
        setData(winReport.data);
        setColumns(winReport.columns);
      }
    })();
  }, [month]);

  const downloadWinReport = async (data) => {
    console.log("Download winReport by month API and pass data", data.month);
    const response = await Reports.downloadWinReport(data);

    // Create a Blob from the response data
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    // Create a link element
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    // Set the download attribute of the link to the filename from the Content-Disposition header
    // const contentDisposition = response.headers["content-disposition"];
    const filename = "report.xlsx"; //contentDisposition.split(";")[1].split("=")[1].trim();
    link.setAttribute("download", filename);

    // Append the link to the body and click it to start the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div
      id="page_container"
      className=" w-full max-w-14xl max-h-[calc(100vh-100px)]"
    >
      <div className="pb-4 px-4">
        <div className="pb-2 md:flex md:items-center md:justify-between md:space-x-5  ">
          <div className="flex items-center">
            <div>
              <PageHeader>Win Report</PageHeader>
              <p>This report shows all the vendor wins</p>
            </div>
          </div>
          <div className="flex items-center gap-x-4">
            <Button
              //   className="w-48"
              fullWidth
              handleClick={() =>
                downloadWinReport({ month: month, tableData: data })
              }
            >
              Download Report
            </Button>{" "}
            <ComboboxSelector
              options={lastFourMonths}
              label="Select Month"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              fieldType="single"
              handleClearAllSelections={() =>
                setMonth(monthNames[(new Date().getMonth() - 1 + 12) % 12])
              }
            />
            {/* <select>
              {lastFourMonths.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select> */}
          </div>
          {/* <Filters
          filterOptions={filterOptions}
          filters={filters}
          setFilters={setFilters}
          handleChangeFilter={handleChangeFilter}
        /> */}
        </div>
        {/* <div className="flex items-center  w-full">
      {tabsContainer &&
        tabsContainer.length > 0 &&
        tabsContainer.map((tabs, index) => {
          return (
            <div className="flex text-sm items-center" key={index}>
              <Tabs
                key={index}
                tabs={tabs.tabs}
                //sort tabs.tabs alphabetically by name
                // .sort((a, b) => (a.name > b.name ? 1 : -1))}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
              />
            </div>
          );
        })}
    </div> */}
      </div>
      {/* <div>{JSON.stringify(data)}</div> */}
      <div className="bg-white rounded-sm shadow-md border border-gray-200">
        <Table
          columns={
            columns &&
            columns.map((column) => ({
              Header: column,
              accessor: column,
            }))
          }
          data={data}
        />
      </div>
    </div>
  );
}

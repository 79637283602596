import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const Reports = {
  async getWinReport(data) {
    console.log("Get winReport by month API and pass data", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", data);
    try {
      let response = await postRequest("reports/vendor-win-report", data);
      if (response) {
        console.log(
          "Get solicitationId by inviteid got response",
          response.data.data
        );
        return response.data.data;
      } else {
        return response;
      }
    } catch (error) {
      console.log("reports/vendor-win-report", error);
      return error;
    }
  },
  async downloadWinReport(data) {
    console.log("Download winReport by month API and pass data", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    try {
      let response = await postRequest(
        "reports/download-win-report",
        data,
        "blob"
      );
      if (response) {
        console.log("reports/download-win-report got response", response);
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.log("reports/download-win-report", error);
      return error;
    }
  },
};

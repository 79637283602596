/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useMemo, useState } from "react";
import { templates } from "../../modules/_templates";
import AssessmentSelect from "../AssessmentSelect";
import { Projects } from "../../modules/_projects";
import YesNoQuestions from "../../components/YesNoQuestions";
import RadioGroupCards from "../../components/RadioGroupCards";
import MultiSelectListBox from "../../components/MultiSelectListBox";
import { Jurisdictions } from "../../modules/_jurisdiction_list";
import { Solicitations } from "../../modules/_solicitations";
import ComboboxSelector from "../../components/ComboBoxSelector";
import { PlusIcon } from "@heroicons/react/20/solid";
import {
  CheckCircleIcon,
  CheckIcon,
  FolderPlusIcon,
  PaperClipIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { current } from "tailwindcss/colors";
import JurisdictionInput from "./components/JurisdictionInput";

import { utils } from "../../modules/_utils";
import Tip from "../../components/Tip";
import MailingInfo from "./components/MailingInfo";
import EmailingInfo from "./components/EmailingInfo";
import CallCenterInfo from "./components/CallCenterInfo";
import Divider from "../../components/Divider";
import { createCompilerHost } from "typescript";
import CreditMonitoringInfo from "./components/CreditMonitoringInfo";
import parseWithOptions from "date-fns/fp/parseWithOptions/index.js";
import Button from "../../elements/Button";
import InviteComponent from "../../components/InviteComponent";
import { usePersistStore, useStore } from "../../store/usestore";
import Uploader from "../../components/Uppy";
import DatePicker from "react-datepicker";
import { templates as _templates } from "../../modules/_templates";
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from "@iconify/react";
import SelectorButtons from "../../components/SelectorButtons";
import { cleanup } from "@testing-library/react";
import RadioGroupLarge from "../../components/RadioGroupLarge";
import Skeleton from "../../components/Skeleton";
import { Paragraph } from "../../components/Typography/Paragraph";
import EaseIn from "../../components/EaseIn";
import { Label } from "../../components/Typography/FieldLabel";
import { audit } from "../../modules/_audit";
import DialogBox from "../../components/Dialog";
import { SERVICE_OPTIONS } from "../../constants/constants";
// import UppyDragDrop from "../../components/UppyDragDrop";

import { _auth } from "../../modules/_auth";

import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import { UppyDropTarget } from "../../components/UppyDropTarget";
import UploadComponent from "../component/UploadComponent";
import { _marketplace } from "../../modules/_marketplace";
import DataMiningInfo from "./components/DataMiningInfo";
import TextEditor from "../../components/TextEditor";
import QuestionToolTip from "../../components/QuestionToolTip";
import { Switch } from "@headlessui/react";
import ForensicsInfo from "./components/ForensicsInfo";
// const deadlineField = document.getElementById("deadline");
// export const deadlineDatePicker = () => {
//   new Datepicker(deadlineField, {
//     // options
//     autohide: true,
//     buttonClass: "btn",
//     format: "mm/dd/yyyy",
//     // startDate: new Date(),
//     // endDate: new Date(),
//     // dateSelected: new Date(),
//     // maxDate: new Date(),
//     // minDate: new Date(),
//     // showAllDates: true,
//     // startDay: 0,
//     // zIndex: 100,
//     // disabled: true,
//     // inline: true,
//     // button: true,
//     // position: "tr",
//     // offset: 12,
//     // view: "days",
//     // autohide: true,
//   });
// };

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NewSolicitation(props) {
  //UI States
  const [loading, setLoading] = useState(false);
  const [copying, setCopying] = useState(false);
  const [success, setSuccess] = useState(false);
  const [saved, setSaved] = useState(false);
  const [sent, setSent] = useState(false);
  const [createDuplicate, setCreateDuplicate] = useState(false);
  const [createFromTemplate, setCreateFromTemplate] = useState(false);
  const [selectedCopy, setSelectedCopy] = useState([]);
  const [currentProject, setCurrentProject] = useState({});
  const [step, setStep] = useState(props.step);
  const [newProject, setNewProject] = useState({});
  const [selectedJurisdictions, setSelectedJurisdictions] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedCallCenterLocation, setSelectedCallCenterLocation] = useState(
    []
  );
  const [selectedClientIndustry, setSelectedClientIndustry] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [callCenterLocationOptions, setCallCenterLocationOptions] = useState(
    []
  );
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [currentService, setCurrentService] = useState(0);
  const [currentServiceName, setCurrentServiceName] = useState(
    selectedServices && selectedServices.length > 0 ? selectedServices[0] : null
  );
  const [totalSteps, setTotalSteps] = useState(props.stepOptions);
  const [solicitationJurisdictions, setSolicitationJurisdictions] = useState();
  const [creationMethod, setCreationMethod] = useState("create-new");
  const [cohorts, setCohorts] = useState([]);
  const { sendAssessment } = useStore();
  //data states
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateSaved, setTemplateSaved] = useState(false);
  const [showConfirmDeleteGroup, setShowConfirmDeleteGroup] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [featuredSuggestions, setFeaturedSuggestions] = useState([]);
  const [randomSuggestions, setRandomSuggestions] = useState([]);
  const [marketplace, setMarketplace] = useState([]);

  const profile = _auth.getUserProfile();

  const [lastStepState, setLastStepState] = useState("save");

  const serviceOptions = SERVICE_OPTIONS;

  useEffect(() => {
    console.log("New Solicitation for Project", props.project);

    setCurrentProject(props.project ? props.project : {});
  }, [props.project]);

  useEffect(() => {
    console.log("New Solicitation Current project changed", currentProject);
    let bidIncrement = currentProject.solicitationIncrement + 1;
    let currentProjectId = currentProject.projectId;
    let isForTesting = currentProject.isForTesting;
    // let name =
    //   "BL-" +
    //   currentProjectId?.toString() +
    //   " " +
    //   currentProject.name +
    //   " BR-" +
    //   bidIncrement.toString();
    let name = currentProject.name + " Bid Request " + bidIncrement.toString();

    let deadline = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);

    console.log("NewSolicitation:useEffect:name", name);
    setNewProject({
      ...newProject,
      name: name,
      deadline: deadline,
      isForTesting: isForTesting,
    });
  }, [currentProject]);

  useEffect(() => {
    console.log("NewSolicitation:useEffect:newProject:changed", newProject);
    if (newProject["rtf_description"]) {
      console.log(
        "NewSolicitation:useEffect:newProject:changed:rtf_description",
        newProject["rtf_description"]
      );
    }
  }, [newProject]);

  useEffect(() => {
    (async () => {
      //make all random stuff backend and use a diff api
      if (saved && saved === true) {
        let services = [];
        if (newProject.services) {
          newProject.services.map((service) => {
            services.push(
              //clean up tab names firest
              utils.cleanUpKeys(service.service)
            );
          });
        }

        console.log("Randomize Suggestions", services);

        const mkt = await _marketplace.getFeaturedMarketplaceVendors(services);
        if (mkt) {
          setFeaturedSuggestions(mkt);
        }
        const fullMarketplace = await _marketplace.getMarketPlaceVendors(
          "published"
        );
        if (fullMarketplace) {
          setMarketplace(fullMarketplace);
        }
      }
    })();
  }, [saved]);

  useEffect(() => {
    (async () => {
      const tmpl = await _templates.getAllTemplates();
      //rename object keys in array
      tmpl.forEach((item) => {
        item["title"] = item["name"];
        item["value"] = item["_id"];
      });
      setTemplates(tmpl);
    })();
  }, [createFromTemplate]); //get recently created templates

  // useEffect(() => {
  //   console.log(
  //     "USE_EFFECT onFieldChange BID REQ changed",
  //     JSON.stringify(newProject)
  //   );
  // }, [newProject]);

  useEffect(() => {
    console.log(
      "handleRadioSelect SETTING solicitationJurisdictions",
      solicitationJurisdictions
    );
  }, [solicitationJurisdictions]);

  useMemo(() => {
    const languages = [];
    const priority = [
      { name: "English" },
      {
        name: "Spanish",
      },
      { name: "French" },
    ];
    //combine the two arrays
    const combined = [...priority, ...languages];

    setLanguageOptions(combined);
  }, []);

  useMemo(() => {
    const options = [];
    const priority = [
      { name: "North America" },
      {
        name: "Europe",
      },
      { name: "United Kingdom" },
      { name: "Australia/New Zealand" },
      { name: "Asia Pacific" },
      { name: "Latin America" },
      { name: "No Preference" },
    ];
    //combine the two arrays
    // const combined = [...priority, ...languages];

    setCallCenterLocationOptions(priority);
  }, []);

  useEffect(() => {
    //update newProject insurer and newProject insurer contact
    if (newProject.insurer) {
      const newState = { ...newProject };
      newState.insurer_contact = "";
      newState.insurer = "";
      setNewProject(newState);
    }
  }, [newProject.isInsured]);

  useEffect(() => {
    console.log(
      "update newProject attachments when fileList changes",
      fileList
    );
    const newState = { ...newProject };
    if (fileList) {
      newState.attachments = fileList;
      setNewProject(newState);
    } else {
      delete newState.attachments;
      setNewProject(newState);
    }
  }, [fileList]);

  useEffect(() => {
    //when currentService changes, update the currentServiceName
    if (selectedServices && selectedServices.length > 0) {
      setCurrentServiceName(selectedServices[currentService]);
    }
    console.log(
      "UseEffect Current Service Name",
      currentService,
      selectedServices[currentService]
    );
  }, [currentService]);

  useEffect(() => {
    console.log("handleSelectedLanguage", selectedLanguages);
  }, [selectedLanguages]);

  useEffect(() => {
    let selected = [];
    console.log(
      "handleRadioSelect UseEffect newProject.services changed",
      newProject,
      serviceOptions
    );
    serviceOptions.map((el) => {
      if (newProject.services) {
        newProject.services.map((service) => {
          if (service.service === el.value && !selected.includes(el.value)) {
            selected.push(el.value);
          }
        });
      }
      console.log(
        " handleRadioSelect UseEffect newProject selected services",
        selected
      );
      console.log(
        "setNewProject setCurrentServiceName to selected[0]",
        selected[0]
      );
      setCurrentServiceName(selected[0]);
      console.log("setNewProject setCurrentService to 0");
      setCurrentService(0);
      console.log("setNewProject setSelectedServices to selected", selected);
      setSelectedServices(selected);
    });
  }, [newProject.services]);

  useEffect(() => {
    console.log(
      "handleRadioSelect currentService, selectedServices, or currentServiceName changed",
      currentServiceName
    );
    console.log(
      "handleRadioSelect currentService, selectedServices, or currentServiceName changed",
      currentService
    );
    console.log(
      "handleRadioSelect currentService, selectedServices, or currentServiceName changed",
      selectedServices
    );
  }, [currentService, selectedServices, currentServiceName]);

  useEffect(() => {
    setStep(props.step);
    // alert("New Project Modal Open", step);
  }, [props.modalOpen]);
  // const handleNextStepClick = () => {
  //   if (step < 2) {
  //     setStep(step + 1);
  //     // setCurrentService(0);
  //     // setCurrentServiceName(selectedServices[0]);
  //   } else {
  //     setStep(step + 1);
  //     setCurrentServiceName(selectedServices[currentService]);
  //     setCurrentService(currentService + 1);
  //   }
  // };
  useEffect(() => {
    console.log("fileList Changed in NewSolicitation", fileList);
  }, [fileList]);

  const updateFileList = (e, file, action, documentType) => {
    console.log("updateFileList", file);
    e && e.preventDefault();

    if (action === "add") {
      let document = {
        name: file.originalname,
        size: file.size,
        type: file.mimetype,
        documentType: documentType,
        path: file.path,
      };
      setFileList([...fileList, document]);
    } else {
      let newFileList =
        fileList &&
        fileList.length > 0 &&
        fileList.filter((el) => el.path !== file.path);

      setFileList(newFileList);
    }
  };

  const saveTemplate = async (e) => {
    e.preventDefault();
    // utils.manageLoading("Save Template", true, loading, setLoading);
    setLoading(true);
    setErr(false);
    setErrMessage("");
    if (!templateName || templateName == "") {
      setErr(true);
      setErrMessage("Please enter a name for the template");
      return;
    }
    if (!templateDescription || templateDescription == "") {
      setErr(true);
      setErrMessage("Please enter a description for the template");
      return;
    }
    let data = {
      fromSolicitationId: newProject._id,
      name: templateName, // solicitation.name,
      description: templateDescription, // "Created by hardcoded description",
      template: newProject,
    };
    let saved = await Solicitations.saveAsTemplate(data);
    if (saved) {
      console.log("Saved as template", saved);
    }
    setTimeout(() => {
      setTemplateSaved(true);
    }, 1000);
    setTimeout(() => {
      setLastStepState(null);
    }, 5000);

    // utils.manageLoading("Save Template", false);
    setLoading(false);
  };

  const handleDeleteCohort = (groupIndex) => {
    let oldCohortsState = [...cohorts];
    oldCohortsState.splice(groupIndex, 1);
    setCohorts(oldCohortsState);
    setShowConfirmDeleteGroup(false);
  };

  const onTemplateCreationFieldChange = (e, field) => {
    setErr(false);
    setErrMessage("");
    console.log("onFieldChange", "{field:", field, ",value:", e, "}");
    if (field == "Description") {
      setTemplateDescription(e.target.value);
    } else if (field == "Name") {
      setTemplateName(e.target.value);
    } else {
      console.log("Field ", field, "not found");
    }
  };
  const handleNextStepClick = () => {
    if (step < 3) {
      console.log(" handleNextStepClick step < 3");
      if (selectedServices[0] === undefined && step + 1 === 3) {
        setStep(step + 2);
        setCurrentService(0);
      } else {
        setStep(step + 1);
        setCurrentService(0);
        //APP-141 BID INCREMENT ISSUE
        // let bidIncrement = currentProject.solicitationIncrement + 1;
        // let currentProjectId = currentProject.projectId;

        // // let name =
        // //   "BL-" +
        // //   currentProjectId?.toString() +
        // //   " " +
        // //   currentProject.name +
        // //   " BR-" +
        // //   bidIncrement.toString();
        // let name = currentProject.name + " BR-" + bidIncrement.toString();

        // let deadline = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);

        // console.log("NewSolicitation:useEffect:name", name);
        // setNewProject({ ...newProject, name: name, deadline: deadline });
      }
    } else if (step === 3) {
      if (currentService < selectedServices.length - 1) {
        setCurrentService(currentService + 1);
        console.log(
          "handleNextStepClick step === 3 currentService < selectedServices.length - 1 => currentService",
          currentService
        );
        // setCurrentServiceName(selectedServices[currentService]);
      } else {
        console.log(
          "handleNextStepClick step === 3 currentService >= selectedServices.length - 1",
          selectedServices.length - 1
        );
        setCurrentService(selectedServices.length - 1);
        // setCurrentServiceName(selectedServices[currentService]);
        setStep(step + 1);
      }
    } else {
      console.log("handleNextStepClick step > 3");
      setStep(step + 1);
    }
  };
  const handlePreviousStepClick = () => {
    if (step === 3) {
      if (currentService > 0) {
        // setCurrentServiceName(selectedServices[currentService - 1]);
        setCurrentService(currentService - 1);
      } else {
        // setCurrentServiceName(selectedServices[0]);
        setCurrentService(0);
        setStep(step - 1);
      }
    } else if (step > 3) {
      if (selectedServices[0] === undefined && step - 1 === 3) {
        setStep(step - 2);
        // setCurrentService(0);
      } else {
        setStep(step - 1);
        setCurrentService(selectedServices.length - 1);
      }
      // setCurrentService(selectedServices.length - 1);
    } else {
      setStep(step - 1);
    }
  };
  const handlePreviousStepClickOld = () => {
    if (step < 2) {
      setCurrentServiceName(selectedServices[0]);
      setCurrentService(0);
      setStep(step - 1);
    } else if (step < totalSteps - 1) {
      setCurrentServiceName(selectedServices[currentService]);
      alert(
        "Service-" +
          currentService +
          "-" +
          selectedServices[currentService] +
          " step-" +
          step
      );
      setCurrentService(currentService - 1);
      setStep(step - 1);
    } else {
      setStep(step - 1);
    }
  };
  function isSelected(value) {
    // console.log("isSelected", value, selectedPerson);
    const selected = selectedLanguages
      ? selectedLanguages.find((el) => el === value)
        ? true
        : false
      : false;

    console.log("isSelected", selected, value, selectedLanguages);
    return selected;
  }
  const findOption = (service, option) => {
    let p = { ...newProject };
    let opts2 =
      p.services[p.services.findIndex((el) => el.service === service)].opts2;
    if (!opts2) {
      opts2 = {};
    }
    return opts2;
  };
  const handleMultiSelect = (selected, service, key) => {
    //updated state array selectedJurisdictions with new items from selected
    console.log("handleMultiSelect isSelected is TRUE");

    let updated;

    if (isSelected(selected)) {
      if (key == "required_language") {
        updated = selectedLanguages.filter((el) => el !== selected);
        setSelectedLanguages(updated);
      } else if (key == "call_center_location") {
        updated = selectedCallCenterLocation.filter((el) => el !== selected);
        setSelectedCallCenterLocation(updated);
      }
    } else {
      if (key == "required_language") {
        updated = [...selectedLanguages, selected];

        setSelectedLanguages(updated);
      } else if (key == "call_center_location") {
        updated = [...selectedCallCenterLocation, selected];

        setSelectedCallCenterLocation(updated);
      } else if (key == "client_industry") {
        updated = [...selectedClientIndustry, selected];

        setSelectedClientIndustry(updated);
      }
    }

    let updates = { ...newProject };
    let opts2 =
      updates.services[
        updates.services.findIndex((el) => el.service === service)
      ].opts2;
    console.log("OPTS2", opts2);
    if (!opts2) {
      updates.services[
        updates.services.findIndex((el) => el.service === service)
      ].opts2 = {};
    }

    opts2[key] && delete opts2[key];
    opts2[key] = updated;

    setNewProject(updates);
  };

  const handleMultiSelectNew = (selected, service, key) => {
    //updated state array selectedJurisdictions with new items from selected
    console.log("handleMultiSelectNew isSelected is TRUE");

    let updated;

    let updates = { ...newProject };
    let opts2 =
      updates.services[
        updates.services.findIndex((el) => el.service === service)
      ].opts2;
    let oldSelection = opts2 && opts2[key] ? opts2[key] : [];

    if (!opts2) {
      updates.services[
        updates.services.findIndex((el) => el.service === service)
      ].opts2 = {};
    }

    if (oldSelection.includes(selected)) {
      console.log(selected, "is included in", oldSelection);
      updated = oldSelection.filter((el) => el !== selected);

      //remove selected from oldSelection
    } else {
      console.log(selected, "is NOT included in", oldSelection);
      updated = [...oldSelection, selected];
      //add selected to oldSelection
    }

    opts2[key] && delete opts2[key];
    opts2[key] = updated;

    setNewProject(updates);
  };
  const handleMultiDeSelectNew = (selected, service, key) => {
    //update newProject with removed item from selected
    console.log("handleMultiDeSelectNew isSelected is TRUE");
    let newState = { ...newProject };
    //find and remove selected from newState
    let updated = newState.services[
      newState.services.findIndex((el) => el.service === service)
    ].opts2[key].filter((el) => el !== selected);

    newState.services[
      newState.services.findIndex((el) => el.service === service)
    ].opts2[key] = updated;

    setNewProject(newState);
  };

  const handleCreditMonitorGroups = (selected, service, key, path) => {
    //updated state array selectedJurisdictions with new items from selected
    console.log("handleMultiSelect isSelected is TRUE");

    let updated;

    if (isSelected(selected)) {
      if (key == "required_language") {
        updated = selectedLanguages.filter((el) => el !== selected);
        setSelectedLanguages(updated);
      } else if (key == "call_center_location") {
        updated = selectedCallCenterLocation.filter((el) => el !== selected);
        setSelectedCallCenterLocation(updated);
      }
    } else {
      if (key == "required_language") {
        updated = [...selectedLanguages, selected];

        setSelectedLanguages(updated);
      } else if (key == "call_center_location") {
        updated = [...selectedCallCenterLocation, selected];

        setSelectedCallCenterLocation(updated);
      }
    }

    let updates = { ...newProject };
    let opts2 =
      updates.services[
        updates.services.findIndex((el) => el.service === service)
      ].opts2;
    console.log("OPTS2", opts2);
    if (!opts2) {
      updates.services[
        updates.services.findIndex((el) => el.service === service)
      ].opts2 = {};
    }

    opts2[key] && delete opts2[key];
    opts2[key] = updated;

    setNewProject(updates);
  };

  const handleCreditMonitorSingleOLD = (
    field,
    e,
    key,
    service,
    path,
    fieldType
  ) => {
    console.log(
      "onFieldChange",
      "{field:",
      field,
      ",value:",
      e,
      ",key:",
      key,
      ",service:",
      service,
      ",path:",
      path,
      "}"
    );
    setErr(false);
    setErrMessage("");
    const isChecked =
      e && e.target && e.target.type && e.target.type === "checkbox";
    const value = isChecked
      ? e.target.checked
      : e.target && e.target.value
      ? e.target.value
      : e;
    key = key && key.key ? key.key : key;

    const newState = { ...newProject };
    if (path && path !== null) {
      let opts2 =
        newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2;

      if (!opts2) {
        opts2 = {};
        opts2[path] = {};
        fieldType && fieldType == "single"
          ? (opts2[path][key] = value)
          : (opts2[path][key] = []);
      } else {
        if (!opts2[path]) {
          opts2[path] = {};
          fieldType && fieldType == "single"
            ? (opts2[path][key] = value)
            : (opts2[path][key] = []);
        }
      }

      console.log("handleCreditMonitor opts2", JSON.stringify(opts2));

      newState.services[
        newState.services.findIndex((el) => el.service === service)
      ].opts2 = opts2;

      // if (
      //   newState.services[
      //     newState.services.findIndex((el) => el.service === service)
      //   ].opts2[path] &&
      //   !newState.services[
      //     newState.services.findIndex((el) => el.service === service)
      //   ].opts2[path][key]
      // ) {
      //if singleselect
      if (fieldType && fieldType == "single") {
        newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2[path][key] = value;
      } else {
        // newState.services[
        //   newState.services.findIndex((el) => el.service === service)
        // ].opts2[path][key].push(value);

        let jdArray =
          newState.services[
            newState.services.findIndex((el) => el.service === service)
          ].opts2[path][key];

        console.log(
          "handleCreditMonitor Found JD Array",
          JSON.stringify(jdArray)
        );

        if (!jdArray.find((el) => el == value)) {
          console.log(
            "handleCreditMonitor Value not found in array, pushing jd",
            key,
            value,
            jdArray
          );
          jdArray.push(value);
        } else {
          const newArr = jdArray.filter((el) => el !== value);
          console.log("handleCreditMonitor newArr", newArr);
          //remove the item from the array
          newState.services[
            newState.services.findIndex((el) => el.service === service)
          ].opts2[path][key] = newArr;
          console.log(
            "handleCreditMonitor Value found, should be deleted",
            key,
            value,
            newState.services[
              newState.services.findIndex((el) => el.service === service)
            ].opts2[path][key]
          );
        }
        // newState.services[
        //   newState.services.findIndex((el) => el.service === service)
        // ].opts2[path][key] = jdArray;
      }

      console.log("handleCreditMonitor onFieldChange newproject", newState);

      setNewProject(newState);

      console.log("handleCreditMonitor onFieldChange newproject", newState);
    }
  };
  const handleCreditMonitorSingle = (
    selected,
    service,
    key,
    path,
    fieldType
  ) => {
    //updated state array selectedJurisdictions with new items from selected
    console.log("handleCreditMonitorSingle", selected, service, key, path);
    let updated = { ...newProject };
    // ######################### CREATE PATH ##############################
    let opts2 =
      updated.services[
        updated.services.findIndex((el) => el.service === service)
      ].opts2;

    if (!opts2) {
      opts2 = {};
      opts2[path] = {};
    } else {
      if (!opts2[path]) {
        opts2[path] = {};
      }
    }

    updated.services[
      updated.services.findIndex((el) => el.service === service)
    ].opts2 = opts2;
    //#########################################################################

    //##################### SINGLE SELECT #####################################

    if (fieldType && fieldType == "single") {
      updated.services[
        updated.services.findIndex((el) => el.service === service)
      ].opts2[path][key] = selected;

      setNewProject(updated);

      return;
    }

    //#########################################################################

    let selectedUpdated = updated.services[
      updated.services.findIndex((el) => el.service === service)
    ].opts2[path][key]
      ? updated.services[
          updated.services.findIndex((el) => el.service === service)
        ].opts2[path][key]
      : [];
    // console.log("UPDATEDDDD selectedUpdated", selected, key, selectedUpdated);
    let isSelected = selectedUpdated.find((el) => el === selected);

    if (isSelected) {
      selectedUpdated = selectedUpdated.filter((el) => el !== selected);
      console.log("removing item from selectedUpdated", selectedUpdated);
    } else {
      selectedUpdated = [...selectedUpdated, selected];
    }

    opts2[path][key] && delete opts2[path][key];
    opts2[path][key] = selectedUpdated;

    setNewProject(updated);
  };
  const handleSelectedLanguage = (selected, service, key) => {
    //updated state array selectedJurisdictions with new items from selected
    console.log("handleSelectedLanguage isSelected is TRUE");

    let selectedLanguagesUpdated;
    if (isSelected(selected)) {
      selectedLanguagesUpdated = selectedLanguages.filter(
        (el) => el !== selected
      );
      console.log("handleSelectedLanguage isSelected is TRUE");
      setSelectedLanguages(selectedLanguagesUpdated);
    } else {
      selectedLanguagesUpdated = [...selectedLanguages, selected];

      setSelectedLanguages(selectedLanguagesUpdated);
    }

    let updated = { ...newProject };
    let opts2 =
      updated.services[
        updated.services.findIndex((el) => el.service === service)
      ].opts2;
    console.log("OPTS2", opts2);
    if (!opts2) {
      updated.services[
        updated.services.findIndex((el) => el.service === service)
      ].opts2 = {};
    }

    opts2[key] && delete opts2[key];
    opts2[key] = selectedLanguagesUpdated;

    setNewProject(updated);
  };
  const handleSingleSelect = (selected, service, key) => {
    //updated state array selectedJurisdictions with new items from selected
    console.log("handleSelectedLanguage isSelected is TRUE");

    let selectedLanguagesUpdated;
    if (isSelected(selected)) {
      selectedLanguagesUpdated = selectedLanguages.filter(
        (el) => el !== selected
      );
      console.log("handleSelectedLanguage isSelected is TRUE");
      setSelectedLanguages(selectedLanguagesUpdated);
    } else {
      selectedLanguagesUpdated = [...selectedLanguages, selected];

      setSelectedLanguages(selectedLanguagesUpdated);
    }

    let updated = { ...newProject };
    let opts2 =
      updated.services[
        updated.services.findIndex((el) => el.service === service)
      ].opts2;
    console.log("OPTS2", opts2);
    if (!opts2) {
      updated.services[
        updated.services.findIndex((el) => el.service === service)
      ].opts2 = {};
    }

    opts2[key] && delete opts2[key];
    opts2[key] = selectedLanguagesUpdated;

    setNewProject(updated);
  };
  const handleSingleSelectNew = (selected, service, key) => {
    console.log("handleSingleSelectNew isSelected is TRUE");

    let updated = { ...newProject };
    let opts2 =
      updated.services[
        updated.services.findIndex((el) => el.service === service)
      ].opts2;
    console.log("OPTS2", opts2);
    if (!opts2) {
      updated.services[
        updated.services.findIndex((el) => el.service === service)
      ].opts2 = {};
    }

    opts2[key] && delete opts2[key];
    opts2[key] = selected;

    setNewProject(updated);
  };
  const handleSelectedLanguage1 = (selected, service, key) => {
    //updated state array selectedJurisdictions with new items from selected
    let p = { ...newProject };
    let opts2 =
      p.services[p.services.findIndex((el) => el.service === service)].opts2;

    if (!opts2) {
      p.services[p.services.findIndex((el) => el.service === service)].opts2 =
        {};
      p.services[
        p.services.findIndex((el) => el.service === service)
      ].opts2.required_languages = [];
    } else {
      p.services[
        p.services.findIndex((el) => el.service === service)
      ].opts2.required_languages = [selectedLanguages];
    }

    console.log(
      "handleSelectedLanguages",
      p.services[p.services.findIndex((el) => el.service === service)].opts2
    );
    console.log("handleSelectedLanguages", selected, service);
    if (isSelected(selected)) {
      const selectedLanguagesUpdated = selectedLanguages.filter(
        (el) => el !== selected
      );
      setSelectedLanguages(selectedLanguagesUpdated);
      //update the newProject object here to add the selected lang?
    } else {
      setSelectedLanguages((prev) => [...prev, selected]);
      //add selected language
      const updated = { ...newProject };

      // console.log("handleSelectedLanguages", updated.services[service].opt2);
      // delete updated.services[service].opt2.required_languages.selected;
      setNewProject(updated);
    }

    // console.log("handleSelectedJurisdictions", newProject);
  };
  const handleClearAllSelections = (e, service, key) => {
    console.log("handleClearAllSelections", service, key);
    if (key == "selected_language") {
      setSelectedLanguages([]);
    } else if (key == "call_center_location") {
      setSelectedCallCenterLocation([]);
    }
    let updated = { ...newProject };

    let opts2 =
      updated.services[
        updated.services.findIndex((el) => el.service === service)
      ].opts2;

    if (!opts2) {
      updated.services[
        updated.services.findIndex((el) => el.service === service)
      ].opts2 = {};
    }

    opts2[key] = [];

    setNewProject(updated);
  };

  const handleSelectCreationMethodClick = (option) => {
    console.log("handleSelectCreationMethodClick", option);
    if (option == "create-new") {
      console.log("handleSelectCreationMethodClick create-new option", option);
      // cleanUpState();
    } else {
      console.log(
        "handleSelectCreationMethodClick create-from-template option",
        option
      );
    }
    setCreateDuplicate(option == "duplicate" ? true : false);
    setCreateFromTemplate(option == "template" ? true : false);
    setCreationMethod(option);
  };

  const createSolicitationFromTemplate = async (a) => {
    // e.preventDefault();
    console.log("createSolicitationFromTemplate e", a);
    let template = templates.find((el) => el._id == a);
    setSelectedTemplate(template.name);
    let created = template.template;
    created.projectId = props.projectId;
    created.fromTemplateId = template._id;

    delete created._id;
    delete created.createdAt;
    delete created.updatedAt;
    delete created.__v;
    delete created.invitations;
    delete created.responses;
    delete created.name;
    delete created.description;
    delete created.deadline;
    setSelectedJurisdictions(currentProject.jurisdictions);
    setFileList(created.attachments);
    //loop through services and delte all service.options
    created.services.forEach((el) => {
      delete el.options;
    });

    console.log("createSolicitationFromTemplate created", created);
    let opts2 = [];
    let allCohorts = created.services.find(
      (el) => el.service === "credit_monitoring"
    );
    if (allCohorts) {
      //loop through opts2 object and push all values to opts2 array
      allCohorts &&
        allCohorts.opts2 &&
        Object.keys(allCohorts.opts2).forEach((el) => {
          console.log("COHORTS EL ==> ", el);
          //remove el.jurisidiction and el.group_total before pushing
          delete allCohorts.opts2[el].jurisdiction;
          delete allCohorts.opts2[el].group_total;

          opts2.push(allCohorts.opts2[el]);
        });
    }

    setCohorts(opts2);
    setNewProject(created);
    // setCohorts(
    //   created.services.find((el) => el.service === "credit_monitoring")
    //     ? [
    //         created.services.find((el) => el.service === "credit_monitoring")
    //           .opts2,
    //       ]
    //     : []
    // );

    setLoading(false);
  };

  const handleCreateFromCopyClick = async (e) => {
    // e.preventDefault();
    setCreateDuplicate(true);
    setCreationMethod("duplicate");
    let createdProject = { ...newProject };
    createdProject.projectId = props.projectId;
    const created = await Solicitations.createFromDuplicate(createdProject);
    if (created) {
      setNewProject(created);
      // setModalOpen(false);
    } else {
      setErr(true);
      setErrMessage("Error creating solicitation");
    }
  };

  function handleDeselectLanguage(value) {
    console.log("handleDeselectLanguages", value);
    if (isSelected(value)) {
      const selectedLanguagesUpdated = selectedLanguages.filter(
        (el) => el !== value
      );
      console.log("handleDeselectLanguages isSelected is TRUE");
      setSelectedLanguages(selectedLanguagesUpdated);
    }
    //update newProject object here to remove the deselected language
    // const updated = { ...newProject };
    // console.log("handleDeselectLanguages", updated.services[service].opt2);
    // // delete updated.services[service].opt2.required_languages.selected;
    // setNewProject(updated);
    //remove jurisdiction from newProject
    // const updated = { ...newProject };
    // console.log("handleDeselectedJurisdictions", updated.jurisdictions);
    // delete updated.jurisdictions[value];
    // setNewProject(updated);
  }

  function deepFind(obj, path) {
    //create object path from path string and return object
    var paths = path.split("."),
      current = obj,
      i;

    for (i = 0; i < paths.length; ++i) {
      if (current[paths[i]] === undefined) {
        current[paths[i]] = {};
        current = current[paths[i]];
      } else {
        current = current[paths[i]];
      }
    }
  }

  function deepFind2(obj, path) {
    //set path to key in object to the path
    var paths = path.split(".");
    for (var i = 0; i < paths.length; i++) {
      obj = obj[paths[i]];
      obj[paths[i]] = "test";
      console.log("deepFind2", obj[paths[i]]);
    }

    return current;
  }

  function findVal(object, key) {
    var value;
    Object.keys(object).some(function (k) {
      if (k === key) {
        value = object[k];
        return true;
      }
      if (object[k] && typeof object[k] === "object") {
        value = findVal(object[k], key);
        return value !== undefined;
      }
    });
    return value;
  }

  const createCohort = (e) => {
    let newCohort = {};
    newCohort.name = "";
    newCohort.jurisdiction = "";
    newCohort.total = 0;
    newCohort.opts2 = {};
    setCohorts([...cohorts, newCohort]);
  };

  const handleClearParentSettings = (
    field,
    e,
    key,
    service,
    path,
    isParent,
    optionsFieldName
  ) => {
    const newState = { ...newProject };

    let opts2 =
      newState.services[
        newState.services.findIndex((el) => el.service === service)
      ].opts2[optionsFieldName];

    Object.keys(opts2).forEach((el) => {
      console.log("handleClearParentSettings", el);
      onFieldChange("options", e, el, service, null, false, optionsFieldName);
    });
  };

  const onFieldChangeRTF = (field, e, content) => {
    console.log(
      "handleFieldChangeRTF",
      field,
      e.target.name,
      e.target.value,
      "content:",
      content
    );

    const { name, value } = e.target;
    console.log("Raw value", value);

    setNewProject((prevState) => ({
      ...prevState,
      rtf_description: value,
      description: content,
    }));
  };

  const handleSwitchChange = (field, e) => {
    console.log("handleSwitchChange", e, field);
    setNewProject((prevState) => ({
      ...prevState,
      [field]: e,
    }));
  };

  const onFieldChange = (
    field,
    e,
    key,
    service,
    path,
    isParent,
    optionsFieldName
  ) => {
    console.log(
      `onFieldChange ${service} ${field}`,

      field,
      e,
      key,
      service,
      path
    );
    setErr(false);
    setErrMessage("");
    const isChecked =
      e && e.target && e.target.type && e.target.type === "checkbox";
    const isRTF = e && e.target && e.target.type && e.target.type === "rtf";
    console.log("onFieldChange isChecked", isChecked);
    const isDate = field == "deadline" ? true : false;
    let value;
    if (
      field == "deadline" ||
      key == "weekday_hours" ||
      key == "weekend_hours"
    ) {
      value = e;
    } else {
      value =
        isChecked == true
          ? e.target.checked
          : e.target && e.target.value
          ? e.target.value
          : e.target.value;
    }
    key = key && key.key ? key.key : key;

    const newState = { ...newProject };

    if (path && path !== null) {
      // console.log("onFieldChange field == options");
      //check if fieldname contains a .

      let opts2 =
        newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2;

      if (!opts2) {
        opts2 = {};
        opts2[path] = {};
      } else {
        if (!opts2[path]) {
          opts2[path] = {};
        }
      }

      //update state with newState with opts2
      newState.services[
        newState.services.findIndex((el) => el.service === service)
      ].opts2 = opts2;

      // opts2[key] = value;

      console.log("onFieldChange opts2", value);

      if (
        newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2[path] &&
        !newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2[path][key]
      ) {
        newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2[path][key] = value;
      } else {
        newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2[path][key] = value;
      }

      console.log("onFieldChange newproject", newState);

      setNewProject(newState);

      console.log("onFieldChange newproject", newState);
    } else if (field && field == "options") {
      let opts2 =
        newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2;

      if (!opts2) {
        opts2 = {};
      }
      if (key === "required_languages") {
        console.log("Checking for kvp", opts2.required_languages);
        if (!opts2.required_languages) {
          console.log("required_languages not found ing opts2", opts2);
          opts2["required_languages"] = [];
        }

        console.log(
          "required_language opts2[required_language] created",
          opts2["required_languages"]
        );

        let jdArray = [...opts2["required_languages"]];

        console.log(
          "required_language jdArray set",
          opts2["required_languages"],
          jdArray
        );
        // const jd = { key: value };
        // const jd = value;

        console.log("required_language --- ");

        if (!jdArray.find((el) => el == value)) {
          console.log("Value not found in array, pushing jd");
          jdArray.push(value);
        } else {
          //remove the item from the array
          jdArray.filter((el) => el !== value);
          console.log(
            "required_languages contains key, should be deleted",
            key,
            value,
            jdArray
          );
          // jdArray[index].value = value;
        }
        // } else {
        //   //if jdArray contains {key: key}, update the value
        //   const index = jdArray.findIndex((el) => el.key == key);
        //   console.log("required_languages contains key", key)
        //   jdArray[index].value = value;
        // }

        //update the newState with the new array
        opts2["required_languages"] = jdArray;

        //end
      }

      //update state with newState with opts2
      newState.services[
        newState.services.findIndex((el) => el.service === service)
      ].opts2 = opts2;

      // opts2[key] = value;

      console.log("onFieldChange opts2", value);

      //if newState does not contain the key/value pair, add it. Otherwise, update the value without losing
      //the other key/value pairs
      if (
        newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2 &&
        !newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2[key]
      ) {
        newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2[key] = value;
      } else {
        newState.services[
          newState.services.findIndex((el) => el.service === service)
        ].opts2[key] = value;
      }

      console.log("onFieldChange newproject", newState);

      setNewProject(newState);

      console.log("onFieldChange newproject", newState);
    } else if (field && field == "jurisdiction") {
      console.log("onFieldChange field == jurisdiction");
      if (!newState[field]) {
        newState[field] = [];
      }

      //set jdArray to the value of the jurisdictions array
      // let jdArray = newState[field];
      let jdArray = newState.services[
        newState.services.findIndex((el) => el.service === service)
      ].options.find((el) => el.jurisdictions).jurisdictions;
      const jd = { key: key, value: value };

      if (!jdArray.find((el) => el.key == key)) {
        jdArray.push(jd);
      } else {
        //if jdArray contains {key: key}, update the value
        const index = jdArray.findIndex((el) => el.key == key);
        jdArray[index].value = value;
      }

      console.log("onFieldChange newproject jd", jdArray);

      //update the newState with the new array
      newState[field] = jdArray;

      setNewProject(newState);
    } else if (field && field == "deadline") {
      //handle date picker change
      console.log("onFieldChange field == deadline");
      newState[field] = e;
      setNewProject(newState);
    } else if (field && field == "rtf_description") {
      console.log(
        "onFieldChange field == rtf_description",
        field,
        e.target.value
      );
      newState[field] = e.target.value;
      //spread the newProject object and update the description
      setNewProject((prevState) => ({
        ...prevState,
        newState,
      }));
    } else {
      console.log("onFieldChange field == other", e.target?.value);
      //update the newState with the new value
      newState[field] = isChecked ? value : e.target.value;
      setNewProject(newState);
    }

    if (
      field == "options" &&
      key === "project_reporting" &&
      e.target.checked == false
      //if project_reporting is unchecked, delete project_reporting_options
    ) {
      //delete project_reporting_options
      delete newState.services[
        newState.services.findIndex((el) => el.service === service)
      ].opts2["project_reporting_options"];
      console.log("DELTED project_reporting_options", newState);
      setNewProject(newState);
    }

    //if isparent, update the children
    // if (isParent) {
    //   handleClearParentSettings(
    //     field,
    //     e,
    //     key,
    //     service,
    //     path,
    //     isParent,
    //     optionsFieldName
    //   );
    //   console.log("isParent handleClearParentSettings --->", newProject);
    // }
  };

  // const onFieldChange = (field, e, key, service, hasChildren) => {
  //   //   {
  //   //     "services": [
  //   //         {
  //   //             "service": "mailings",
  //   //             "options": [
  //   //                 {
  //   //                     "jurisdictions": [
  //   //                         {
  //   //                             "key": "Algeria",
  //   //                             "value": "100"
  //   //                         },
  //   //                         {
  //   //                             "key": "Austria",
  //   //                             "value": "499"
  //   //                         },
  //   //                         {
  //   //                             "key": "Barbados",
  //   //                             "value": "350"
  //   //                         }
  //   //                     ]
  //   //                 }
  //   //             ],
  //   //             "opts2": {
  //   //                 "project_reporting": true,
  //   //                 "project_reporting_options": {
  //   //                     "letter_confirmation": true,
  //   //                      "other_confirmation": true
  //   //                 }
  //   //
  //   //             }
  //   //         }
  //   //     ]
  //   // }

  //   console.log(
  //     "onFieldChange",
  //     "{field:",
  //     field,
  //     ",value:",
  //     e,
  //     ",key:",
  //     key,
  //     ",service:",
  //     service,
  //     "}"
  //   );
  //   setErr(false);
  //   setErrMessage("");
  //   const isChecked = e.target.type === "checkbox";
  //   const value = isChecked ? e.target.checked : e.target.value;
  //   key = key && key.key ? key.key : key;

  //   const newState = { ...newProject };
  //   // console.log("onFieldChange newState", JSON.stringify(newState));

  //   if (field && field == "options") {
  //     // console.log("onFieldChange field == options");
  //     //check if fieldname contains a .
  //     if (key && key.includes(".")) {
  //       // console.log("onFieldChange key.includes(.)");
  //       //split key into array
  //       const keyArray = key.split(".");
  //       // console.log("onFieldChange keyArray", keyArray);
  //       //find the nested key in the opts2 object using the keyArray
  //     }

  //     let opts2 =
  //       newState.services[
  //         newState.services.findIndex((el) => el.service === service)
  //       ].opts2;

  //     if (!opts2) {
  //       opts2 = {};
  //     }
  //     //update state with newState with opts2
  //     newState.services[
  //       newState.services.findIndex((el) => el.service === service)
  //     ].opts2 = opts2;

  //     // opts2[key] = value;

  //     console.log("onFieldChange opts2", value);

  //     //if newState does not contain the key/value pair, add it. Otherwise, update the value without losing
  //     //the other key/value pairs
  //     if (
  //       newState.services[
  //         newState.services.findIndex((el) => el.service === service)
  //       ].opts2 &&
  //       !newState.services[
  //         newState.services.findIndex((el) => el.service === service)
  //       ].opts2[key]
  //     ) {
  //       newState.services[
  //         newState.services.findIndex((el) => el.service === service)
  //       ].opts2[key] = value;
  //     } else {
  //       //update the value without losing what's already there

  //       newState.services[
  //         newState.services.findIndex((el) => el.service === service)
  //       ].opts2[key] = value;
  //     }

  //     console.log("onFieldChange newproject", newState);

  //     setNewProject(newState);

  //     console.log("onFieldChange newproject", newState);
  //   } else if (field && field == "jurisdiction") {
  //     console.log("onFieldChange field == jurisdiction");
  //     if (!newState[field]) {
  //       newState[field] = [];
  //     }

  //     //set jdArray to the value of the jurisdictions array
  //     // let jdArray = newState[field];
  //     let jdArray = newState.services[
  //       newState.services.findIndex((el) => el.service === service)
  //     ].options.find((el) => el.jurisdictions).jurisdictions;
  //     const jd = { key: key, value: value };

  //     if (!jdArray.find((el) => el.key == key)) {
  //       jdArray.push(jd);
  //     } else {
  //       //if jdArray contains {key: key}, update the value
  //       const index = jdArray.findIndex((el) => el.key == key);
  //       jdArray[index].value = value;
  //     }

  //     console.log("onFieldChange newproject jd", jdArray);

  //     //update the newState with the new array
  //     newState[field] = jdArray;

  //     setNewProject(newState);
  //   } else {
  //     console.log("onFieldChange field == other");
  //     //update the newState with the new value
  //     newState[field] = value;
  //     setNewProject(newState);
  //   }
  // };

  const handleSelect = (field, value) => {
    console.log("handleSelect NewProject.js", field, value);
    //update state with new key/value pair
    const newState = { ...newProject };
    // newState["blurbs"][name] = value;
    newState[field] = value;
    setNewProject(newState);
  };

  const handleRadioSelect = (field, value, key, service) => {
    console.log(
      "handleRadioSelect Solicitation =>",
      field,
      value,
      key,
      service
    );
    //update state with new key/value pair
    const newState = { ...newProject };
    console.log("handleRadioSelect initial newState", JSON.stringify(newState));
    if (!newState[field]) {
      console.log("handleRadioSelect creating newState", field);
      newState[field] = [];
      console.log(
        "handleRadioSelect created newState[]field",
        JSON.stringify(newState)
      );
    }
    //BEGIN NEW
    console.log("handleRadioSelect newState[field] created", newState);

    let jdArray = newState[field];
    let jd = {
      service: value,
      options: [{ jurisdictions: currentProject.jurisdictions }],
    };

    console.log("Checking jdArray for service", jdArray, value);

    if (!utils.isInArray(jdArray, "service", value)) {
      newState[field] = [
        ...newState[field],
        {
          service: value,
          options: [{ jurisdictions: currentProject.jurisdictions }],
          opts2: {},
        },
      ];
    } else {
      //remove service from newstate[field] array while keeping the rest of the array intact
      newState[field] = newState[field].filter((el) => el.service !== value);
    }

    console.log("handleRadioSelect updated NewState with jdArray", newState);

    setSolicitationJurisdictions(currentProject.jurisdictions);
    console.log(
      "handleRadioSelect SETTING setNewProject to newState",
      newState
    );
    //setNewProject and trigger a re-render
    setNewProject(newState);

    console.log("handleRadioSelect newProject should have updated", newState);
  };

  const cleanUpState = () => {
    setNewProject({});
    setStep(0);
    setErr(false);
    setErrMessage("");
    setSelectedJurisdictions([]);
    setCohorts([]);
    setCreateDuplicate(false);
    setCreateFromTemplate(false);
    setCreationMethod("create-new");
    setSaved(false);
    setSent(false);
    setTemplateSaved(false);
    setSent(false);
    setTemplateName("");
    setTemplateDescription("");
    setSelectedTemplate("");
    setFileList([]);
    setLastStepState(null);
  };
  const isValid = (field) => {
    console.log("Check isValid", newProject, field);

    if (newProject && Object.keys(newProject).length === 0) {
      setErr(true);
      setErrMessage("You need to give your project a name before saving.");
      setLoading(false);
      return false;
    } else {
      console.log("isValid the newProject is not empty");
      return true;
    }
  };

  const isSolicitationValid = () => {
    const newState = { ...newProject };
    if (!newState.name) {
      return false;
    }
    return true;
    // if (!newState.description) {
    //   setErr(true);
    //   setErrMessage("Missing description");

    //   return false;
    // }
  };

  const onSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const valid = isSolicitationValid();
    if (!valid) {
      setTimeout(() => {
        setLoading(false);
        setErr(true);
        setErrMessage("You must give your bid request a name before saving");
      }, 1000);
    } else {
      const newState = { ...newProject };
      newState.projectId = props.projectId;

      setNewProject(newState);
      newProject.projectId = props.projectId;

      const saved = await Solicitations.create(newState);
      if (saved) {
        console.log("Saved successfully", saved);
        setNewProject(saved);
        setTimeout(() => {
          setSaved(true);
          // props.updateData(saved);
          setLoading(false);
        }, 2000);
      }
    }
  };

  const onSend = async (people) => {
    setLoading(true);
    const newState = { ...newProject };
    newState.invitations = people;
    newState.projectId = props.projectId;
    setNewProject(newState);
    newProject.projectId = props.projectId;

    const saved = await Solicitations.update(newState);
    if (saved) {
      console.log("Saved successfully", saved);
      console.log("Sending invites");
      sendAssessment(people, saved._id);
      audit.logAction("Send Bid Request", {
        name: saved.name,
        id: saved._id,
        description: saved.description,
        projectId: saved.projectId,
      });
      setLoading(false);
      setSent(true);
      // cleanUpState();
      // props.setModalOpen(false);
      props.updateData(saved);
      //close the modal and show success notification
    }
    setLastStepState(null);
  };

  const onClose = async (people) => {
    console.log("Close the new solicitation window");
    setLoading(false);
    props.setModalOpen(false);
    cleanUpState();
  };

  const getSelectedJurisdictions = () => {
    console.log("looking for jurisdictions newProject");
    let selected;
    if (solicitationJurisdictions) {
      console.log("Getting solicitationJurisdictions");
      selected = solicitationJurisdictions;
    } else {
      console.log("Getting selectedJurisdictions");
      selected = selectedJurisdictions;
    }
    console.log("selected", selected);
    return selected;
  };

  const handleGetSelectedJurisdictions = () => {
    console.log("looking for jurisdictions newProject");
    if (newProject.services && newProject.services.length > 0) {
      console.log("newProject.services: ", newProject.services);
      return (
        findJurisdictions(newProject.services, "mailings", "jurisdictions") ||
        []
      );
    } else {
      console.log("newProject no services selected");
      return selectedJurisdictions;
    }
  };
  const findJurisdictions = (array, service, option) => {
    console.log("findJurisdictions", array, service);
    const found = array.find((svc) => svc.service === service);
    console.log("found service", found);

    //find jurisdictions key in options array
    const found2 = found.options.find((opt) => {
      console.log("opt", opt.jurisdictions);
      console.log("project opts", currentProject.jurisdictions);
    });
    return found2.jurisdictions;
  };

  const handleCopyService = (fromService, toService) => {
    console.log("handleCopyService", fromService, toService);
    setCopying(true);
    //find the fromService in the newProject.services array and copy it to the toService
    //then update the newProject.services array with a deep copy of the new toService
    const newState = { ...newProject };
    const fromServiceIndex = newState.services.findIndex(
      (el) => el.service === fromService
    );
    const toServiceIndex = newState.services.findIndex(
      (el) => el.service === toService
    );
    console.log("handleCopyService fromServiceIndex", fromServiceIndex);
    console.log("handleCopyService toServiceIndex", toServiceIndex);

    newState.services[toServiceIndex] = JSON.parse(
      JSON.stringify(newState.services[fromServiceIndex])
    );
    newState.services[toServiceIndex].service = toService;
    setTimeout(() => {
      setNewProject(newState);
      setCopying(false);
      setSuccess(true);
      // alert("Success true");
    }, 750);
    setTimeout(() => {
      // alert("Success false");
      setSuccess(false);
    }, 3000);
  };

  return (
    <div className="flex flex-col h-full w-full">
      <form className="mb-5 space-y-8 divide-y divide-gray-200">
        <div className="">
          {/* Intro */}
          {step == 0 && (
            <div>
              <div>
                <h3 className="text-base font-semibold leading-6 text-slate-900">
                  What is a bid request?
                </h3>
                <Paragraph>
                  A bid request is where the magic happens. We'll walk you
                  through all of the things you need to send out a request to
                  the vendors of your choice.{" "}
                  <span className="font-semibold">
                    The vendors will be able to see your requirements and
                    respond with a bid.
                  </span>
                </Paragraph>
              </div>

              <div className="mt-20">
                <h3 class="mb-5 text-base font-semibold text-gray-900">
                  How do you want to create your bid request?
                </h3>
                <ul class="grid w-full gap-6 grid-cols-1 lg:grid-cols-2">
                  <li
                    id="new-solicitation-create-new-option"
                    className="min-h-full"
                  >
                    <input
                      type="radio"
                      id="create-new-in-new-solicitation"
                      name="new-solicitation"
                      value="create-new"
                      checked={creationMethod === "create-new"}
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("create-new clicked");
                        console.log("e", e);
                        if (creationMethod === "create-new") return;
                        handleSelectCreationMethodClick("create-new");
                      }}
                      class="hidden peer"
                      required
                    />

                    <label
                      for="create-new-in-new-solicitation"
                      class="inline-flex min-h-full items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer  peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-100 "
                    >
                      <div className="w-full grid grid-cols-6 gap-6">
                        <div className="col-span-1 flex items-center">
                          <Icon
                            icon="heroicons:document-plus"
                            className="h-full w-full"
                          />
                        </div>
                        <div className="flex col-span-5  justify-between">
                          <div class="block">
                            <div class=" w-full text-lg font-semibold">
                              Start from scratch
                            </div>
                            <div class="">
                              Get started right away if your needs are unique.
                            </div>
                          </div>
                          <div className="flex items-center justify-ends"></div>
                        </div>
                      </div>
                    </label>
                  </li>

                  {templates && templates.length > 0 && (
                    <li
                      id="new-solicitation-template-option"
                      className="min-h-full"
                    >
                      <input
                        type="radio"
                        id="template"
                        name="new-solicitation"
                        value="template"
                        checked={creationMethod === "template"}
                        class="hidden peer"
                        required
                        onClick={() => {
                          handleSelectCreationMethodClick("template");
                        }}
                      />

                      <label
                        for="template"
                        class="inline-flex min-h-full items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer  peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-100 "
                      >
                        <div className="w-full grid grid-cols-6 gap-6">
                          <div className="col-span-1 flex items-center">
                            <Icon
                              icon="heroicons:document-duplicate"
                              className="h-full w-full"
                            />
                          </div>
                          <div className="flex col-span-5  justify-between">
                            <div class="block">
                              <div class=" w-full text-lg font-semibold">
                                Create from a saved template
                              </div>
                              <div class="">
                                The fastest option if you have templates.
                              </div>
                            </div>
                          </div>
                        </div>
                      </label>
                    </li>
                  )}
                </ul>

                {/* {createFromTemplate && ( */}
                <EaseIn show={createFromTemplate}>
                  <div className="mt-5">
                    {templates.length > 0 ? (
                      <h3 class="mb-5 text-base font-semibold text-gray-900">
                        Select a template below to create a new bid request.
                      </h3>
                    ) : (
                      <Tip>
                        You'll need to create at least one bid request and save
                        it as a template before you will see any templates here.
                      </Tip>
                    )}
                    {/* <MultiSelectListBox
                      options={[
                        {
                          id: "asdfaasdf",
                          name: "test",
                          area: "project",
                          label: "test",
                          value: "test",
                        },
                        {
                          id: "asdfaasdf2",
                          name: "solicitation 1",
                          area: "Project 1",
                          label: "test2",
                          value: "test2",
                        },
                      ]}
                      fieldType="single"
                      label="Select a bid request to duplicate"
                      name="duplicate_bid_request"
                      handleSelect={(e, value) => handleSelect(e, value)}
                      // handleDeselect={(e) => handleDeselect(e)}

                      value={selectedCopy}
                    /> */}
                    {/* <SelectorButtons
                      options={templates}
                      handleButtonClick={(e) =>
                        createSolicitationFromTemplate(e)
                      }
                    /> */}
                    <RadioGroupCards
                      options={templates}
                      onChange={(e) => createSolicitationFromTemplate(e)}
                      defaultValue={newProject.fromTemplateId}
                    />
                  </div>
                </EaseIn>
                {/* )} */}
              </div>
            </div>
          )}
          {/* Basics */}
          {step == 1 && (
            <div>
              <div>
                <h3 className="text-xl font-semibold leading-6 text-slate-900">
                  The Basics
                </h3>
                <Paragraph>
                  Let's start by collecting some basic details. You can create
                  multiple bid requests for the same project. Each bid request
                  can have a unique name.
                </Paragraph>
                {/* <Tip>
                  If you only plan to have one bid request, you can give it the
                  same name as your project.
                </Tip> */}
                {createFromTemplate && (
                  <Tip>
                    You're creating this bid request from a saved template.
                  </Tip>
                )}
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-5">
                  <Label sm htmlFor="project_name">
                    Bid Request Name{" "}
                    <QuestionToolTip
                      field="Bid Request Name"
                      text="Give your bid request a name. We've pre-filled it with the name of your project. You can change it if you want.
                      Vendors will see this name when they receive your bid request.
                    "
                    />
                  </Label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    {/* <deadlineDatePicker /> */}

                    <input
                      type="text"
                      name="project_name"
                      id="project_name"
                      autoComplete="none"
                      value={newProject.name}
                      onChange={(e) => onFieldChange("name", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <Label sm htmlFor="deadline">
                    Bid Deadline{" "}
                    <QuestionToolTip
                      field="Bid Deadline"
                      text="We've pre-filled this deadline with a date 2 days from now. Feel free to change it. 
                    "
                    />
                  </Label>

                  <div
                    id={"deadline"}
                    className="mt-1 flex rounded-md shadow-sm"
                  >
                    <DatePicker
                      // selected={null}
                      showMonthDropdown
                      minDate={new Date()}
                      onSelect={(e) => onFieldChange("deadline", e)}
                      selected={
                        newProject.deadline
                          ? newProject.deadline
                          : //two days from now
                            new Date(
                              new Date().getTime() + 2 * 24 * 60 * 60 * 1000
                            )
                      }
                      value={newProject.deadline}
                      onChange={(e) => onFieldChange("deadline", e)}
                      // calendarClassName="calendar"

                      className="block w-full flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                    />

                    {/* <input
                      type="text"
                      name="deadline"
                      id="deadline"
                      autoComplete="none"
                      value={newProject.deadline}
                      onChange={(e) => onFieldChange("deadline", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                    /> */}
                  </div>
                </div>
                {profile && profile.role === "Super Admin" && (
                  <div className="flex items-center space-x-3 sm:col-span-6 border-t border-b border-gray-200 bg-gray-50 py-4">
                    <label
                      htmlFor="isForTesting"
                      className="flex items-center cursor-pointer font-medium"
                    >
                      This project is for testing/demo purposes only.
                    </label>
                    <Switch
                      id={"isForTesting"}
                      checked={newProject.isForTesting}
                      onChange={(e) => handleSwitchChange("isForTesting", e)}
                      className={classNames(
                        newProject.isForTesting
                          ? "bg-brand-600"
                          : "bg-gray-200",
                        "flex items-center w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          newProject.isForTesting
                            ? "translate-x-3.5"
                            : "translate-x-0",
                          "flex h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                )}
                <div className="col-span-full lg:col-span-3 pr-0 lg:pr-6 space-y-2">
                  <Label
                    htmlFor="vendor-message"
                    // className="block text-base font-semibold text-slate-700"
                  >
                    Vendor Message
                    <QuestionToolTip
                      field="Vendor Messge"
                      text="This message will be sent to the vendors along with your bid request. You can use this space to give them any additional information you want them to have. 
                    "
                    />
                  </Label>
                  <Paragraph>
                    Write a few sentences to describe the nature of the bid
                    request.
                  </Paragraph>
                  <div className="mt-4 ">
                    <TextEditor
                      placeholder="Use this area to give your bidding requirements."
                      handleFieldChange={(e, rawContent, content) => {
                        onFieldChangeRTF(
                          "rtf_description",
                          {
                            target: {
                              name: "rtf_description",
                              value: rawContent,
                              type: "rtf",
                            },
                          },
                          content
                        );
                      }}
                      handleSaveToAdditionalComments={(e, content) => {
                        console.log("handleSaveToAdditionalComments", content);
                        // onFieldChange("description", {
                        //   target: {
                        //     name: "description",
                        //     value: content,
                        //   },
                        // });
                      }}
                      value={
                        newProject["rtf_description"]
                          ? newProject["rtf_description"]
                          : newProject["description"]
                          ? newProject["description"]
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className=" lg:border-l lg:border-gray-200 col-span-full lg:col-span-3 pl-0 lg:pl-6  space-y-2">
                  <Label
                    htmlFor="attachments"
                    // className="block text-sm font-medium text-slate-700"
                  >
                    Attachments
                  </Label>
                  <p className=" text-sm ">
                    Upload attachments to help the vendor build a more accurate
                    bid.
                  </p>

                  <div className="w-full divide-x mt-4 flex justify-between  rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                    <div className="space-y-1 col-span-2">
                      <div className="flex justify-center text-sm text-gray-600">
                        {fileList && fileList.length > 0 ? (
                          <div>
                            <div className="flex text-sm text-gray-600">
                              Attachments
                            </div>
                            <div className="flex flex-col space-y-1">
                              {fileList.map((file) => (
                                <div className="flex justify-between">
                                  <div className="flex space-x-2">
                                    <PaperClipIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <p>{file.name}</p>
                                  </div>
                                  <div className="flex space-x-2">
                                    <button
                                      onClick={(e) =>
                                        updateFileList(e, file, "remove")
                                      }
                                      className="text-red-500 hover:text-red-700"
                                    >
                                      <XMarkIcon
                                        className="ml-3 h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col space-y-1">
                            No attachments
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col px-6 space-y-1 col-span-2 text-center justify end">
                      <svg
                        className="mx-auto h-12 w-12 text-slate-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex flex-col text-center text-sm text-slate-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-medium text-brand-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-brand-500 focus-within:ring-offset-2 hover:text-brand-500"
                        >
                          <span>Upload a file</span>
                          <UploadComponent
                            fileList={fileList}
                            updateFileList={updateFileList}
                          />
                          {/* <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                          /> */}
                        </label>
                        {/* <p className="pl-1">or drag and drop</p> */}
                      </div>
                      <p className="text-xs text-slate-500">PDF up to 10MB</p>
                    </div>
                  </div>
                </div>
                <div className="col-span-6 relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="nda_required"
                      name="nda_required"
                      disabled={false}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                      placeholder="required_languages_checkbox"
                      value={newProject.nda_required && newProject.nda_required}
                      defaultChecked={
                        newProject.nda_required && newProject.nda_required
                      }
                      onChange={(e) => onFieldChange("nda_required", e)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <Label sm bold htmlFor="comments">
                      Require NDA
                    </Label>
                    <p className="text-slate-500">
                      Check this box if you require the user to read and agree
                      to an uploaded NDA before they can view the bid.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Services */}
          {step == 2 && (
            <div className="">
              <div>
                <h3 className="text-xl font-semibold leading-6 text-slate-900">
                  Services
                </h3>

                <Paragraph>
                  Here you can select all the services you would like a bid for
                  in this request. Keep in mind that you can request multiple
                  services in a single bid or you can create multiple bid
                  requests.
                </Paragraph>
                <Tip>
                  Tip: When creating multiple bid requests, you can choose who
                  receives each request.
                </Tip>
                {/* <p className="p-2 bg-brand-50 border-2 border-dashed border-brand-500 mt-3 mb-2 text-xs text-slate-600">
                  Tip: When creating multiple bid requests, you can choose who
                  receives each request.
                </p> */}
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <>
                    <Paragraph sm htmlFor="is_insured" className="mb-6 ">
                      Which services would you like a bid for?
                    </Paragraph>

                    <RadioGroupCards
                      name="services"
                      defaultValue={selectedServices}
                      value={selectedServices}
                      type={"multiple"}
                      className="grid gap-y-6 grid-cols-1 lg:grid-cols-3 xl:grid-cols-4  sm:gap-x-3"
                      onChange={(e) => handleRadioSelect("services", e)}
                      options={serviceOptions.filter(
                        (option) => !option.visible
                      )}
                    />
                  </>
                </div>
              </div>
            </div>
          )}
          {/* Selected Services */}
          {step == 3 && (
            <div className="">
              {/* Display each service separately when current service matches service */}
              {/* {selectedServices &&
                selectedServices.map((service, index) => {
                  // console.log("SelectedServices", selectedServices);
                  // console.log(
                  //   "Comparing ",
                  //   currentServiceName,
                  //   " to ",
                  //   service
                  // );
                  if (currentServiceName === service) {
                    return (
                      <div key={index} className="flex  h-screen items-center">
                        <div className=" text-3xl  text-center my-auto font-bold text-slate-600">
                          Placeholder for{" "}
                          <span className="uppercase text-brand-500">
                            {service}{" "}
                          </span>
                          options at index {index} of{" "}
                          {selectedServices.length - 1} and ServiceIDX{" "}
                          {currentService}
                        </div>
                      </div>
                    );
                  }
                })} */}
              {selectedServices &&
                selectedServices.map((service, index) => {
                  console.log(
                    "SelectedServices to display",
                    service,
                    selectedServices,
                    currentServiceName
                  );
                  if (
                    currentServiceName === "mailings" &&
                    currentServiceName === service
                  ) {
                    return (
                      <div className="">
                        <div>
                          <h3 className="text-xl font-semibold leading-6 text-slate-900">
                            Mailings
                          </h3>

                          <Paragraph>
                            Use the form on this screen to tell the vendor your
                            requirements related to mailing notification
                            letters.
                          </Paragraph>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-6">
                            <h3 className="text-base font-semibold leading-6 text-slate-900">
                              Affected Individuals
                            </h3>
                            <Paragraph>
                              We'll start by gathering the total number of
                              required letters you'll need sent to each
                              jurisdiction.
                            </Paragraph>
                            <Tip>
                              We've pulled the below information in from the
                              project. You can modify the numbers or leave them
                              as-is.
                            </Tip>
                            <Paragraph
                              htmlFor="is_insured"
                              className="block text-sm font-medium text-slate-700"
                            >
                              Enter the number of affected individuals for each
                              jurisdiction only if you need letters sent to
                              them.
                            </Paragraph>
                            <div className="mt-4 flex rounded-md ">
                              <JurisdictionInput
                                create={true}
                                scope="solicitation"
                                field={"jurisdiction"}
                                service={"mailings"}
                                newProject={newProject}
                                project={currentProject}
                                onFieldChange={onFieldChange}
                                selectedJurisdictions={getSelectedJurisdictions}
                                setSelectedJurisdictions={
                                  setSelectedJurisdictions
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <MailingInfo
                          create={true}
                          scope="solicitation"
                          service={"mailings"}
                          newProject={newProject}
                          project={currentProject}
                          onFieldChange={onFieldChange}
                          onSelectionChange={null}
                          languageOptions={languageOptions}
                          handleMultiDeSelectNew={handleMultiDeSelectNew}
                          handleDeselectedLanguage={handleDeselectLanguage}
                          handleSelectedLanguage={handleMultiSelectNew}
                          // handleMultiSelect={handleMultiSelectNew}
                          handleClearAllSelections={handleClearAllSelections}
                        />
                      </div>
                    );
                  }
                  if (
                    currentServiceName === "emailings" &&
                    currentServiceName === service
                  ) {
                    return (
                      <div className="">
                        <div>
                          <h3 className="text-xl font-semibold leading-6 text-slate-900">
                            Emails
                          </h3>

                          <Paragraph>
                            Use the form on this screen to tell the vendor your
                            requirements related to emailing notifications.
                          </Paragraph>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-6">
                            <h3 className="text-base font-semibold leading-6 text-slate-900">
                              Affected Individuals
                            </h3>
                            <Paragraph>
                              We'll start by gathering the total number of
                              required emails you'll need to be sent to each
                              jurisdiction.
                            </Paragraph>
                            <Tip>
                              We've pulled the below information in from the
                              project. You can modify the numbers or leave them
                              as-is.
                            </Tip>
                            <Label
                              sm
                              bold
                              htmlFor="is_insured"
                              // className="block text-sm font-medium text-slate-700"
                            >
                              Enter the number of affected individuals for each
                              jurisdiction only if you need emails sent to them.
                            </Label>
                            <div className="mt-4 flex rounded-md ">
                              <JurisdictionInput
                                create={true}
                                scope="solicitation"
                                field={"jurisdiction"}
                                service={"emailings"}
                                newProject={newProject}
                                project={currentProject}
                                onFieldChange={onFieldChange}
                                selectedJurisdictions={getSelectedJurisdictions}
                                setSelectedJurisdictions={
                                  setSelectedJurisdictions
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <EmailingInfo
                          create={true}
                          scope="solicitation"
                          service={"emailings"}
                          newProject={newProject}
                          project={currentProject}
                          onFieldChange={onFieldChange}
                          onSelectionChange={null}
                          languageOptions={languageOptions}
                          handleDeselectedLanguage={handleDeselectLanguage}
                          // handleSelectedLanguage={handleSelectedLanguage}
                          handleSelectedLanguage={handleMultiSelectNew}
                          handleClearAllSelections={handleClearAllSelections}
                        />
                      </div>
                    );
                  }
                  if (
                    currentServiceName === "call_center" &&
                    currentServiceName === service
                  ) {
                    return (
                      <div className="">
                        <div>
                          <h3 className="text-xl font-semibold leading-6 text-slate-900">
                            Call Center
                          </h3>

                          <Paragraph className="mt-1 text-sm text-slate-500">
                            Use the form on this screen to tell the vendor your
                            requirements related to the call center.
                          </Paragraph>
                        </div>
                        {/* <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-6">
                            <h3 className="text-base font-semibold leading-6 text-slate-900">
                              Affected Individuals
                            </h3>
                            <p className="mt-1 mb-4 text-sm text-slate-500">
                              We'll start by gathering the total number of
                              required emails you'll need to be sent to each
                              jurisdiction.
                            </p>
                            <Tip>
                              We've pulled the below information in from the
                              project. You can modify the numbers or leave them
                              as-is.
                            </Tip>
                            <label
                              htmlFor="is_insured"
                              className="block text-sm font-medium text-slate-700"
                            >
                              Enter the number of affected individuals for each
                              jurisdiction only if you need emails sent to them.
                            </label>
                            <div className="mt-4 flex rounded-md ">
                              <JurisdictionInput
                                scope="solicitation"
                                field={"jurisdiction"}
                                service={"emailings"}
                                newProject={newProject}
                                project={currentProject}
                                onFieldChange={onFieldChange}
                                selectedJurisdictions={getSelectedJurisdictions}
                                setSelectedJurisdictions={
                                  setSelectedJurisdictions
                                }
                              />
                            </div>
                          </div>
                        </div> */}

                        <CallCenterInfo
                          create={true}
                          scope="solicitation"
                          service={"call_center"}
                          newProject={newProject}
                          project={currentProject}
                          onFieldChange={onFieldChange}
                          onSelectionChange={null}
                          handleSelect={onFieldChange}
                          languageOptions={languageOptions}
                          callCenterLocationOptions={callCenterLocationOptions}
                          handleDeselectedLanguage={handleDeselectLanguage}
                          // handleSelectedLanguage={handleMultiSelect}
                          handleSelectedLanguage={handleMultiSelectNew}
                          handleClearAllSelections={handleClearAllSelections}
                        />
                      </div>
                    );
                  }
                  if (
                    currentServiceName === "landing page" &&
                    service === "landing page"
                  ) {
                    return (
                      <div
                        className={"space-y-8 text-slate-600 font-bold text-lg"}
                        key={index}
                      >
                        <div>Step: {step}</div>
                        <div>Index: {index}</div>
                        <div className="text-xl">ServiceName: {service}</div>
                        <div>Service: {currentService}</div>
                      </div>
                    );
                  }
                  if (
                    currentServiceName === "credit_monitoring" &&
                    currentServiceName === service
                  ) {
                    return (
                      <div className="">
                        <div>
                          <h3 className="text-xl font-semibold leading-6 text-slate-900">
                            Credit Monitoring
                          </h3>

                          <p className="mt-1 text-base text-slate-500">
                            Use the form on this screen to tell your vendor your
                            specific credit monitoring needs. To keep your
                            request organized, you can create groups of
                            individuals and select your preferences for each
                            group.
                          </p>
                        </div>
                        <Tip>
                          You can specify as many groups as you need to. For
                          each selection, you can set the total number of
                          individuals who will need those specific services and
                          what jurisdiction they are in. You can also create
                          multiple groups in the same jurisdiction if you need
                          to.
                        </Tip>

                        {cohorts &&
                          cohorts.map((cohort, index) => {
                            return (
                              <div>
                                {index > 0 && (
                                  <div className="mt-6 mb-6 border-t border-gray-200"></div>
                                )}

                                <CreditMonitoringInfo
                                  create={true}
                                  scope="solicitation"
                                  service={"credit_monitoring"}
                                  newProject={newProject}
                                  project={currentProject}
                                  onFieldChange={onFieldChange}
                                  onSelectionChange={null}
                                  handleSelect={onFieldChange}
                                  languageOptions={languageOptions}
                                  selectedJurisdictions={selectedJurisdictions}
                                  handleDeleteCohort={() =>
                                    handleDeleteCohort(index)
                                  }
                                  groupIndex={index}
                                  cohort={cohort}
                                  callCenterLocationOptions={
                                    callCenterLocationOptions
                                  }
                                  handleDeselectedLanguage={
                                    handleDeselectLanguage
                                  }
                                  handleSelectedLanguage={
                                    handleCreditMonitorSingle
                                  }
                                  handleClearAllSelections={
                                    handleClearAllSelections
                                  }
                                />
                              </div>
                            );
                          })}

                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-6">
                            <Divider
                              handleClick={(e) => createCohort(e)}
                              buttonText={"New Group"}
                            />
                            {/* <div className="mt-4 flex rounded-md ">
                              <JurisdictionInput
                                scope="solicitation"
                                field={"jurisdiction"}
                                service={"emailings"}
                                newProject={newProject}
                                project={currentProject}
                                onFieldChange={onFieldChange}
                                selectedJurisdictions={getSelectedJurisdictions}
                                setSelectedJurisdictions={
                                  setSelectedJurisdictions
                                }
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (
                    currentServiceName === "data_mining" &&
                    currentServiceName === service
                  ) {
                    return (
                      <div className="">
                        <div>
                          <h3 className="text-xl font-semibold leading-6 text-slate-900">
                            Data Mining
                          </h3>

                          <Paragraph className="mt-1 text-sm text-slate-500">
                            Use the form on this screen to tell the vendor your
                            requirements related to data mining.
                          </Paragraph>
                        </div>

                        <DataMiningInfo
                          create={true}
                          scope="solicitation"
                          service={"data_mining"}
                          newProject={newProject}
                          project={currentProject}
                          onFieldChange={onFieldChange}
                          onSelectionChange={null}
                          handleSelect={onFieldChange}
                          handleSingleSelect={handleSingleSelectNew}
                          languageOptions={languageOptions}
                          callCenterLocationOptions={callCenterLocationOptions}
                          handleDeselectedLanguage={handleDeselectLanguage}
                          handleMultiSelect={handleMultiSelectNew}
                          handleMultiDeSelect={handleMultiDeSelectNew}
                          handleSelectedLanguage={handleMultiSelect}
                          handleClearAllSelections={handleClearAllSelections}
                        />
                      </div>
                    );
                  }
                  if (
                    currentServiceName === "document_review" &&
                    currentServiceName === service
                  ) {
                    return (
                      <div className="">
                        <div>
                          <div className="flex items-center justify-between">
                            <h3 className="text-xl font-semibold leading-6 text-slate-900">
                              Document Review
                            </h3>
                            {
                              //if "data_mining" service exists, show button
                              newProject &&
                                newProject.services &&
                                newProject.services[
                                  newProject.services.findIndex(
                                    (el) => el.service === "data_mining"
                                  )
                                ] && (
                                  <Button
                                    color="primary"
                                    loader={copying}
                                    disabled={
                                      //data mining object doesn't have opts2
                                      newProject.services[
                                        newProject.services.findIndex(
                                          (el) => el.service === "data_mining"
                                        )
                                      ].opts2 &&
                                      Object.keys(
                                        newProject.services[
                                          newProject.services.findIndex(
                                            (el) => el.service === "data_mining"
                                          )
                                        ].opts2
                                      ).length === 0
                                    }
                                    handleClick={(e) => {
                                      e.preventDefault();
                                      //update
                                      handleCopyService(
                                        "data_mining",
                                        "document_review"
                                      );
                                    }}
                                  >
                                    {success && success == true && (
                                      <CheckIcon
                                        className="h-5 w-5 mr-2"
                                        aria-hidden="true"
                                      />
                                    )}{" "}
                                    Copy from Data Mining
                                  </Button>
                                )
                            }
                          </div>
                          <Paragraph className="mt-1 text-sm text-slate-500">
                            Use the form on this screen to tell the vendor your
                            requirements related to document review.
                          </Paragraph>

                          {newProject &&
                            newProject.services &&
                            newProject.services[
                              newProject.services.findIndex(
                                (el) => el.service === "data_mining"
                              )
                            ] &&
                            Object.keys(
                              newProject.services[
                                newProject.services.findIndex(
                                  (el) => el.service === "data_mining"
                                )
                              ].opts2
                            ).length > 0 && (
                              <Tip className="mt-4">
                                Since most of the information here is the same
                                as Data Mining, you can copy those answers over
                                by clicking the Copy button. Then you can edit
                                the answers as needed and answer the additional
                                Review-specific questions.
                              </Tip>
                            )}
                        </div>

                        <DataMiningInfo
                          id={"document_review"}
                          create={true}
                          scope="solicitation"
                          service={"document_review"}
                          newProject={newProject}
                          project={currentProject}
                          onFieldChange={onFieldChange}
                          onSelectionChange={null}
                          handleSelect={onFieldChange}
                          handleSingleSelect={handleSingleSelectNew}
                          languageOptions={languageOptions}
                          callCenterLocationOptions={callCenterLocationOptions}
                          handleDeselectedLanguage={handleDeselectLanguage}
                          handleMultiSelect={handleMultiSelectNew}
                          handleMultiDeSelect={handleMultiDeSelectNew}
                          handleSelectedLanguage={handleMultiSelect}
                          handleClearAllSelections={handleClearAllSelections}
                        />
                      </div>
                    );
                  }
                  if (
                    currentServiceName === "forensics" &&
                    currentServiceName === service
                  ) {
                    return (
                      <div className="">
                        <div>
                          <h3 className="text-xl font-semibold leading-6 text-slate-900">
                            Forensics
                          </h3>

                          <Paragraph className="mt-1 text-sm text-slate-500">
                            Use the form on this screen to tell the vendor your
                            requirements related to forensics.
                          </Paragraph>
                        </div>

                        <ForensicsInfo
                          id="forensics"
                          onFieldChangeRTF={onFieldChangeRTF}
                          create={true}
                          scope="solicitation"
                          service={"forensics"}
                          newProject={newProject}
                          project={currentProject}
                          onFieldChange={onFieldChange}
                          onSelectionChange={null}
                          handleSelect={onFieldChange}
                          handleSingleSelect={handleSingleSelectNew}
                          languageOptions={languageOptions}
                          callCenterLocationOptions={callCenterLocationOptions}
                          handleDeselectedLanguage={handleDeselectLanguage}
                          handleMultiSelect={handleMultiSelectNew}
                          handleMultiDeSelect={handleMultiDeSelectNew}
                          handleSelectedLanguage={handleMultiSelect}
                          handleClearAllSelections={handleClearAllSelections}
                        />
                      </div>
                    );
                  }
                  if (
                    currentServiceName === "ransomware" &&
                    currentServiceName === service
                  ) {
                    return (
                      <div className="">
                        <div>
                          <h3 className="text-xl font-semibold leading-6 text-slate-900">
                            Ransomware
                          </h3>

                          <Paragraph className="mt-1 text-sm text-slate-500">
                            Use the form on this screen to tell the vendor your
                            requirements related to ransomware.
                          </Paragraph>
                        </div>

                        <ForensicsInfo
                          id="ransomware"
                          onFieldChangeRTF={onFieldChangeRTF}
                          create={true}
                          scope="solicitation"
                          service={"ransomware"}
                          newProject={newProject}
                          project={currentProject}
                          onFieldChange={onFieldChange}
                          onSelectionChange={null}
                          handleSelect={onFieldChange}
                          handleSingleSelect={handleSingleSelectNew}
                          languageOptions={languageOptions}
                          callCenterLocationOptions={callCenterLocationOptions}
                          handleDeselectedLanguage={handleDeselectLanguage}
                          handleMultiSelect={handleMultiSelectNew}
                          handleMultiDeSelect={handleMultiDeSelectNew}
                          handleSelectedLanguage={handleMultiSelect}
                          handleClearAllSelections={handleClearAllSelections}
                        />
                      </div>
                    );
                  }
                  if (
                    currentServiceName === "managed_detection_and_response" &&
                    currentServiceName === service
                  ) {
                    return (
                      <div className="">
                        <div>
                          <h3 className="text-xl font-semibold leading-6 text-slate-900">
                            Managed Detection and Response
                          </h3>

                          <Paragraph className="mt-1 text-sm text-slate-500">
                            Use the form on this screen to tell the vendor your
                            requirements related to MDR.
                          </Paragraph>
                        </div>

                        <ForensicsInfo
                          id="managed_detection_and_response"
                          onFieldChangeRTF={onFieldChangeRTF}
                          create={true}
                          scope="solicitation"
                          service={"managed_detection_and_response"}
                          newProject={newProject}
                          project={currentProject}
                          onFieldChange={onFieldChange}
                          onSelectionChange={null}
                          handleSelect={onFieldChange}
                          handleSingleSelect={handleSingleSelectNew}
                          languageOptions={languageOptions}
                          callCenterLocationOptions={callCenterLocationOptions}
                          handleDeselectedLanguage={handleDeselectLanguage}
                          handleMultiSelect={handleMultiSelectNew}
                          handleMultiDeSelect={handleMultiDeSelectNew}
                          handleSelectedLanguage={handleMultiSelect}
                          handleClearAllSelections={handleClearAllSelections}
                        />
                      </div>
                    );
                  }
                  if (
                    currentServiceName === "other services" &&
                    service === "other services"
                  ) {
                    return (
                      <div
                        className={"space-y-8 text-slate-600 font-bold text-lg"}
                        key={index}
                      >
                        <div>Step: {step}</div>
                        <div>Index: {index}</div>
                        <div className="text-xl">ServiceName: {service}</div>
                        <div>Service: {currentService}</div>
                      </div>
                    );
                  }
                })}

              {/* {selectedServices &&
                selectedServices.length > 0 &&
                selectedServices.map((service, index) => {
                  if (currentServiceName === service) {
                    console.log("Displaying: ", service, " at index: ", index);
                    return (
                      <div
                        className={"space-y-8 text-slate-600 font-bold text-lg"}
                        key={index}
                      >
                        <div>Step: {step}</div>
                        <div>Index: {index}</div>
                        <div className="text-xl">ServiceName: {service}</div>
                        <div>Service: {currentService}</div>
                      </div>
                    );
                  }
                })} */}
            </div>
          )}

          {step == -5 && (
            <div className="pt-8">
              <div>
                <h3 className="text-xl font-semibold leading-6 text-slate-900">
                  Notifications
                </h3>
                <p className="mt-1 text-sm text-slate-500">
                  We'll always let you know about important changes, but you
                  pick what else you want to hear about.
                </p>
              </div>
              <div className="mt-6">
                <fieldset>
                  <legend className="sr-only">By Email</legend>
                  <div
                    className="text-sm font-semibold text-slate-900"
                    aria-hidden="true"
                  >
                    By Email
                  </div>
                  <div className="mt-4 space-y-4">
                    <div className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="comments"
                          name="comments"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="comments"
                          className="font-medium text-slate-700"
                        >
                          Comments
                        </label>
                        <p className="text-slate-500">
                          Get notified when someones posts a comment on a
                          posting.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="candidates"
                          name="candidates"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="candidates"
                          className="font-medium text-slate-700"
                        >
                          Candidates
                        </label>
                        <p className="text-slate-500">
                          Get notified when a candidate applies for a job.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="offers"
                          name="offers"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="offers"
                          className="font-medium text-slate-700"
                        >
                          Offers
                        </label>
                        <p className="text-slate-500">
                          Get notified when a candidate accepts or rejects an
                          offer.
                        </p>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="mt-6">
                  <legend className="contents text-sm font-semibold text-slate-900">
                    Push Notifications
                  </legend>
                  <p className="text-sm text-slate-500">
                    These are delivered via SMS to your mobile phone.
                  </p>
                  <div className="mt-4 space-y-4">
                    <div className="flex items-center">
                      <input
                        id="push-everything"
                        name="push-notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-brand-600 focus:ring-brand-500"
                      />
                      <label
                        htmlFor="push-everything"
                        className="ml-3 block text-sm font-medium text-slate-700"
                      >
                        Everything
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="push-email"
                        name="push-notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-brand-600 focus:ring-brand-500"
                      />
                      <label
                        htmlFor="push-email"
                        className="ml-3 block text-sm font-medium text-slate-700"
                      >
                        Same as email
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="push-nothing"
                        name="push-notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-brand-600 focus:ring-brand-500"
                      />
                      <label
                        htmlFor="push-nothing"
                        className="ml-3 block text-sm font-medium text-slate-700"
                      >
                        No push notifications
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          )}
          {/* Last Step */}
          {step == totalSteps && (
            <div className="">
              <div>
                <h3 className="text-xl font-semibold leading-6 text-slate-900">
                  That's everything we need to create a bid request.
                </h3>
                <p className="mt-1 text-sm text-slate-900">
                  Now that we've collected all the information related to this
                  bid request, let's tie up some loose ends. Start by saving
                  your request, then you can choose to create a template or send
                  your requests.
                </p>
                <div className=" w-full p-10  items-center">
                  <div className="flex items-center w-full mx-auto">
                    {!saved && (
                      <button
                        onClick={(e) => onSave(e)}
                        className="w-full lg:w-1/2 h-full text-left py-3 px-4 rounded bg-white 
                    border border-gray-300
                    shadow-sm duration-150 ease-in-out
                    hover:border-gray-300 hover:shadow-md hover:bg-gray-50
                    focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500
                    
                   "
                      >
                        <div className="flex items-center">
                          {loading ? (
                            <Skeleton
                              type="spinner"
                              className="h-10 w-10 mr-3"
                              forButton
                            />
                          ) : (
                            <Icon
                              icon="mdi:content-save"
                              className="h-10 w-10 mr-3"
                            />
                          )}
                          {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                          <div className="grow">
                            <div className="flex flex-wrap items-center justify-between mb-0.5">
                              <span className="font-semibold text-slate-800">
                                Save
                              </span>
                              {/* <span>
                                <span className="font-medium text-green-600">
                                  $59.00
                                </span>
                                /mo
                              </span> */}
                            </div>
                            <div className="text-xs">
                              {err ? (
                                <span className={"text-red-500 text-sm"}>
                                  {errMessage}
                                </span>
                              ) : (
                                "Save your bid request"
                              )}
                            </div>
                          </div>
                        </div>
                      </button>
                    )}
                    {saved && (
                      <RadioGroupLarge
                        onClickFunction={(v) => setLastStepState(v)}
                        selectedOption={lastStepState}
                        disabledActions={[
                          sent ? "send" : null,
                          templateSaved ? "template" : null,
                        ]}
                        options={[
                          {
                            checkedValue: lastStepState == "template",
                            label: "Save as Template",
                            canDisable: false,
                            value: "template",
                            description:
                              "Save this request as a template for future use",
                            // onClickFunction: () => setLastStepState("template"),
                          },
                          {
                            checkedValue: lastStepState == "send",
                            label: "Send my bid request",
                            canDisable: true,
                            value: "send",
                            description:
                              "Start here if you haven't created a template",
                            // onClickFunction: () => setLastStepState("send"),
                          },
                        ]}
                      />
                    )}
                  </div>
                </div>
                <div className="w-full   items-center">
                  {console.log(lastStepState, "stepstate")}
                  {lastStepState == "send" && !sent && (
                    <div>
                      <h3 className="text-xl font-semibold leading-6 text-slate-900">
                        Send Invitations
                      </h3>
                      <p className="mt-1 text-sm text-slate-900">
                        Now that we've collected all the information related to
                        this bid request, you can select who you want to send it
                        to below.
                      </p>
                      <Tip className="mt-4">
                        When you're finished, click the "Save and Send" button
                        to save it and send it out.
                      </Tip>
                      <InviteComponent
                        onSave={onSend}
                        suggestions={featuredSuggestions}
                        marketplace={marketplace}
                        readOnly={false}
                      />
                    </div>
                  )}
                </div>

                <EaseIn show={lastStepState == "template"}>
                  <div className="w-full   items-center">
                    <div className="py-6">
                      <h3 className="text-xl font-semibold leading-6 text-slate-900">
                        Save as Template
                      </h3>
                      <p className="mt-1 text-sm text-slate-500">
                        Save time by saving this bid request as a template.
                      </p>
                      {!templateSaved ? (
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Name
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                autoComplete="name"
                                value={templateName}
                                onChange={(e) =>
                                  onTemplateCreationFieldChange(e, "Name")
                                }
                                className="max-w-lg block w-full shadow-sm focus:ring-brand-500 focus:border-brand-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                            <label
                              htmlFor="description"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Description
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <textarea
                                id="description"
                                name="description"
                                rows={3}
                                value={templateDescription}
                                onChange={(e) =>
                                  onTemplateCreationFieldChange(
                                    e,
                                    "Description"
                                  )
                                }
                                className="max-w-lg block w-full shadow-sm focus:ring-brand-500 focus:border-brand-500 sm:text-sm border-gray-300 rounded-md"
                                defaultValue={""}
                              />
                              <p className="mt-2 text-sm text-gray-500">
                                Brief description for your template.
                              </p>
                            </div>
                          </div>
                          <Button
                            audit={true}
                            action="Save Bid Request Template"
                            details={{
                              name: templateName,
                              description: templateDescription,
                            }}
                            disabled={templateSaved}
                            loader={loading}
                            handleClick={(e) => {
                              saveTemplate(e);
                            }}
                            color="secondary"
                          >
                            Save Template
                          </Button>
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                            {err && (
                              <div className="text-red-500 text-sm font-medium">
                                {errMessage}
                              </div>
                            )}
                          </div>
                          {/* Buttons Save and Cancel */}
                        </div>
                      ) : (
                        <EaseIn show={templateSaved}>
                          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <Icon
                              icon="ep:success-filled"
                              className="inline-flex h-6 w-6 text-green-500"
                            />
                            Template Saved Successfully!
                          </div>
                        </EaseIn>
                      )}
                    </div>
                  </div>
                </EaseIn>
              </div>
            </div>
          )}
        </div>
      </form>
      <footer className="pt-3 mt-auto space-x-2 -mb-5 border-t bg-white sticky bottom-0 ">
        <div className="flex items-center justify-between ">
          <div className="flex justify-start ">
            {!saved && (
              <Button
                color={"white"}
                type="button"
                className=" border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-slate-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                handleClick={(e) => {
                  e.preventDefault();
                  cleanUpState();
                  props.setModalOpen(false);
                }}
              >
                Discard
              </Button>
            )}
          </div>
          <div className="flex justify-end">
            {step !== 0 && !saved && (
              <Button
                color={"primary"}
                size="sm"
                // className="cursor-pointer ml-3 mr-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium  shadow-sm  focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                // disabled={step === 1}
                handleClick={(e) => {
                  e.preventDefault();
                  handlePreviousStepClick();
                  // setStep(step - 1);
                }}
              >
                Previous Step
              </Button>
            )}

            {step < totalSteps && (
              <Button
                color={"secondary"}
                size="sm"
                // disabled={true}
                disabled={
                  step === 1 &&
                  newProject.nda_required === true &&
                  newProject.attachments &&
                  newProject.attachments.length === 0
                    ? true
                    : createFromTemplate === true &&
                      (selectedTemplate === null || selectedTemplate === "")
                    ? true
                    : false

                  // if (
                  //   step === 1 &&
                  //   newProject.nda_required === true &&
                  //   newProject.attachments.length === 0
                  // ) {
                  //   return false;
                  // } else if (
                  //   createFromTemplate === true &&
                  //   (selectedTemplate === null || selectedTemplate === "")
                  // ) {
                  //   return false;
                  // } else {
                  //   return false;
                  // }
                }
                className="ml-3 "
                handleClick={(e) => {
                  e.preventDefault();
                  handleNextStepClick();
                  // setStep(step + 1);
                }}
              >
                Next Step
              </Button>
            )}
            {step == totalSteps &&
              saved &&
              newProject.invitations &&
              newProject.invitations.length > 0 &&
              sent && (
                <Button
                  color={""}
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-brand-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                  handleClick={(e) => {
                    e.preventDefault();
                    onClose();
                    props.handleCloseButtonClick &&
                      props.handleCloseButtonClick(currentProject, newProject);

                    // setStep(step + 1);
                  }}
                >
                  Done - Close this window
                </Button>
              )}
          </div>
        </div>
      </footer>
      {/* {showConfirmDeleteGroup && ( */}
      <DialogBox
        handleConfirmClick={handleDeleteCohort}
        open={showConfirmDeleteGroup}
        handleCancelClick={() => setShowConfirmDeleteGroup(false)}
        loading={false}
        success={null}
        title="Confirm Deletion"
        message={"Are you sure you want to delete this group?"}
        OKbuttonText={"Delete"}
        CancelButton={"Cancel"}
      ></DialogBox>
      {/* )} */}
    </div>
  );
}

/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/24/outline";

const features = [];

export default function VendorPublicLandingIntro(props) {
  return (
    <div className="">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-4">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-2xl text-slate-800 font-bold">
            Before you begin...
          </h1>
        </div>
      </div>

      <div className=" mx-auto text-left text-base">
        <p className="mt-4  ">
          First, we want to thank you for being part of our journey. We're
          dedicated to opening up new opportunities for you and ensuring a level
          playing field for both vendors and consumers. Your support and
          participation as a Breachlink vendor are invaluable to us, and we're
          excited about the journey ahead.
        </p>
        <p className="mt-4  ">
          By now, we hope that you have seen the benefits of being a Breachlink
          vendor. As part of our commitment to innovation and service
          enhancement, we're excited to announce some upcoming changes in April
          2024.
        </p>
        <p className="mt-4  ">
          Starting in April, we're introducing a new pricing model tailored to
          empower every vendor to engage with bid requests actively. Whether
          you're seeking to boost your visibility or access additional features,
          our model offers flexible subscription options to meet your needs.
        </p>
        <p className="mt-4  ">
          We remain steadfast in our commitment fairness. All vendors will
          continue to have the ability to receive and respond to bid requests.
          For those not subscribing to additional features, a nominal commission
          will be introduced on successful bids, ensuring we can keep delivering
          value to all members of our community.
        </p>

        <p className="mt-4  ">
          We'll be sharing more details in the weeks to come. If you're curious
          about the benefits of our new features or have any questions, don't
          hesitate to reach out. We're here to support your success on
          Breachlink.
        </p>
        {/* <p className="mt-4  ">
            We will ask you for your agreement to these terms each time you are
            invited to bid on a project. If you would like to learn more about
            the value our subscriptions provide, please reach out to us!
          </p>
          <p className="mt-4  ">
            You many continue without selecting this box if you would like to
            review the bid or decline the request. If you would like to come
            back to this page later, you can do so by clicking the link in the
            invitation email.
          </p>
          <p className="mt-4  ">If you agree, please check the box below. </p> */}
      </div>
      {/* <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon
                  className="absolute h-6 w-6 text-green-500"
                  aria-hidden="true"
                />
                <p className="ml-9 text-md leading-6 font-medium text-slate-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base ">{feature.description}</dd>
            </div>
          ))}
        </dl> */}
      {/* <p className="mt-4  ">
          You many continue without selecting this box if you would like to
          review the bid or decline the request. If you would like to come back
          to this page later, you can do so by clicking the link in the
          invitation email.
        </p> */}
    </div>
  );
}

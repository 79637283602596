import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import ArrowSteps from "../components/ArrowSteps";
import LandingIntro from "../components/LandingIntro";
import SingleOptions from "../components/SingleOptions";
import StackedList from "../components/StackedList";
import ContactForm from "../components/ContactForm";
import Spinner from "../images/spinner.png";
import { _onboarding_options } from "../modules/_onboarding_options";
import Fade from "react-reveal/Fade";
import { _auth } from "../modules/_auth";
import Skeleton from "../components/Skeleton";
import Input from "../components/Input";
import { _user } from "../modules/_user";
import InputList from "../components/InputList";
import ListBoxSelector from "../components/ListBoxSelector";
import MultiSelectListBox from "../components/MultiSelectListBox1";
import Button from "../elements/Button";
import { _teams } from "../modules/_teams";
import DoNotShowAgain from "../components/DoNotShowAgain";
import { Preferences } from "../modules/_user_preferences";
import VendorPublicLandingIntro from "../components/VendorPublicLandingIntro";
const steps = _onboarding_options.getOnboardingSteps();
const roles = _onboarding_options.getEmployeeRoles();
const departments = _onboarding_options.getEmployeeDepartments();
const companySize = _onboarding_options.getCompanySize();
const companyLocation = _onboarding_options.getCompanyLocation();
const options = _onboarding_options.getAllSettingsOptions();

const mailingLists = [
  {
    id: 1,
    name: "Food & Beverage",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 2,
    name: "Hospitality",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 3,
    name: "Retail",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 4,
    name: "Education",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 5,
    name: "Healthcare",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 6,
    name: "Financial",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 7,
    name: "Consumer Services",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 8,
    name: "Manufacturing",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 9,
    name: "Government",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 10,
    name: "Transportation",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 11,
    name: "Media",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 12,
    name: "Communications",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 13,
    name: "Energy",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 14,
    name: "Technology",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 15,
    name: "Other",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function VendorPaymentAgreement() {
  const history = useHistory();
  const location = useLocation();
  const [selectedMailingLists, setSelectedMailingLists] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [currentStep, setStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [company, setCompany] = useState("");
  const [tenant, setTenant] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = useState(false);
  const [profile, setProfile] = useState(location.state.profile);
  const [buttonText, setButtonText] = useState("Take me to the bid request");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paramsState, setParamsState] = useState(location.state);

  const { token, userId, email, tenantId, type, inviteId, version } =
    location.state;

  // async function handleCheckboxClick(clickType) {
  //   if (clickType === "dontshowagain") {
  //     setChecked(!checked);
  //   }
  //   if (clickType === "onboardFinished") {
  //     if (checked) {
  //       let ans = await _onboarding_options.setDontShowAgain(!checked);
  //       console.log(ans, "ansss");
  //       if (ans) {
  //         return history.push({
  //           pathname: "/dashboard",
  //         });
  //       }
  //     }
  //     // else {
  //     //   onFinish();
  //     // }
  //   }
  // }

  // const onFinish = async () => {
  //   setLoader(true);
  //   return history.push({
  //     pathname: "/home",
  //     state: { profile },
  //   });
  // };
  const handleContinue = async (e) => {
    e.preventDefault();
    let version = paramsState.version;
    let pathname;
    paramsState.agreedToPaymentTerms = true;
    if (version && version == "v2") {
      console.log("Navigating to Version 2");
      pathname = "/projects/project-details/vendor-bid-info-v2";
      history.push({
        pathname: pathname,
        state: paramsState,
      });
    } else {
      console.log("Version 1");
      pathname = "/projects/project-details/vendor-bid-info";
      history.push({
        pathname: pathname,
        state: paramsState,
      });
    }
  };

  return (
    <div className="flex h-screen bg-white w-full">
      {/* Content */}

      <div className="w-full my-auto justify-center items-center bg-white flex flex-col ">
        {/* <Fade right> */}
        {/* <div className="px-4 py-8"> */}
        {/* This div controls the width of the main content */}

        <div
          id="options_container"
          className={classNames(
            currentStep == 7
              ? "w-4/12 mx-auto"
              : "w-3/4 sm:w-1/2 xl:w-4/12 2xl:w-1/23 3xl:w-1/3 mx-auto",
            "max-h-min"
          )}
        >
          {currentStep == 1 && (
            <Fade right>
              <div className="flex flex-col w-full after:flex-1">
                <div className="overflow-y-none">
                  <VendorPublicLandingIntro tenantType={"vendor"} />
                </div>
                <div className="sm:flex sm:justify-between sm:items-center">
                  <div className="mb-4 sm:mb-0"></div>
                </div>

                <div class="flex justify-end items-right w-full mt-8">
                  <Button
                    color="secondary"
                    fullWidth
                    shadow
                    size="lg"
                    exact
                    handleClick={(e) => handleContinue(e)}
                  >
                    {loader && (
                      <Skeleton
                        type="spinner"
                        color="gray"
                        fill="purple"
                        forButton={true}
                      />
                    )}{" "}
                    {buttonText}
                  </Button>
                </div>

                {/* <DoNotShowAgain
                      preferenceId="show-onboarding"
                      setProfile={setProfile}
                      profile={profile}
                      value={Preferences.getPreferenceValue(
                        profile.preferences,
                        "show-onboarding"
                      )}
                    /> */}

                {error && (
                  <div className="mt-4">
                    <p className="text-red-500 text-sm">{errorMessage}</p>
                  </div>
                )}
              </div>
            </Fade>
          )}
        </div>

        {/* </div> */}
        {/* </Fade> */}
      </div>
    </div>
  );
}

export default VendorPaymentAgreement;

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from "react";
import {
  PlusIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import { ListSelector } from "./ListSelector";
import { _assessment } from "../modules/_assessment";
import { usePersistStore, useStore } from "../store/usestore";
import Button from "../elements/Button";
import Input from "./Input";
import QuickView from "./QuickPeak";
import Tip from "./Tip";
import SearchMarketplace from "../pages/SearchMarketplace";
import SearchSuggest from "./SearchSuggest";
import { valid } from "node-html-parser";
import { FeatureFlag } from "./FeatureFlag";
import CopyToClipBoard from "./CopyToClipBoard";
import QuestionToolTip from "./QuestionToolTip";
import Tooltip from "./Tooltip";

export default function InviteComponent(
  props,
  { done, onModal, solicitationId }
) {
  const type = props.type ? props.type : null;
  const { guestList, getGuestList } = usePersistStore();
  const { sendAssessment } = useStore();
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [assessment_type, setAssessmentType] = useState(null);
  const [assessment_name, setAssessmentName] = useState("");
  const [isGuestExist, setIsGuestExist] = useState(false);
  const [invitedGuests, setInvitedGuests] = useState(guestList);
  const [assessment_names, setAssessmentNames] = useState([]);
  const [initialGuestList, setInitialGuestList] = useState([]);
  const [inputError, setInputError] = useState("");
  const [people, setPeople] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [quickViewOpen, setQuickViewOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(props.suggestions);
  const [alreadyInvitedList, setAlreadyInvitedList] = useState([]);
  const [initialMarketplace, setInitialMarketplace] = useState([]);

  useEffect(() => {
    getGuestList();
    setPeople([]);
    setInvitedGuests(guestList);
    setInitialGuestList(guestList);
    setInputError("");
    console.log(guestList, "list");
  }, []);

  useEffect(() => {
    setSuggestions(props.suggestions);
  }, [props.suggestions]);

  useEffect(() => {
    setInitialMarketplace(props.marketplace);
  }, [props.marketplace]);

  useEffect(() => {
    //props.setPeople
  }, [people]);

  useEffect(() => {
    //listen for onDone and set state
    // getGuestList();
    // setPeople([]);
    // setInvitedGuests([...invitedGuests, ...people]);
    // setAssessmentType(null);
    // setAssessmentName("");
  }, [done]);

  useEffect(() => {
    if (invitedGuests.length > 0) {
      setIsGuestExist(true);
      // let checkTypeExist = invitedGuests.filter(
      //   (value, i) => value.assessment_type === assessment_type
      // );
      // console.log(checkTypeExist, "exist");
      // if (checkTypeExist.length > 0) {

      // } else {
      //   setIsGuestExist(false);
      // }
    }
  }, [invitedGuests]);

  useEffect(() => {
    console.log("Guestlist changed");
    // setPeople(guestList);
    setInvitedGuests(guestList);
    console.log(guestList, "guests");
  }, [guestList]);

  // useEffect(() => {
  //   (async () => {
  //     const assessmentNames = await _assessment.getTemplates();
  //     setAssessmentNames(assessmentNames);
  //   })();
  // }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const onDone = (e) => {
    e.preventDefault();
    // onModal();
    // e.stopPropagation();
    let inviteList = people;

    getGuestList();
    setPeople([]);
    setInvitedGuests([...invitedGuests, ...people]);
    setAssessmentType(null);
    setAssessmentName("");
    // onSave(inviteList);
    handleSave(inviteList);
  };
  const handleClick = (e, a) => {
    console.log("handleClick", a);
    e.preventDefault();
    e.stopPropagation();
    setAssessmentType(a.key);
    setAssessmentName(a.name);
  };
  const handleSave = (inviteList) => {
    console.log("handleSave");

    props.onSave(inviteList);
  };

  const onInvited = (email) => {
    const newList = [];
    newList.push({ email });
    console.log("me");
    setEmail("");
    setPeople([...newList, ...people]);
    //remove from invitedGuests
    const newGuestList = invitedGuests.filter((item) => item.email !== email);
    setInvitedGuests(newGuestList);
  };

  const onAdd = (e) => {
    console.log(props.solicitation, "solictation");
    let eml = email;
    //if e is a click event, prevent default
    if (e && e.preventDefault) {
      e.preventDefault();
      // e.stopPropagation();
    } else {
      eml = e;
    }
    console.log("onAdd email", eml);
    const newList = [];

    if (eml && eml.length > 0 && validateEmail(eml)) {
      console.log("running", people);
      let checkLocalExist = people.find((value) => {
        console.log(value, "valllll");
        console.log("runnn", value.email.toString() === eml.toString());
        return value.email.toString() === eml.toString();
      });

      if (!checkLocalExist) {
        console.log(props.solicitation);
        if (
          props.solicitation &&
          props.solicitation.invitations &&
          props.solicitation.invitations.length > 0
        ) {
          let solicitationInvite = props?.solicitation?.invitations;
          console.log(solicitationInvite, "array check");
          let filteredList = solicitationInvite.find((val) => {
            return val.email.toString() === eml.toString();
          });
          if (!filteredList) {
            console.log("onAdd email does not exist", eml);
            newList.push({ email: eml });
            console.log("me");
            setEmail("");
            setPeople([...newList, ...people]);
          } else {
            setInputError("You have already Invited this email");
            setEmail("");
            checkLocalExist = [];
          }
        } else {
          console.log("onAdd email does not exist", eml);
          newList.push({ email: eml });
          console.log("me");
          setEmail("");
          setPeople([...newList, ...people]);
        }
      } else {
        setInputError("You have already Invited this email");
        setEmail("");
        checkLocalExist = [];
      }
    } else {
      console.log("you");
      setEmail("");
      setInputError("Email is not valid");
    }
  };

  const handleAddToListClick = (selected) => {
    console.log("handleAddToListClick", selected.email);
    setInputError("");
    setEmail(selected.email);
    onAdd(selected.email);
    const newSuggestions = suggestions.filter(
      (item) => item._id !== selected._id
    );
    setSuggestions(newSuggestions);
  };

  const onCancel = () => {
    onModal();
    setPeople([]);
    setAssessmentType("");
    // onModal();
  };

  const onRemove = (e, i) => {
    e.preventDefault();
    e.stopPropagation();
    //if email is in initialGuestList, add it back to invitedGuests
    const email = people[i].email;
    const checkInitialGuestList = initialGuestList.find(
      (value) => value.email.toString() === email.toString()
    );
    if (checkInitialGuestList) {
      console.log("onRemove email exist", email);
      const newGuestList = invitedGuests;
      newGuestList.push({ email });
      setInvitedGuests(newGuestList);
    }

    people.splice(i, 1);
    setPeople([...people]);
  };

  return (
    <>
      <div className="mx-auto max-w-full">
        <div className="w-full">
          <div className="text-center">
            {/* <svg
              className="mx-auto h-12 w-12 text-slate-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            <h2 className="mt-2 text-lg font-medium text-slate-900">
              Invite others to take this assessment
            </h2>
            <p className="mt-1 text-sm text-slate-500">
              You can send this assessment to as many people as you want. Once
              you send it, you can track who's taken it in the Assessments
              table.
            </p> */}
          </div>

          <div className="mt-6 w-full">
            {type && type == "reinvite" ? (
              ""
            ) : (
              <>
                {type !== "view_invite" && (
                  <QuickView
                    allSuggestions={suggestions}
                    handleButtonClick={handleAddToListClick}
                  />
                )}
              </>
            )}
            {type && type == "reinvite" && (
              <Tip>
                You can invite additional people to this bid request below.{" "}
              </Tip>
            )}
            {console.log(props.readOnly, "readOnly")}
            {props.readOnly === false && (
              <>
                <form action="#" className="mt-6 flex">
                  <label htmlFor="email" className="sr-only">
                    Email address
                  </label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => {
                      setInputError("");
                      console.log("TYPING", e.target.value);
                      setEmail(e.target.value);
                    }}
                    className="block w-full"
                    // className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                    placeholder="Email address" //Enter a company name to search email addresses to invite
                  />

                  <Button
                    type="submit"
                    handleClick={onAdd}
                    color="secondary"
                    size="sm"
                    className="ml-3 flex-shrink-0"
                    disabled={!validateEmail(email)}
                  >
                    Add to Invite List
                  </Button>
                </form>
                <FeatureFlag
                  id="search-marketplace"
                  type="flag"
                  featureName="Search Marketplace"
                >
                  <div className="w-full">
                    <SearchSuggest
                      searchInput={email}
                      // onSearch={handleSearch}
                      handleSearchSuggestClick={(selected) => {
                        console.log("handleSearchSuggestClick", selected);
                        onAdd(selected);
                      }}
                      onAdd={() => {
                        console.log("onAdd from searchSuggest", email);
                        onAdd(email);
                      }}
                      initialData={initialMarketplace}
                      mainButtonText="Add to Invite List"
                      isValidEmail={validateEmail(email)}
                      bottomButtonText="Invite this person to bid."
                    />
                  </div>
                </FeatureFlag>
                <div>
                  {inputError.length > 0 && (
                    <span className="text-sm font-medium text-red-900">
                      {inputError}
                    </span>
                  )}
                </div>
              </>
            )}

            <div className="mt-10">
              {people.length > 0 && (
                <>
                  <h3 className="text-sm font-medium text-slate-500">
                    Invite List
                  </h3>
                  <ul
                    role="list"
                    className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200 mb-10"
                  >
                    {people
                      //sort by email
                      .sort((a, b) => (a.email > b.email ? 1 : -1))
                      .map((person, personIdx) => (
                        <li
                          key={personIdx}
                          className="flex items-center justify-between space-x-3 py-4"
                        >
                          <div className="flex min-w-0 flex-1 items-center space-x-3">
                            <div className="flex-shrink-0">
                              {/* <img
                          className="h-10 w-10 rounded-full"
                          src={person.imageUrl}
                          alt=""
                        /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <div className="min-w-0 flex-1">
                              <p className="truncate text-sm font-medium text-slate-900">
                                {person.email}
                              </p>
                              <p className="truncate text-sm font-medium text-slate-500">
                                {person.role}
                              </p>
                            </div>
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              onClick={(e) => onRemove(e, personIdx)}
                              className="inline-flex h-7 items-center rounded-full border border-transparent bg-gray-100 py-2 px-3 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                            >
                              {/* <PlusIcon
                          className="-ml-1 mr-0.5 h-5 w-5 text-slate-400"
                          aria-hidden="true"
                        /> */}
                              <span className="text-sm font-medium text-slate-900">
                                {" "}
                                Remove{" "}
                                <span className="sr-only">
                                  {person.name}
                                </span>{" "}
                              </span>
                            </button>
                          </div>
                        </li>
                      ))}
                  </ul>
                </>
              )}
              {console.log(props.solicitation, "solicitations-invites")}
              {props.solicitation &&
                props.solicitation.invitations &&
                props.solicitation.invitations.length > 0 && (
                  <>
                    <Tip color="gray">
                      {/* <div className="mb-10"> */}
                      <div className="inline-flex text-sm font-medium text-slate-900 ">
                        Already invited to this bid request
                      </div>
                      <div className="text-sm  text-slate-700  mb-4 mt-4">
                        The following guests have already been invited to this
                        bid request.
                      </div>
                      <ul
                        role="list"
                        className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200"
                      >
                        {props.solicitation.invitations
                          //sort by email
                          .sort((a, b) => (a.email > b.email ? 1 : -1))
                          //take the top 10 if more than 10
                          .slice(0, 10)
                          .map((person, personIdx) => (
                            <>
                              <li
                                key={personIdx}
                                className="flex items-center justify-between space-x-3 py-4"
                              >
                                <div className="flex min-w-0 flex-1 items-center space-x-3">
                                  <div className="flex-shrink-0">
                                    {/* <img
                               className="h-10 w-10 rounded-full"
                               src={person.imageUrl}
                               alt=""
                             /> */}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <p className="truncate text-sm font-medium text-slate-900">
                                      {person.email}
                                    </p>
                                    <p className="truncate text-sm font-medium text-slate-500">
                                      {/* {person.role} */}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  {person.InvitedLink === undefined ? (
                                    <>
                                      <div className="block pr-6">
                                        <Tooltip
                                          size="xl"
                                          bg="dark"
                                          position={"left"}
                                          title={"Where's my link?"}
                                          description={
                                            "If your link is not appearing, it usually means this person was invited before the link functionality was released."
                                          }
                                        >
                                          <div className="block ml-3 text-xs text-brand-700 underline">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="inline mr-2 h-4 w-4"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                              />
                                            </svg>
                                            Where's my link?
                                          </div>
                                        </Tooltip>
                                      </div>
                                    </>
                                  ) : (
                                    <CopyToClipBoard
                                      inviteLink={person.InvitedLink}
                                    />
                                  )}
                                </div>
                              </li>
                            </>
                          ))}
                      </ul>
                      {/* </div> */}
                    </Tip>
                  </>
                )}
              {props.readOnly === false && (
                <>
                  {invitedGuests.length > 0 && (
                    <>
                      <div>
                        {isGuestExist && (
                          <span className="text-sm font-medium text-slate-900 text-center mb-10 mt-10">
                            Recently Invited
                          </span>
                        )}
                        <ul
                          role="list"
                          className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200"
                        >
                          {invitedGuests
                            //sort by email
                            .sort((a, b) => (a.email > b.email ? 1 : -1))
                            //take the top 10 if more than 10
                            .slice(0, 10)
                            .map((person, personIdx) => (
                              <>
                                <li
                                  key={personIdx}
                                  className="flex items-center justify-between space-x-3 py-4"
                                >
                                  <div className="flex min-w-0 flex-1 items-center space-x-3">
                                    <div className="flex-shrink-0">
                                      {/* <img
                              className="h-10 w-10 rounded-full"
                              src={person.imageUrl}
                              alt=""
                            /> */}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        class="w-6 h-6"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <p className="truncate text-sm font-medium text-slate-900">
                                        {person.email}
                                      </p>
                                      <p className="truncate text-sm font-medium text-slate-500">
                                        {/* {person.role} */}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <button
                                      onClick={() => onInvited(person.email)}
                                      type="button"
                                      className="inline-flex items-center rounded-full border border-transparent bg-gray-100 py-2 px-3 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                    >
                                      <PlusIcon
                                        className="-ml-1 mr-0.5 h-5 w-5 text-slate-400"
                                        aria-hidden="true"
                                      />
                                      <span className="text-sm font-medium text-slate-900">
                                        {" "}
                                        Invite{" "}
                                        <span className="sr-only">
                                          {person.name}
                                        </span>{" "}
                                      </span>
                                    </button>
                                  </div>
                                </li>
                              </>
                            ))}
                        </ul>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.readOnly === false && (
        <div className="sticky bottom-0 px-5 py-4 bg-white border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            {/* <Button
            color="white"
            // className="btn-sm bg-primary-500 hover:bg-primary-600 text-white"
            handleClick={(e) => {
              // e.stopPropagation()
              // showHideShareModal();
              // setDone(!done)
              onCancel();
            }}
          >
            Cancel
          </Button> */}

            <>
              <Button
                color={"secondary"}
                disabled={people.length === 0}
                // className="btn-sm bg-primary-500 hover:bg-primary-600 text-white"
                handleClick={(e) => {
                  // e.stopPropagation()
                  // showHideShareModal();
                  // setDone(!done)
                  onDone(e);
                }}
              >
                Save and Send
              </Button>
            </>

            {/* <button className="btn-sm bg-primary-500 hover:bg-primary-600 text-white">
              I Understand
            </button> */}
          </div>
        </div>
      )}
      {/* <QuickView selectedVendor={selectedVendor} open={quickViewOpen} /> */}
    </>
  );
}
